import React, { useState, memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Checkbox,
  Form,
  notification,
} from 'antd';
import {
  Button,
  Input,
} from 'components/gyramais';
import {
  PolicyOrTermsModal,
} from 'components';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import { formatError } from 'utils';
import { CREATE_PASSWORD_PARTNER, ADD_SESSION } from './graphql';

import './styles.less';

const { Item, useForm } = Form;

const PasswordForm = () => {
  const history = useHistory();
  const location = useLocation();
  const [form] = useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [policyModalVisible, setPolicyModalVisible] = useState(false);

  const {
    'password-token': passwordToken,
    'update-password-token': updatePasswordToken,
  } = queryString.parse(location.search);

  const [accepted, setAccepted] = useState(!!updatePasswordToken);

  const [addSession] = useMutation(ADD_SESSION, {
    onCompleted: () => {
      history.push('/');
    },
  });

  const [createPasswordPartner, { loading }] = useMutation(CREATE_PASSWORD_PARTNER, {
    onCompleted: ({ createPasswordPartner }) => {
      addSession({
        variables: {
          session: createPasswordPartner,
        },
      });
    },
    onError: (error) => notification.error({
      message: formatError(error),
      description: (
        <span>
          Para continuar,
          {' '}
          <Button
            text
            onClick={() => history.push('/login')}
          >
            <b>
              Entre
            </b>
          </Button>
          {' '}
          na sua conta ou clique em
          {' '}
          <Button
            text
            onClick={() => history.push('/esqueci-minha-senha')}
          >
            <b>
              Esqueci minha senha
            </b>
          </Button>
        </span>
      ),
    }),
  });

  const onFinish = async (values) => {
    await form.validateFields();
    const { password, passwordConfirmation } = values;
    if (password !== passwordConfirmation) {
      notification.error({ message: 'Confirmação de senha incorreta.' });
      return;
    }

    createPasswordPartner({
      variables: {
        ...values,
        passwordToken,
        updatePasswordToken,
      },
    });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      id="password-form"
    >
      <h2>
        Crie sua nova
        {' '}
        <span className="secondary-text">senha</span>
      </h2>
      <br />
      <Item name="password" rules={[{ required: true, message: 'Por favor insira sua senha.' }]}>
        <Input
          id="password"
          autoComplete="new-password"
          type="password"
          placeholder="Digite sua nova senha"
        />
      </Item>

      <Item name="passwordConfirmation" rules={[{ required: true, message: 'Por favor insira a confirmação de senha.' }]}>
        <Input
          id="passwordConfirmation"
          autoComplete="new-password"
          type="password"
          placeholder="Digite novamente"
        />
      </Item>

      <Item style={{ marginTop: 24 }} className="password-terms-area">
        <Checkbox
          checked={accepted}
          id="password-terms"
          onChange={({ target: { checked } }) => setAccepted(checked)}
        >
          <p>
            <b>
              Li e aceito os
              {' '}
              <Button
                text
                onClick={() => { setModalVisible(true); setPolicyModalVisible(false); }}
              >
                <b className="secondary-text underline">
                  Termos e Condições do Solicitante
                </b>
              </Button>
              {' '}
              e as
              {' '}
              <Button
                text
                onClick={() => { setModalVisible(true); setPolicyModalVisible(false); }}
              >
                <b className="secondary-text underline">
                  Políticas de Privacidade
                </b>
              </Button>
              .
            </b>
          </p>
        </Checkbox>
      </Item>

      <Button
        block
        disabled={!accepted}
        loading={loading}
        htmlType="submit"
        id="create-password-button"
        className="continue-button"
      >
        Prosseguir
      </Button>

      <PolicyOrTermsModal
        onClose={() => setModalVisible(false)}
        visible={modalVisible}
        type={policyModalVisible ? 'policy' : 'terms'}
      />
    </Form>
  );
};

export default memo(PasswordForm);
