/* eslint-disable no-param-reassign */
import JSZip from 'jszip';
import { Parser } from 'json2csv';

const formatError = (error) => {
  let { message } = error;
  message = message.replace('GraphQL error: ', '');
  message = message.replace('ParseError: 141 ', '');
  return message;
};

const formatDate = (date) => date.toLocaleDateString('pt-BR', {
  day: 'numeric', month: 'numeric', year: 'numeric',
});

const dateDiff = (date1, date2, interval) => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const timediff = Math.abs(date2 - date1);
  switch (interval) {
    case 'years': return date2.getFullYear() - date1.getFullYear();
    case 'months': return ((date2.getFullYear() * 12 + date2.getMonth()) - (date1.getFullYear() * 12 + date1.getMonth()));
    case 'weeks': return Math.floor(timediff / week);
    case 'days': return Math.ceil(Math.floor(timediff / (1000 * 60 * 60 * 24)));
    case 'hours': return Math.floor(timediff / hour);
    case 'minutes': return Math.floor(timediff / minute);
    case 'seconds': return Math.floor(timediff / second);
    default: return undefined;
  }
};

function CPFValidator(strCPF) {
  let soma = 0;
  let resto;
  if (strCPF === '00000000000') return false;
  if (strCPF === '11111111111') return false;
  if (strCPF === '22222222222') return false;
  if (strCPF === '33333333333') return false;
  if (strCPF === '44444444444') return false;
  if (strCPF === '55555555555') return false;
  if (strCPF === '66666666666') return false;
  if (strCPF === '77777777777') return false;
  if (strCPF === '88888888888') return false;
  if (strCPF === '99999999999') return false;
  for (let i = 1; i <= 9; i += 1) {
    soma += Number(strCPF.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== Number(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i += 1) soma += Number(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== Number(strCPF.substring(10, 11))) return false;
  return true;
}

const formatCPF = (cpf) => (cpf && cpf.toString().replace(/[^\d]/g, '').length === 11
  && cpf.toString().replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'))
  || '';

const formatCNPJ = (rawCnpj) => rawCnpj
  ?.toString()
  .replace(/[^\d]/g, '')
  .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

const queryParams = (query) => {
  const params = query.replace('?', '').split('&');
  const response = {};
  params.map((i) => {
    const [key, value] = i.split('=');
    // eslint-disable-next-line no-return-assign
    return response[key] = value;
  });
  return response;
};

const states = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

const isBrightColor = (color) => {
  if (!/^#[0-9A-F]{6}$/i.test(color)) {
    return true;
  }
  const r = parseInt(color.substr(1, 2), 16);
  const g = parseInt(color.substr(3, 2), 16);
  const b = parseInt(color.substr(5, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= (255 / 2));
};

const getContrastYIQ = (hexcolor) => {
  const r = parseInt(hexcolor?.substr(0, 2), 16);
  const g = parseInt(hexcolor?.substr(2, 2), 16);
  const b = parseInt(hexcolor?.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? '#2b2b2b' : '#ffffff';
};

const changeColors = (colors = {}) => {
  const {
    primary = '#000B28',
    primaryButton = '#1256FF',
    secondaryButton = '#1256FF',
    linkButton = '#1256FF',
  } = colors;

  document.documentElement.style.setProperty('--primary-color', primary);
  document.documentElement.style.setProperty('--primary-text-color', getContrastYIQ(primary.replace('#', '')));
  document.documentElement.style.setProperty('--primary-button-color', primaryButton);
  document.documentElement.style.setProperty('--primary-button-text-color', getContrastYIQ(primaryButton.replace('#', '')));
  document.documentElement.style.setProperty('--secondary-button-color', secondaryButton);
  document.documentElement.style.setProperty('--secondary-button-text-color', getContrastYIQ(secondaryButton.replace('#', '')));
  document.documentElement.style.setProperty('--link-button-color', linkButton);
  document.documentElement.style.setProperty('--avatar-text-color', getContrastYIQ(linkButton.replace('#', '')));
};

const downloadImportModel = async (model) => {
  const generateFile = (content, name) => {
    const url = window.URL.createObjectURL(content);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  let items;
  if (model === 'vendas') {
    items = {
      name: 'modelo-vendas.json',
      data: [{
        orderId: 'ABSR-1242156341',
        items: [{
          name: 'Item 1',
          amount: Number(1500),
        }, {
          name: 'Item 2',
          amount: Number(99.50),
        }],
        user: {
          name: 'Cliente que comprou',
        },
        date: new Date(),
        status: 'OK',
        amount: Number(1599.50),
        cnpj: '01234567891011',
      }],
    };
    const json = JSON.stringify(items.data);
    const blob = new Blob([json], { type: 'octet/stream' });
    generateFile(blob, items.name);
  } else if (model === 'clientes') {
    items = {
      name: 'modelo-clientes.json',
      data: [{
        email: 'fulano@gyramais.com',
        cnpj: '01234567891011',
        name: 'Nome da empresa',
        legalName: 'Razão social da empresa',
        fullName: 'Nome Exemplo',
        phoneNumber: '21911111111',
        cpf: '01234567890',
      }, {
        email: 'fulano2@gyramais.com',
        cnpj: '01234567891011',
        name: 'Nome da empresa 2',
        legalName: 'Razão social da empresa 2',
        fullName: 'Nome Exemplo 2',
        phoneNumber: '21911111111',
        cpf: '01234567890',
      }],
    };
    const json = JSON.stringify(items.data);
    const blob = new Blob([json], { type: 'octet/stream' });
    generateFile(blob, items.name);
  } else if (model === 'pagamentos') {
    items = {
      name: 'modelo-pagamentos.json',
      data: [{
        paymentId: 'ABSR-1242156341',
        balancedAmount: Number(10000),
        description: 'Descrição da movimentação',
        type: 'DEPOSITO',
        amount: Number(1500),
        status: 'OK',
        currency: 'R$',
        date: new Date(),
        cnpj: '01234567891011',
      }],
    };
    const json = JSON.stringify(items.data);
    const blob = new Blob([json], { type: 'octet/stream' });
    generateFile(blob, items.name);
  } else {
    items = [
      {
        name: 'modelo-clientes.json',
        data: [{
          email: 'fulano@gyramais.com',
          cnpj: '01234567891011',
          name: 'Nome da empresa',
          legalName: 'Razão social da empresa',
          fullName: 'Nome Exemplo',
          phoneNumber: '21911111111',
          cpf: '01234567890',
        }, {
          email: 'fulano2@gyramais.com',
          cnpj: '01234567891011',
          name: 'Nome da empresa 2',
          legalName: 'Razão social da empresa 2',
          fullName: 'Nome Exemplo 2',
          phoneNumber: '21911111111',
          cpf: '01234567890',
        }],
      }, {
        name: 'modelo-pagamentos.json',
        data: [{
          paymentId: 'ABSR-1242156341',
          balancedAmount: Number(10000),
          description: 'Descrição da movimentação',
          type: 'DEPOSITO',
          amount: Number(1500),
          status: 'OK',
          currency: 'R$',
          date: new Date(),
          cnpj: '01234567891011',
        }],
      }, {
        name: 'modelo-vendas.json',
        data: [{
          orderId: 'ABSR-1242156341',
          items: [{
            name: 'Item 1',
            amount: Number(1500),
          }, {
            name: 'Item 2',
            amount: Number(99.50),
          }],
          user: {
            name: 'Cliente que comprou',
          },
          date: new Date(),
          status: 'OK',
          amount: Number(1599.50),
          cnpj: '01234567891011',
        }],
      }, {
        name: 'modelo-emprestimos.json',
        data: [{
          cnpj: '01234567891011',
          amount: Number(15000),
          installments: Number(9),
        }],
      },
    ];
    const zip = new JSZip();
    await items.forEach((item) => {
      const json = JSON.stringify(item.data);
      const blob = new Blob([json], { type: 'octet/stream' });
      zip.file(item.name, blob);
    });
    zip.generateAsync({ type: 'blob' })
      .then((content) => generateFile(content, 'modelos-gyramais.zip'));
  }
};

/**
 * @typedef {'vendas' | 'clientes' | 'pagamentos'} modelTypes
 *
 * @param {modelTypes} model
 */
const downloadImportCsvModel = async (model) => {
  const generateFile = (content, name) => {
    const url = window.URL.createObjectURL(content);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  let items;
  if (model === 'vendas') {
    items = {
      name: 'modelo-vendas.csv',
      data: [{
        orderId: 'ABSR-1242156341',
        date: new Date(),
        status: 'OK',
        amount: Number(1599.50),
        cnpj: '01234567891011',
      }],
    };
    const csv = new Parser().parse(items.data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    generateFile(blob, items.name);
  } else if (model === 'clientes') {
    items = {
      name: 'modelo-clientes.csv',
      data: [{
        email: 'fulano@gyramais.com',
        cnpj: '01234567891011',
        name: 'Nome da empresa',
        legalName: 'Razão social da empresa',
        fullName: 'Nome Exemplo',
        phoneNumber: '21911111111',
        cpf: '01234567890',
      }, {
        email: 'fulano2@gyramais.com',
        cnpj: '01234567891011',
        name: 'Nome da empresa 2',
        legalName: 'Razão social da empresa 2',
        fullName: 'Nome Exemplo 2',
        phoneNumber: '21911111111',
        cpf: '01234567890',
      }],
    };
    const csv = new Parser().parse(items.data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    generateFile(blob, items.name);
  } else if (model === 'pagamentos') {
    items = {
      name: 'modelo-pagamentos.csv',
      data: [{
        paymentId: 'ABSR-1242156341',
        balancedAmount: Number(10000),
        description: 'Descrição da movimentação',
        type: 'DEPOSITO',
        amount: Number(1500),
        status: 'OK',
        currency: 'R$',
        date: new Date(),
        cnpj: '01234567891011',
      }],
    };
    const csv = new Parser().parse(items.data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    generateFile(blob, items.name);
  } else if (model === 'produtos') {
    items = {
      name: 'modelo-produtos.csv',
      data: [{
        productId: 'ABSR-1242156341',
        name: 'Meu produto',
        quantity: Number(100),
        price: Number(1500),
        category: 'Meus produtos',
        cnpj: '01234567891011',
        date: new Date(),
      }],
    };
    const csv = new Parser().parse(items.data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    generateFile(blob, items.name);
  } else {
    items = [
      {
        name: 'modelo-clientes.csv',
        data: [{
          email: 'fulano@gyramais.com',
          cnpj: '01234567891011',
          name: 'Nome da empresa',
          legalName: 'Razão social da empresa',
          fullName: 'Nome Exemplo',
          phoneNumber: '21911111111',
          cpf: '01234567890',
        }, {
          email: 'fulano2@gyramais.com',
          cnpj: '01234567891011',
          name: 'Nome da empresa 2',
          legalName: 'Razão social da empresa 2',
          fullName: 'Nome Exemplo 2',
          phoneNumber: '21911111111',
          cpf: '01234567890',
        }],
      }, {
        name: 'modelo-pagamentos.csv',
        data: [{
          paymentId: 'ABSR-1242156341',
          balancedAmount: Number(10000),
          description: 'Descrição da movimentação',
          type: 'DEPOSITO',
          amount: Number(1500),
          status: 'OK',
          currency: 'R$',
          date: new Date(),
          cnpj: '01234567891011',
        }],
      }, {
        name: 'modelo-vendas.csv',
        data: [{
          orderId: 'ABSR-1242156341',
          items: [{
            name: 'Item 1',
            amount: Number(1500),
          }, {
            name: 'Item 2',
            amount: Number(99.50),
          }],
          user: {
            name: 'Cliente que comprou',
          },
          date: new Date(),
          status: 'OK',
          amount: Number(1599.50),
          cnpj: '01234567891011',
        }],
      },
      {
        name: 'modelo-produtos.csv',
        data: [{
          productId: 'ABSR-1242156341',
          name: 'Meu produto',
          quantity: Number(100),
          price: Number(1500),
          category: 'Meus produtos',
          cnpj: '01234567891011',
          date: new Date(),
        }],
      },
    ];
    const zip = new JSZip();
    await items.forEach((item) => {
      const csv = new Parser().parse(items.data);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      zip.file(item.name, blob);
    });
    zip.generateAsync({ type: 'blob' })
      .then((content) => generateFile(content, 'modelos-gyramais.zip'));
  }
};

const addressToString = (address) => {
  if (!address) {
    return '-';
  }

  const {
    street, city, district, state, complement, number,
  } = address;

  const complementText = complement
    ? `, ${complement}`
    : '';

  return `${street}, ${number}${complementText} - ${district}, ${city}/${state}`;
};

const toKebabCase = (value) => value?.toLowerCase().split(' ').join('-');

const makeUniqueKey = (length = 10) => {
  const result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

const formatCurrency = (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
  .format(value);

/**
 * @typedef {'cnpj'|'cpf'|'cep'|'rg'|'currency'} FormatTypes
 *
 * @param {string} value - Valor a ser formatado
 * @param {FormatTypes} type - Typo de formatação
 * @returns {string}
 */
const formatString = (value, type) => {
  if (!value) {
    return value;
  }

  let result = value;
  // eslint-disable-next-line default-case
  switch (type) {
    case 'cnpj': result = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'); break;
    case 'cpf': result = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); break;
    case 'cep': result = value.replace(/^(\d{5})(\d{3})/, '$1-$2'); break;
    case 'rg': result = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'); break;
    case 'currency': result = value
      .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      .replace(/,/g, 'a')
      .replace('.', ',')
      .replace(/a/g, '.');
  }
  return result;
};

const numberToMonth = (number) => {
  const months = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };
  return months[number] || '-';
};

export * from './texts';
export {
  formatError,
  formatDate,
  dateDiff,
  formatCPF,
  CPFValidator,
  queryParams,
  states,
  downloadImportModel,
  downloadImportCsvModel,
  changeColors,
  formatCNPJ,
  addressToString,
  toKebabCase,
  makeUniqueKey,
  getContrastYIQ,
  isBrightColor,
  formatCurrency,
  formatString,
  numberToMonth,
};
