// @ts-check
import React, { useEffect, useMemo } from 'react';
import {
  Form,
  Row,
  Col,
  Select,
} from 'antd';
import { Input } from 'components/gyramais';

import { useQuery } from '@apollo/client';

// @ts-ignore
import BUSINESS from '../../../../../../graphql/business.gql';
// @ts-ignore
import CIVIL_STATUSES from './graphql/civilStatuses.gql';
// @ts-ignore
import BUSINESS_USER_TYPE from './graphql/businessUserType.gql';

import './styles.less';

const { Item } = Form;
const { Option } = Select;

const genders = [
  { id: 1, name: 'Masculino', value: 'MASCULINO' },
  { id: 2, name: 'Feminino', value: 'FEMININO' },
];

const ProfileStep = ({
  businessId,
  businessUserId,
  form,
}) => {
  const {
    data: {
      business,
    } = {},
    loading: businessLoading,
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    fetchPolicy: 'cache-only',
  });

  const {
    data: {
      civilStatuses = [],
    } = {},
  } = useQuery(CIVIL_STATUSES, {
    fetchPolicy: 'cache-and-network',
    skip: !businessId,
  });

  const {
    data: {
      businessUserTypes = [],
    } = {},
    loading: businessUserTypeLoading,
  } = useQuery(BUSINESS_USER_TYPE, {
    fetchPolicy: 'cache-and-network',
  });

  const businessUser = useMemo(() => {
    const businessUser = business?.users?.find(({ id }) => id === businessUserId);
    return businessUser;
  }, [business]);

  const loading = businessLoading;

  useEffect(() => {
    if (businessUser?.user) {
      const { user } = businessUser;
      const {
        fullName,
        cpf,
        civilStatus,
        birthDate,
        phoneNumber,
        email,
        additionalInfo,
      } = user;
      let birthDateString = '';

      if (birthDate) {
        const [year, month, day] = birthDate?.split('T')[0]?.split('-');
        birthDateString = `${day}/${month}/${year}`;
      }
      form.setFieldsValue({
        fullName,
        cpf,
        civilStatusId: civilStatus?.id,
        birthDate: birthDateString,
        phoneNumber,
        email,
        gender: additionalInfo?.gender,
        typesValues: businessUser.types.map((type) => type.value),
      });
    } else {
      form.resetFields();
    }
  }, [businessUser, businessId, businessUserId]);

  return (
    <Form
      id="profile-step"
      form={form}
    >
      <Row align="middle" id="add-user-account" gutter={24}>

        <Col xs={24} sm={24} md={12}>
          <Item
            label="Email"
            name="email"
            rules={[{
              required: true,
              whitespace: true,
              message: 'Por favor preencha o email',
            }]}
          >
            <Input
              disabled={loading}
              placeholder="Email"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Item
            label="CPF"
            name="cpf"
            rules={[{
              required: true,
              whitespace: true,
              message: 'Por favor preencha o cpf',
            }]}
          >
            <Input
              type="masked"
              // @ts-ignore
              mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              disabled={loading}
              placeholder="CPF"
            />
          </Item>
        </Col>
        {businessUser && (
          <>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="Nome completo"
                name="fullName"
                rules={[{
                  required: true,
                  message: 'Por favor preencha o nome',
                }]}
              >
                <Input
                  disabled={loading || !businessUser}
                  placeholder="Nome completo"
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="Estado civil"
                name="civilStatusId"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha o Estado civil',
                }]}
              >
                <Select
                  className="gyramais-input"
                  placeholder="Selecione o estado civil"
                  disabled={loading || !businessUser}
                >
                  {civilStatuses.map((civilStatus) => (
                    <Option value={civilStatus.id} key={civilStatus.id}>
                      {civilStatus.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="Data de nascimento"
                name="birthDate"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha a data de nascimento',
                }]}
              >
                <Input
                  type="masked"
                  // @ts-ignore
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  disabled={loading || !businessUser}
                  placeholder="Data de nascimento"
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="TELEFONE CELULAR"
                name="phoneNumber"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha o telefone celular',
                }]}
              >
                <Input
                  type="masked"
                  guide
                  // @ts-ignore
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  disabled={loading || !businessUser}
                  placeholder="Telefone celular"
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Item
                name="typesValues"
                label="Responsabilidades"
                rules={[{ required: true, message: 'Por favor, adicione ao menos uma responsabilidade' }]}
              >
                <Select
                  className="gyramais-input"
                  mode="multiple"
                  placeholder="Selecione as responsabilidades"
                  dropdownClassName="gyramais-input-options"
                  loading={businessUserTypeLoading}
                >
                  {businessUserTypes.filter((type) => !['conjuge'].includes(type.value)).map((type) => (
                    <Option value={type.value} key={type.id}>
                      {type.name}
                    </Option>
                  ))}
                  Teste
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Item
                name="gender"
                label="SEXO"
                rules={[{
                  required: true,
                  message: 'Selecione o sexo',
                }]}
              >
                <Select
                  className="gyramais-input"
                  placeholder="Selecione o sexo"
                >
                  {genders?.map(({ id, name, value }) => (
                    <Option key={id} value={value}>{name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default ProfileStep;
