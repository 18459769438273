import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Row,
  Col,
  Card,
  message,
  Modal,
} from 'antd';
import MaskedTextInput from 'react-text-mask';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  ME,
} from 'services/graphql/queries';
import {
  GET_USER_BY_CPF,
  SAVE_BUSINESS_USER,
} from 'services/graphql/mutations';
import { BusinessUserCard } from 'components';
import { formatError, CPFValidator } from 'utils';

const BusinessPartnersForm = ({
  businessId,
  businessUsers,
  refetch,
}) => {
  const [CPFModalVisible, setCPFModalVisible] = useState(false);
  const [CPF, setCPF] = useState('false');
  const [userSearched, setUserSearched] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState({});
  const history = useHistory();
  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: () => {
      setUserSearched(false);
      setPartnerInfo({});
      setCPFModalVisible(false);
      setCPF('');
      refetch();
    },
    onError: (error) => message.error(formatError(error)),
    refetchQueries: [{ query: ME }],
  });
  const [getUserByCPF, { loading: loadingUser }] = useMutation(GET_USER_BY_CPF, {
    onCompleted: ({
      userByCPF,
    }) => {
      setUserSearched(true);
      if (userByCPF) {
        const {
          fullName, email, phoneNumber,
        } = userByCPF;
        setPartnerInfo({
          fullName, email, phoneNumber,
        });
      }
    },
    onError: (error) => message.error(formatError(error)),
  });

  const addPartner = () => {
    saveBusinessUser({
      variables: {
        businessId,
        cpf: CPF,
      },
    });
  };
  return (
    <>
      <Modal
        visible={CPFModalVisible}
        okText="Adicionar"
        centered
        onOk={() => {
          if (!partnerInfo.fullName) {
            history.push('/cadastro/empresa-socios', {
              cpf: CPF.replace(/\D/g, ''),
              businessId,
            });
          } else {
            addPartner();
          }
        }}
        okButtonProps={{ disabled: !userSearched || saveLoading, id: 'continue-add-business-user-button' }}
        cancelText="Voltar"
        onCancel={() => {
          setUserSearched(false);
          setPartnerInfo({});
          setCPFModalVisible(false);
          setCPF('');
        }}
        cancelButtonProps={{ disabled: saveLoading || loadingUser }}
      >
        Digite o CPF do sócio
        <MaskedTextInput
          className="ant-input"
          guide
          id="new-business-user-cpf"
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholder="CPF"
          value={CPF}
          style={{ marginTop: 10 }}
          onChange={(e) => {
            setUserSearched(false);
            setPartnerInfo({});
            setCPF(e.target.value);
            if (e.target.value.replace(/[^\d]/g, '').length === 11) {
              if (CPFValidator(e.target.value.replace(/\D/g, ''))) {
                getUserByCPF({ variables: { cpf: e.target.value } });
              } else {
                message.error('Digite um CPF válido', 3);
              }
            }
          }}
          disabled={loadingUser || saveLoading}
        />
        <div style={{ paddingTop: '1rem' }}>
          {partnerInfo.fullName
            ? (
              <p style={{ marginBottom: 0 }}>
                Deseja adicionar

                <span style={{ fontWeight: 'bold' }}>{` ${partnerInfo.fullName.split(' ')[0]} ${partnerInfo.fullName.split(' ')[partnerInfo.fullName.split(' ').length - 1]}`}</span>
                ?
              </p>
            )

            : userSearched && (
              <p style={{ marginBottom: 0 }}>
                Novo usuario
              </p>
            )}
        </div>
      </Modal>
      <b style={{ marginLeft: 10 }}>Responsáveis</b>
      <Form style={{ minHeight: 0, marginBottom: 0 }}>
        {businessUsers.map((user) => (
          <BusinessUserCard key={user.id} user={user} refetch={refetch} />
        ))}
        <div className="business-card-container" style={{ display: 'flex' }}>
          <Card
            className="business-card partner-card"
            hoverable
            onClick={() => setCPFModalVisible(true)}
          >
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} gutter={12}>
              <Col>
                <PlusCircleOutlined
                  id="add-business-user-card"
                  style={{
                    color: 'rgb(220, 220, 220)',
                    fontSize: 30,
                  }}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </Form>
    </>
  );
};

BusinessPartnersForm.propTypes = {
  businessId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  businessUsers: PropTypes.array.isRequired,
  refetch: PropTypes.func,
};

export default BusinessPartnersForm;
