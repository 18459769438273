import { useEffect, useState } from 'react';

/**
 * Confirm browser exit.
 *
 * @param {{
 *  defaultEnabled?: boolean
 *  message?: string
 * }} args
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 */
const useConfirmBrowserExit = ({
  defaultEnabled = true,
  message = 'Confirm leave page',
}) => {
  const [msg, setMsg] = useState(message);
  const [enabled, setEnabled] = useState(defaultEnabled);

  useEffect(() => {
    function listener(e) {
      if (enabled) {
        e.preventDefault();
        e.returnValue = msg;
        return msg;
      }

      return undefined;
    }

    window.addEventListener('beforeunload', listener);

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [msg, enabled]);

  return {
    enableConfirmation() {
      setEnabled(true);
    },
    disableConfirmation() {
      setEnabled(false);
    },
    setMessage(newMessage) {
      setMsg(newMessage);
    },
    getMessage() {
      return msg;
    },
    setEnabled(status) {
      setEnabled(status);
    },
    getEnabled() {
      return enabled;
    },
  };
};

export default useConfirmBrowserExit;
