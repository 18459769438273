import { gql } from '@apollo/client';

const SAVE_LOAN = gql`
  mutation saveLoan(
    $id: ID
    $businessId: ID
    $amount: Float
    $installmentsCount: Int
    $paymentDay: Int!
  ) {
    saveLoan(
      id: $id
      businessId: $businessId
      amount: $amount
      installmentsCount: $installmentsCount
      paymentDay: $paymentDay
    ) {
      id
    }
  }
`;

const SAVE_PARTNER_LOAN = gql`
  mutation savePartnerLoan(
    $customerId: ID!
    $amount: Float!
    $installmentsCount: Int!
  ) {
    savePartnerLoan(
      customerId: $customerId
      amount: $amount
      installmentsCount: $installmentsCount
    ) {
      id
      number
      ccbNumber
      date
      disbursementDate
      amount
      finalAmount
      totalAmount
      installmentsCount
      finalInstallmentsCount
      installmentAmount
      interestRate
      iofAmount
      annualCET
      business {
        id
        name
        number
      }
      status {
        id
        name
        value
        colors
        step
      }
    }
  }
`;

const REMOVE_SELLER_LOAN = gql`
  mutation removeSellerLoan($loanId: ID!) {
    removeSellerLoan(loanId: $loanId)
  }
`;

export {
  SAVE_LOAN,
  SAVE_PARTNER_LOAN,
  REMOVE_SELLER_LOAN,
};
