import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';
import { Tag } from 'antd';

const Status = ({
  value,
  name,
}) => {

  const tagColor = () => {
    switch (value) {
      case 'active':
      case 'completed':
      case 'paid':
      case 'renegotiated':
      case 'active-renegotiated':
        return 'success';

      case 'final-verifying':
      case 'integrated':
      case 'lead':
      case 'new':
      case 'partially-paid':
      case 'pending':
      case 'pending-renegotiated':
      case 'pre-approved':
      case 'registered':
      case 'review':
      case 'awaiting-signature':
      case 'awaiting-signature-additive':
      case 'signed':
      case 'update':
      case 'verifying':
      case 'score':
        return 'warning';

      case 'awaiting-re-evaluation':
      case 'awaiting-re-evaluation-cnpj-inactive':
      case 'awaiting-re-evaluation-scr-late':
      case 'awaiting-re-evaluation-scr-loss-b':
      case 'awaiting-re-evaluation-tm-time':
      case 'awaiting-re-evaluation-tm-volume':
      case 'canceled':
      case 'expired':
      case 'lawsuit':
      case 'late':
      case 'rejected':
      case 'down-payment-invoice':
      default:
        return 'error';
    }
  };

  return (
    <Tag color={tagColor()} className="gyramais-status">
      {name?.toUpperCase?.()}
    </Tag>
  );
};

Status.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
};

export default Status;
