import { useQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect } from 'react';
import { ME_ROLES } from './graphql';

/**
 * @callback checkForBusinessAction
 * @param {BusinessActions} actionValue
 * @returns boolean
 */

/**
 * @callback checkForPreset
 * @param {Presets} presetValue
 * @returns boolean
 */

const defaultCallback = () => {
  throw new Error('Você precisa chamar esse contexto dentro do provider!');
};

export const ActionContext = createContext({
  checkForBusinessAction: defaultCallback,
  loading: true,
  allPresets: [],
  businessActions: [],
});

const ActionProvider = ({ children }) => {
  const { data, loading } = useQuery(ME_ROLES, {
    fetchPolicy: 'cache-first',
  });

  const roles = data?.me?.roles || [];
  const businessActions = data?.me?.currentBusiness?.actions || [];
  const actions = roles?.filter((role) => role.name.startsWith('action-')) || [];
  const presets = roles?.filter((role) => role.name.startsWith('preset-')) || [];
  const allPresets = data?.presets || [];

  const checkForBusinessAction = (actionValue) => {
    if (!actionValue) {
      return true;
    }

    return !!businessActions.find((role) => role.value === actionValue);
  };

  const checkForPreset = (presetValue) => {
    if (!presetValue) {
      return true;
    }

    return !!presets.find((role) => role.name === `preset-${presetValue}`);
  };

  return (
    <ActionContext.Provider
      value={{
        checkForBusinessAction,
        checkForPreset,
        loading,
        actions,
        allPresets,
        businessActions,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};

export default ActionProvider;

/**
 * @returns {{
 *  checkForBusinessAction: checkForBusinessAction
 *  checkForPreset: checkForPreset
 *  loading: boolean
 *  allPresets: {
 *    name: string
 *    displayName: string
 *  }[]
 *  businessActions: string[]
 * }}
 */
export function useActionContext({ onCompleted } = {}) {
  const contextData = useContext(ActionContext);

  useEffect(() => {
    if (!contextData.loading) {
      onCompleted?.();
    }
  }, [contextData.loading]);

  return contextData;
}
