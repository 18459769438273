import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Select,
  message,
} from 'antd';
import {
  formatError,
} from 'utils';
import {
  Drawer,
  Input,
} from 'components/gyramais';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import {
  BUSINESS_PLATFORM_TYPES,
  BUSINESS_SECTORS,
  BUSINESS_SEGMENTS,
  EDIT_SELLER,
} from './graphql';

const { Item, useForm } = Form;
const { Option } = Select;

const UserDrawer = ({
  business,
  onClose,
  visible,
  refetch,
}) => {

  const [form] = useForm();
  const setFormFields = () => {
    form.setFieldsValue({
      fullName: business?.owner?.fullName,
      cpf: business?.owner?.cpf,
      email: business?.owner?.email,
      phoneNumber: business?.owner?.phoneNumber,
      employeesNumber: business?.employeesNumber,
      revenueAmount: business?.revenueAmount,
      platformType: business?.platformType?.value,
      sector: business?.sector?.value,
      segment: business?.segment?.value,
    });
  };

  useEffect(() => {
    setFormFields();
  }, [business]);

  const {
    data: {
      businessPlatformTypes,
    } = {},
  } = useQuery(BUSINESS_PLATFORM_TYPES, {
    skip: !visible,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSegments,
    } = {},
  } = useQuery(BUSINESS_SEGMENTS, {
    skip: !visible,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSectors,
    } = {},
  } = useQuery(BUSINESS_SECTORS, {
    skip: !visible,
    fetchPolicy: 'cache-and-network',
  });

  const [editSeller, { loading }] = useMutation(EDIT_SELLER, {
    onCompleted: () => {
      message.success('Informações alteradas com sucesso!');
      setFormFields();
      onClose();
    },
    onError: (error) => message.error(formatError(error), 2),
  });

  const isInvalid = ![
    'new',
    'pending',
    'integrated',
    'integrating',
    'verifying',
    'score',
    'final-verifying',
  ].includes(business?.loans?.[0]?.status?.value);

  return (
    <>
      <Drawer
        className="user-drawer"
        visible={visible}
        onClose={() => {
          setFormFields();
          onClose();
        }}
        title={(
          <span>
            Editar
            {' '}
            {business?.owner?.fullName ? (
              <span className="secondary-text">
                {business?.owner?.fullName}
              </span>
            ) : 'Cliente'}
          </span>
        )}
        primaryButton={{
          id: 'save-edited-user',
          loading,
          htmlType: 'submit',
          children: 'Salvar alterações',
          onClick: async () => {
            await form.validateFields();
            const values = form.getFieldsValue();
            await editSeller({
              variables: {
                ...values,
                id: business?.id,
                employeesNumber: Number(values.employeesNumber),
                revenueAmount: Number(values.revenueAmount),
              },
            });
            await refetch();
          },
        }}
      >

        <Form
          form={form}
          layout="vertical"
        >
          <h3>Sócio Principal</h3>
          <br />
          <Row gutter={12}>
            <Col xs={24} sm={24} lg={14}>
              <Item name="fullName" rules={[{ required: true, message: 'Preencha o nome completo' }]}>
                <Input
                  disabled={business?.owner?.fullName ? isInvalid : false}
                  placeholder="Nome completo"
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} lg={10}>
              <Item name="cpf" rules={[{ required: true, message: 'Preencha o CPF' }]}>
                <Input
                  disabled={business?.owner?.cpf ? isInvalid : false}
                  type="masked"
                  guide
                  mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  placeholder="CPF"
                />
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={14}>
              <Item name="email" rules={[{ required: true, message: 'Preencha o email' }]}>
                <Input placeholder="Email" />
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={10}>
              <Item name="phoneNumber" rules={[{ required: true, message: 'Preencha o número' }]}>
                <Input
                  type="masked"
                  disabled={business?.owner?.phoneNumber
                    ? !!(isInvalid)
                    : false}
                  guide
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  placeholder="Telefone com DDD"
                  alert="Seu telefone não poderá ser alterado enquanto houver um empréstimo ativo. Entre em contato com nosso suporte em caso de urgência"
                />
              </Item>
            </Col>
          </Row>

          <hr />
          <h3>Dados do negócio</h3>
          <br />
          <Row gutter={12}>
            <Col xs={24} sm={24} lg={6}>
              <Item name="employeesNumber" rules={[{ required: true, message: 'Preencha o número de funcionários' }]}>
                <Input
                  type="number"
                  placeholder="Nº de funcionários"
                />
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={6}>
              <Item name="revenueAmount" rules={[{ required: true, message: 'Preencha a fatura mensal' }]}>
                <Input
                  placeholder="Faturamento Mensal"
                  type="currency"
                />
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={12}>
              <Item name="segment" rules={[{ required: true, message: 'Selecione o segmento' }]}>
                <Select
                  className="gyramais-input"
                  placeholder="Selecione o segmento"
                >
                  {businessSegments?.map(({ id, name, value }) => (
                    <Option key={id} value={value}>{name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={12}>
              <Item name="platformType" rules={[{ required: true, message: 'Selecione o ramo de atuação' }]}>
                <Select
                  className="gyramais-input"
                  placeholder="Selecione o ramo de atuação"
                >
                  {businessPlatformTypes?.map(({ id, name, value }) => (
                    <Option key={id} value={value}>{name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>

            <Col xs={24} sm={24} lg={12}>
              <Item name="sector" rules={[{ required: true, message: 'Selecione o setor' }]}>
                <Select
                  className="gyramais-input"
                  placeholder="Selecione o setor"
                >
                  {businessSectors?.map(({ id, name, value }) => (
                    <Option key={id} value={value}>{name}</Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

UserDrawer.propTypes = {
  onClose: PropTypes.func,
  refetch: PropTypes.func,
  visible: PropTypes.bool,
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    legalName: PropTypes.string,
    cnpj: PropTypes.string,
    employeesNumber: PropTypes.number,
    revenueAmount: PropTypes.number,
    platformType: PropTypes.shape({
      value: PropTypes.string,
    }),
    sector: PropTypes.shape({
      value: PropTypes.string,
    }),
    segment: PropTypes.shape({
      value: PropTypes.string,
    }),
    loans: PropTypes.arrayOf(PropTypes.shape({
      interestRate: PropTypes.number,
      status: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    })),
    owner: PropTypes.shape({
      emailVerified: PropTypes.bool,
      passwordToken: PropTypes.string,
      emailVerificationCode: PropTypes.string,
      fullName: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      cpf: PropTypes.string,
    }),
  }),
};

export default UserDrawer;
