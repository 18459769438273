import React,
{
  useState,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Layout,
  Carousel,
  Form,
  message,
} from 'antd';
import {
  Progress,
  SubHeader,
  Button,
  CustomStep,
} from 'components/gyramais';
import {
  SuccessMessage,
} from 'components';
import { formatError } from 'utils';
import { useMutation } from '@apollo/client';
import { CREATE_CLIENT_WITH_LOAN_PATH } from 'routes';
import useDynamicSteps from 'utils/hooks/useDynamicSteps';
import {
  BusinessInfosStep,
  UserAndLoanStep,
} from './components';
import {
  SAVE_CUSTOMER,
  SAVE_PARTNER_LOAN,
} from './graphql';

import './styles.less';

let carouselRef;

const { Content } = Layout;

const { useForm } = Form;

const NewClientScreen = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [installmentsCount, setInstallmentsCount] = useState();
  const [amount, setAmount] = useState(10000);
  const [formParams, setFormParams] = useState({});
  const { pathname } = useLocation();
  const history = useHistory();
  const [form] = useForm();

  const hasLoanCreation = pathname.includes(CREATE_CLIENT_WITH_LOAN_PATH);

  /**
   * @typedef {"business-step-1" | "owner-loan-step-2" | "success"} Steps
   *
   * @type {{
   *  getStepIndex: (stepName: Steps) => number
   *  getStepLength: () => number
   * }}
   */
  const { getStepIndex, getStepLength } = useDynamicSteps([
    { name: 'business-step-1' },
    { name: 'owner-loan-step-2' },
    { name: 'success' },
  ]);

  const headerSteps = [
    {
      subheader: {
        title: 'Olá! Vamos começar com o básico. Preencha os dados do seu cliente.',
        subtitle: 'É preciso reconhecer e confiar para dividir um sonho com alguém. Queremos crescer junto com você e seu cliente, e pra isso, precisamos conhecê-los melhor!',
      },
    },
    {
      subheader: hasLoanCreation ? {
        title: 'Agora, nos informe os dados do sócio principal e configure o empréstimo.',
        subtitle: 'Informe o valor que o seu cliente precisa para crescer e em quantas vezes ele gostaria de nos pagar de volta.',
      } : {
        title: 'Agora, nos informe os dados do sócio principal.',
        subtitle: 'Precisamos saber quem é o usuário responsável pela empresa que está sendo cadastrada',
      },
    },
  ];

  const isLastStep = currentStep === getStepLength() - 1;

  const successSubtitle = hasLoanCreation
    ? 'Fique atento! Em até 24 horas você e seu cliente receberão uma resposta por email ou SMS com os resultados da análise.'
    : 'Cliente cadastrado com sucesso, mas não receberá email até que seja feita uma análise e aprovação. Obrigado.';

  const [savePartnerLoan, { loading: loadingPartnerLoan }] = useMutation(SAVE_PARTNER_LOAN, {
    onCompleted: async () => {
      setCurrentStep(getStepIndex('success'));
      carouselRef.next();
    },
    onError: (error) => message.error(formatError(error), 2),
  });

  const [saveCustomer, { loading: loadingCustomer }] = useMutation(SAVE_CUSTOMER, {
    onCompleted: async ({
      saveCustomer: {
        id,
      },
    }) => {
      if (!hasLoanCreation) {
        setCurrentStep(getStepIndex('success'));
        return;
      }

      await savePartnerLoan({
        variables: {
          customerId: id,
          amount,
          installmentsCount,
        },
      });
    },
    onError: (error) => {
      message.error(formatError(error), 10);
      setCurrentStep(currentStep - 1);
    },
  });

  const checkForm = async (keys) => {
    await form.validateFields(keys);
    const params = {};

    Object.keys(form.getFieldsValue()).map((key) => {
      if (keys.includes(key)) params[key] = form.getFieldsValue()[key];
      return true;
    });

    return params;
  };

  const handleNext = async () => {
    if (currentStep === getStepIndex('business-step-1')) {
      const params = await checkForm([
        'cnpj',
        'name',
        'legalName',
        'employeesNumber',
        'revenueAmount',
        'platformType',
        'sector',
        'segment',
      ]);

      if (params.cnpj.includes('_')) {
        return message.error('Insira um CNPJ válido');
      }

      setFormParams({ ...params });
      setCurrentStep(getStepIndex('owner-loan-step-2'));
      return true;
    }

    if (currentStep === getStepIndex('owner-loan-step-2')) {
      const params = await checkForm([
        'fullName',
        'cpf',
        'email',
        'phoneNumber',
      ]);

      if (hasLoanCreation && !installmentsCount) {
        return message.error('Insira o número de parcelas');
      }

      await saveCustomer({
        variables: {
          ...formParams,
          ...params,
          employeesNumber: Number(formParams.employeesNumber),
        },
      });

      return true;
    }
    return true;
  };

  useEffect(() => {
    if (carouselRef) {
      carouselRef.goTo(currentStep);
    }
  }, [currentStep]);

  const steps = [
    {
      content: (
        <div className="carousel-content">
          <BusinessInfosStep form={form} hasLoanCreation={hasLoanCreation} />
        </div>
      ),
    },
    {
      content: (
        <div className="carousel-content">
          <UserAndLoanStep
            form={form}
            withLoanCreation={hasLoanCreation}
            setInstallmentsCount={hasLoanCreation && setInstallmentsCount}
            setAmount={hasLoanCreation && setAmount}
          />
        </div>
      ),
    },
    {
      content: (
        <div className="carousel-content">
          {isLastStep && (
            <SuccessMessage
              centered
              title="Sua solicitação foi concluída."
              subtitle={successSubtitle}
              primaryButton={{
                text: 'Ir para a página inicial',
                onClick: () => history.push('/'),
              }}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <Layout id="new-client-screen">
      {!isLastStep && (
        <SubHeader
          title={headerSteps[currentStep].subheader.title}
          subtitle={headerSteps[currentStep].subheader.subtitle}
        />
      )}

      {!isLastStep && (
        <Progress percent={(100 / getStepLength()) * (currentStep + 1)} />
      )}

      <Content>
        <Carousel
          ref={(c) => { carouselRef = c; }}
          dots={false}
          swipe={false}
          swipeToSlide={false}
          draggable={false}
          accessibility={false}
        >
          <CustomStep
            steps={steps}
            current={currentStep}
          />
        </Carousel>

        {!isLastStep && (
          <div className="carousel-footer">

            {!isLastStep && (
              <Button
                link
                disabled={loadingCustomer || loadingPartnerLoan}
                onClick={() => {
                  if (currentStep === 0) history.push('/');
                  if (currentStep === getStepLength() - 1) setCurrentStep(0);
                  else {
                    carouselRef.prev();
                    setCurrentStep(currentStep - 1);
                  }
                }}
              >
                {'< Voltar'}
              </Button>
            )}

            <Button
              color="secondary"
              id="business-register-next-button"
              loading={loadingCustomer || loadingPartnerLoan}
              onClick={() => {
                handleNext();
              }}
            >
              Confirmar e prosseguir
            </Button>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default NewClientScreen;
