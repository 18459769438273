/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Modal } from 'antd';

import {
  Button,
} from 'components';

import './styles.less';

/**
 * @typedef {{
 *    id?: string
 *    text: string
 *    onClick?: () => void
 *    visible?: boolean
 *    loading?: boolean
 *    disabled?: boolean
 *    block?: boolean
 *    color?: string
 *    htmlType?: string
 *  }} ModalButtonProps
 *
 * @param {{
 *  children?: import('react').ReactNode[] | import('react').ReactNode
 *  className?: string
 *  title?: string | import('react').ReactNode[] | import('react').ReactNode
 *  visible?: boolean
 *  centered?: boolean
 *  hideFooter?: boolean
 *  closable?: boolean
 *  destroyOnClose?: boolean
 *  width?: number | string
 *  onClose?: () => void
 *  onCancel?: () => void
 *  onOk?: () => void
 *  okText?: string
 *  cancelButtonProps?: any
 *  footer?: import('react').ReactNode
 *  okButton?: ModalButtonProps
 *  cancelButton?: ModalButtonProps
 *  data-cy?: string
 * }} props
 */
const GyramaisModal = ({
  children,
  hideFooter,
  okButton,
  cancelButton,
  className,
  onClose,
  'data-cy': dataCy,
  ...rest
}) => {
  const isOkButtonVisible = okButton !== undefined
    || (okButton && okButton.visible !== true);

  const isCancelButtonVisible = cancelButton !== undefined
    || (cancelButton && cancelButton.visible !== true);

  const noContentModal = !children || (Array.isArray(children) && children.length === 0);

  const shouldRenderFooter = !hideFooter && (isOkButtonVisible || isCancelButtonVisible);

  return (
    <Modal
      className={`gyramais-modal ${className || ''} ${noContentModal ? 'decision-modal' : ''} new-gyramais-styles`}
      onCancel={onClose}
      data-cy={dataCy}
      footer={shouldRenderFooter ? (
        <div className="gyramais-modal-footer">
          {isCancelButtonVisible && (
            <Button
              id={cancelButton?.id}
              link={noContentModal ? false : !cancelButton?.color}
              color={noContentModal ? 'secondary-second' : cancelButton?.color}
              onClick={cancelButton?.onClick}
              loading={cancelButton?.loading}
              disabled={cancelButton?.disabled}
              className={cancelButton?.className}
              block={cancelButton?.block}
              htmlType={cancelButton?.htmlType}
              lg={noContentModal}
            >
              {cancelButton?.text || '< Voltar'}
            </Button>
          )}

          {isOkButtonVisible && (
            <Button
              id={okButton?.id}
              color={okButton?.color || 'primary'}
              onClick={okButton?.onClick}
              loading={okButton?.loading}
              disabled={okButton?.disabled}
              className={okButton?.className}
              block={okButton?.block}
              htmlType={okButton?.htmlType}
              lg={noContentModal}
            >
              {okButton?.text || 'Confirmar e prosseguir'}
            </Button>
          )}
        </div>
      ) : false}
      {...rest}
    >
      {children}
    </Modal>
  );
};

GyramaisModal.confirm = Modal.confirm;

export default GyramaisModal;
