/**
 *
 * @param {{
 *   name: string,
 *   isVisibleWhen?: boolean
 * }[]} steps
 * @returns
 */
const useDynamicSteps = (steps) => {
  /**
   * @param {string} stepName
   * @returns number
   */
  const getStepIndex = (stepName) => {
    let index = 0;

    for (const step of steps) {
      if (step.name === stepName) {
        return index;
      }

      if (step.isVisibleWhen || step.isVisibleWhen === undefined) {
        index++;
      }
    }

    return 0;
  };

  const getStepLength = () => {
    let count = 0;

    for (const step of steps) {
      if (step.isVisibleWhen || step.isVisibleWhen === undefined) {
        count++;
      }
    }

    return count;
  };

  return {
    getStepIndex,
    getStepLength,
  };
};

export default useDynamicSteps;
