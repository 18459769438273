import PropTypes from 'prop-types';

const CustomStep = ({ steps, current }) => steps[current].content;

CustomStep.propTypes = {
  steps: PropTypes.instanceOf(Array),
  current: PropTypes.number,
};

export default CustomStep;
