import React from 'react';
import PropTypes from 'prop-types';
import {
  Progress,
} from 'antd';

import './styles.less';

const GyramaisProgress = ({
  percent, className,
}) => (
  <Progress className={`gyramais-progress ${className || ''}`} showInfo={false} percent={percent} status="active" />
);

GyramaisProgress.propTypes = {
  percent: PropTypes.number,
  className: PropTypes.string,
};

export default GyramaisProgress;
