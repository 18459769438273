import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Card,
} from 'antd';
import {
  Images,
} from 'assets';
import { ME } from 'services/graphql/queries';
import { meVar } from 'services/graphql/cache';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useActionContext } from 'contexts/ActionContext';

const {
  Emprestimo,
  Pessoa,
  Integracao,
  Configuracoes,
  ApiDoc,
} = Images;

const items = [{
  key: '',
  text: 'Clientes',
  to: '/',
  icon: Pessoa,
}, {
  key: 'emprestimos',
  text: 'Empréstimos',
  to: '/emprestimos',
  icon: Emprestimo,
}, {
  key: 'parcelas',
  text: 'Parcelas',
  to: '/parcelas',
  icon: Integracao,
}, {
  key: 'configuracoes',
  text: 'Configurações',
  to: '/configuracoes',
  icon: Configuracoes,
}, {
  key: 'redirect',
  text: 'API Docs',
  to: 'https://developers.gyramais.com.br/',
  icon: ApiDoc,
}];

const Menu = ({ onClose }) => {
  const history = useHistory();
  const location = useLocation();
  const key = location.pathname.split('/')[1];
  const [selected, setSelected] = useState(key);
  const { checkForBusinessAction } = useActionContext();

  const me = useReactiveVar(meVar);
  const {
    data: {
      me: {
        currentBusiness: {
          partner = {},
        } = {},
      } = {},
    } = {},
  } = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
    skip: !me,
  });

  return (
    <>
      {[items.map(({
        key,
        icon,
        text,
        to,
        iconComponent,
      }) => (
        <div key={key}>
          {(key !== 'parcelas' || (partner && checkForBusinessAction('client-installments-access'))) && (
            <Card
              id={`${key}-menu-button`}
              key={key}
              className={`menu-card ${selected === key ? 'active' : ''}`}
              style={{ marginBottom: 10 }}
              hoverable
              onClick={() => {
                if (onClose) onClose();
                if (key === 'redirect') {
                  window.open(to, '_blank');
                } else {
                  history.push(to);
                  setSelected(key);
                }
              }}
            >
              {iconComponent && iconComponent}
              {icon && <img src={icon} alt="text" width={44} />}
              {text}
            </Card>
          )}
        </div>
      ))]}
    </>
  );
};

Menu.propTypes = {
  onClose: PropTypes.func,
};

export default memo(Menu);
