import { gql } from '@apollo/client';

const CNPJ_DETAILS = gql`
  query cnpjDetails(
    $cnpj: String!
  ) {
    cnpjDetails (
      cnpj: $cnpj
    ) {
      legalName
      name
    }
  }
`;

const BUSINESS_NAMES = gql`
  query businesses(
    $businessName: String
  ) {
      businesses (
        businessName: $businessName
      ) {
        name
      }
    }
`;

const BUSINESSES = gql`
  query businesses(
    $limit: Int
    $offset: Int
    $me: Boolean
    $businessName: String
    $cnpj: String
    $partner: Boolean
  ) {
    businesses (
      limit: $limit
      offset: $offset
      me: $me
      businessName: $businessName
      cnpj: $cnpj
      partner: $partner
    ) {
      id
      name
      legalName
      cnpj
      number
      ... on Seller {
        integrations(partner: true) {
          id
          orders {
            id
          }
          payments {
            id
          }
        }
        loans {
          id
          interestRate
          status{
            id
            name
            value
          }
        }
      }
    }
  }
`;

const BUSINESS = gql`
  query business(
    $id: ID!
  ) {
    business (
      id: $id
    ) {
      id
      name
      legalName
      cnpj
      number
      ... on Seller {
        loans {
          id
          number
          ccbNumber
          date
          disbursementDate
          amount
          finalAmount
          totalAmount
          installmentsCount
          finalInstallmentsCount
          installmentAmount
          interestRate
          iofAmount
          annualCET
          business {
            name
            number
          }
          status {
            id
            name
            value
            colors
            step
          }
        }
        users {
          id
          user {
            fullName
            cpf
            email
          }
          types {
            id
            name
          }
          completedAt
        }
      }
    }
  }
`;

const PARTNERS = gql`
  query partners(
    $id: ID!
    $limit: Int
    $offset: Int
  ) {
    partners(
      id: $id
      limit: $limit
      offset: $offset
    ) {
      id
      fullName
      email
    }
  }
`;

const BUSINESS_PLATFORM_TYPES = gql`
query businessPlatformTypes {
  businessPlatformTypes {
    id
    name
    value
  }
}`;

const BUSINESS_SEGMENTS = gql`
query businessSegments {
  businessSegments {
    id
    name
    value
  }
}`;

const BUSINESS_SECTORS = gql`
query businessSectors {
  businessSectors {
    id
    name
    value
  }
}`;

export {
  CNPJ_DETAILS,
  BUSINESS_NAMES,
  BUSINESSES,
  BUSINESS,
  PARTNERS,
  BUSINESS_PLATFORM_TYPES,
  BUSINESS_SEGMENTS,
  BUSINESS_SECTORS,
};
