import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'assets/images';
import {
  Progress,
} from 'antd';
import './styles.less';

const ProcessMessage = ({
  title = 'Verificando arquivo',
  subtitle = 'Precisamos confirmar se seu arquivo está configurado corretamente',
  image = Loading,
  centered,
}) => (
  <div className={`processing-content ${centered ? 'progress-centered' : ''}`}>
    <img src={image} alt="processando arquivo" className="processing-gif" />
    <h3 className="primary-text loading-title">
      {title}
    </h3>

    <h6 className="justified-text">{subtitle}</h6>
    <br />

    <Progress
      className="processing-progress"
      percent={100}
      showInfo={false}
      status="active"
    />
  </div>
);

ProcessMessage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  centered: PropTypes.bool,
};

export default ProcessMessage;
