import { gql } from '@apollo/client';

const SAVE_BUSINESS = gql`
  mutation saveBusiness(
    $id: ID!
    $cnpj: String
    $name: String
    $legalName: String
    $bylawsFileId: ID
    $revenueStatementFilesIds: [ID!]
    $proofOfAddressFileId: ID
    $statementFileId: ID
    $completed: Boolean
  ) {
    saveBusiness(
      id: $id
      name: $name
      legalName: $legalName
      bylawsFileId: $bylawsFileId
      revenueStatementFilesIds: $revenueStatementFilesIds
      proofOfAddressFileId: $proofOfAddressFileId
      statementFileId: $statementFileId
      completed: $completed
      cnpj: $cnpj
    ) {
      id
    }
  }
`;

const DELETE_FILE = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`;

const VALIDATE_CNPJ = gql`
  mutation validateCNPJ($cnpj: String!, $id: ID) {
    validateCNPJ(cnpj: $cnpj, id: $id)
  }
`;

const REMOVE_BUSINESS = gql`
  mutation removeBusiness($id: ID!) {
    removeBusiness(id: $id)
  }
`;

export {
  SAVE_BUSINESS,
  DELETE_FILE,
  VALIDATE_CNPJ,
  REMOVE_BUSINESS,
};
