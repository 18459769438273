/* eslint-disable react/prop-types */
import React from 'react';
import { Success } from 'assets/images';
import { Row, Col } from 'antd';
import { Button } from 'components/gyramais';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';

import './styles.less';

/**
 * @type {React.FC<{
 *  customTopComponent: React.ReactNode
 *  beforeTitle?: string
 *  title?: string
 *  subtitle?: string | React.ReactNode
 *  extra?: React.ReactNode | React.ReactNode[]
 *  primaryButton?: {
 *    id: string
 *    text: string
 *    onClick: Function
 *  }
 *  secondaryButton?: {
 *    id: string
 *    text: string
 *    onClick: Function
 *  }
 *  image: React.ReactNode
 *  className?: string
 *  anotherDivider: Boolean
 *  extraOutsideFooters: Boolean
 *  centered?: Boolean
 *  withoutPadding?: Boolean
 * }>}
 */
const SuccessMessage = ({
  customTopComponent,
  beforeTitle,
  title,
  subtitle,
  extra,
  primaryButton,
  secondaryButton,
  image = Success,
  className,
  anotherDivider,
  extraOutsideFooter,
  centered,
  withoutPadding,
}) => {
  const { width } = useWindowDimensions();

  return (
    <div className={`success-content-container ${className ? `${className}` : ''} ${centered ? 'success-centered' : ''}`}>
      {customTopComponent ? (
        <>
          {customTopComponent}
          <br />
        </>
      ) : (
        <img src={image} alt="checkbox animada" />
      )}

      <div className={`bottom-side ${width <= 768 && withoutPadding ? 'no-padding' : ''} ${!subtitle ? 'no-subtitle' : ''}`}>
        <h3>
          <span className="secondary-text">{beforeTitle || 'Sucesso!'}</span>
          <br />
          {title}
        </h3>

        {subtitle && (
          <>
            <hr className="success-divider" />

            <p className={`no-margin-bottom ${width <= 768 ? 'justified-text' : ''}`}>
              <b>
                {subtitle}
              </b>
            </p>
          </>
        )}

        {anotherDivider && <hr className="success-divider" />}
      </div>
      <Row className={`footer ${width <= 768 && withoutPadding ? 'no-padding' : ''}`} gutter={[0, 16]}>
        {primaryButton && (
          <Col span={24} align="center">
            <Button
              id={primaryButton?.id}
              onClick={primaryButton.onClick}
            >
              {primaryButton.text}
            </Button>
          </Col>
        )}

        {secondaryButton && (
          <Col span={24} align="center">
            <Button
              id={secondaryButton?.id}
              onClick={secondaryButton.onClick}
              link
            >
              {secondaryButton.text}
            </Button>
          </Col>
        )}

        {!extraOutsideFooter && extra}
      </Row>
      {extraOutsideFooter && extra}
    </div>
  );
};

export default SuccessMessage;
