import React, { useMemo, useState } from 'react';
import {
  notification,
  Form,
  Row,
  Col,
  Card,
  Checkbox,
} from 'antd';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import { formatError } from 'utils';

import UploadCard from 'components/cards/UploadCard';
import { Button } from 'components/gyramais';

import BUSINESS from '../../../../../../graphql/business.gql';
import SAVE_USER from '../../graphql/saveUser.gql';
import GENERATE_PROOF_OF_CIVIL_STATUS from '../../graphql/generateProofOfCivilStatus.gql';

import './styles.less';

const { Item } = Form;

const DocumentsStep = ({
  businessId,
  businessUserId,
  loading: parentLoading,
}) => {
  const [isTermCheckboxActive, setIsTermCheckboxActive] = useState(false);

  const {
    data: {
      business,
    } = {},
    refetch,
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    fetchPolicy: 'cache-only',
  });

  const [saveUser, { loading: saveLoading }] = useMutation(SAVE_USER, {
    onCompleted: () => {
      refetch();
      notification.success({ message: 'Informação alterada com sucesso', duration: 10 });
    },
    onError: (error) => notification.error(formatError(error)),
  });
  const [
    generateProofOfCivilStatus,
    { loading: generateProofOfCivilStatusLoading },
  ] = useMutation(GENERATE_PROOF_OF_CIVIL_STATUS, {
    onCompleted: () => {
      refetch();
      notification.success({ message: 'Informação alterada com sucesso', duration: 10 });
    },
    onError: (error) => notification.error(formatError(error)),
  });

  const businessUser = useMemo(() => {
    const businessUser = business?.users?.find(({ id }) => id === businessUserId);
    return businessUser;
  }, [business]);

  const files = [{
    value: 'idCardFileId',
    file: businessUser?.user.idCard,
    name: 'Identidade, CPF ou CNH',
  }, {
    value: 'proofOfAddressFileId',
    file: businessUser?.user.proofOfAddress,
    name: 'Comprovante de endereço',
  }, {
    value: 'proofOfIncomeFileId',
    file: businessUser?.user.proofOfIncome,
    name: 'Imposto de renda',
  }, {
    value: 'proofOfIncomeReceiptFileId',
    file: businessUser?.user.proofOfIncomeReceipt,
    name: 'Recibo do imposto de renda',
  }];

  if (
    ['divorciado', 'casado', 'viuvo'].includes(businessUser?.user.civilStatus?.value)
  ) {
    if (!files.find(({ value }) => value === 'proofOfMariageOrDivorceFileId')) {
      files.push({
        value: 'proofOfMariageOrDivorceFileId',
        file: businessUser?.user.proofOfMariageOrDivorce,
        name: 'Certidão de casamento / Averbação da separação ou divórcio',
      });
    }
  }

  const loading = parentLoading || !business || saveLoading;

  return (
    <Form>
      <Row gutter={12}>
        {files.map(({ value, name, file }) => (
          <Col xs={24} sm={24} key={value}>
            <Item
              name={value}
              rules={[{ required: true, message: `Por favor, insira o ${name}` }]}
            >
              <UploadCard
                id="bylaws-upload"
                accept="image/png,image/jpeg,application/pdf"
                loading={loading}
                label={name}
                actualFile={file}
                onCompleted={async ({ id: fileId }) => {
                  const variables = {
                    id: businessUser.user.id,
                  };
                  variables[value] = fileId;

                  await saveUser({ variables });
                }}
              />
            </Item>
          </Col>
        ))}

        {['solteiro', 'uniao-estavel'].includes(businessUser?.user.civilStatus?.value) && (
          <Col xs={24} sm={24} id="civil-term">
            <Item
              name="Declaração de estado civil"
              rules={[{ required: true, message: 'Por favor, insira a declaração de estado civil' }]}
            >
              <Card id="terms-card">
                {/*  eslint-disable-next-line react/no-unknown-property */}
                <div className="terms-card-title" align="middle">
                  {businessUser?.user.civilStatus?.value === 'solteiro' && 'TERMO DE DECLARAÇÃO DE ESTADO CIVIL'}
                  {businessUser?.user.civilStatus?.value === 'uniao-estavel' && 'TERMO DE DECLARAÇÃO DE UNIÃO ESTÁVEL'}
                </div>
                <div className="terms-card-body">
                  Declaro para os devidos fins e efeitos legais que
                  <b className="highlight-text">{businessUser?.user.fullName}</b>
                  , sócio(a) da empresa
                  <b className="highlight-text">{businessUser?.user.name || businessUser?.user.legalName}</b>
                  , inscrito(a) no CPF sob o número
                  <b className="highlight-text">{businessUser?.user.cpf || '-'}</b>
                  , residente e domicilado(a)
                  à
                  <b className="highlight-text">{businessUser?.user.address?.street}</b>
                  , nº
                  <b className="highlight-text">{businessUser?.user.address?.number}</b>
                  -
                  <b className="highlight-text">{businessUser?.user.address?.district}</b>
                  , CEP
                  <b className="highlight-text">{businessUser?.user.address.postalCode || '-'}</b>
                  , na cidade de
                  <b className="highlight-text">{businessUser?.user.address?.city}</b>
                  -
                  <b className="highlight-text">{businessUser?.user.address?.state}</b>
                  {businessUser?.user.type === 'solteiro' && (
                  <span>
                    , permanece no estado civil de
                    <b className="highlight-text">Solteiro</b>
                    no momento em que assino este termo, livre de relações
                    de vida em comum ou união estável com outra pessoa
                    nas condições previstas no artivo 1.723 do Código Civil.
                  </span>
                  )}
                  {businessUser?.user.type === 'uniao-estavel' && (
                  <span>
                    , convive em
                    <b className="highlight-text">União Estável</b>
                    , de natureza familiar, pública e duradoura,
                    com o objetivo de constituição da família, nos termos dos artivos
                    1.723 e seguintes do Código Civil.
                  </span>
                  )}
                </div>
              </Card>
              {!!businessUser?.user.proofOfCivilStatus && (
                'Declaração já gerada!'
              )}

              {!businessUser?.user.proofOfCivilStatus && (
              <>
                <Checkbox
                  value={isTermCheckboxActive}
                  onChange={(e) => setIsTermCheckboxActive(e)}
                >
                  Confirmo que li, aceito e concordo com as informações
                  descritas acima. Reconheço a assinatura deste termo como
                  válida e comprovante do estado civil do sócio adicionado.
                </Checkbox>

                <Button
                  disabled={!isTermCheckboxActive}
                  loading={generateProofOfCivilStatusLoading}
                  onClick={() => {
                    generateProofOfCivilStatus({
                      variables: {
                        ip: 'teste',
                        id: businessUser.id,
                        overwriteBusinessId: business.id,
                      },
                    });
                  }}
                >
                  Gerar declaração de estado civil
                </Button>
              </>
              )}

            </Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default DocumentsStep;
