import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  message,
  notification,
} from 'antd';
import {
  Drawer,
  Input,
  Information,
} from 'components/gyramais';
import { formatError, CPFValidator } from 'utils';
import {
  useMutation,
} from '@apollo/client';
import {
  GET_USER_BY_CPF,
  ADD_PARTNER_USER,
} from './graphql';

const { Item, useForm } = Form;

const FindUserDrawer = ({
  visible,
  onClose,
  businessId,
  refetch,
  onAddNewUser,
}) => {
  const [form] = useForm();
  const [userSearched, setUserSearched] = useState(false);
  const [user, setUser] = useState();
  const [saveBusinessUser, { loading: saveLoading }] = useMutation(ADD_PARTNER_USER, {
    onCompleted: () => {
      notification.success({ message: 'Usuário adicionado com sucesso' });
      refetch();
      form.resetFields();
      setUserSearched(false);
      setUser();
      onClose();
    },
    onError: (error) => message.error(formatError(error)),
  });
  const [getUserByCPF, { loading }] = useMutation(GET_USER_BY_CPF, {
    onCompleted: ({
      userByCPF,
    }) => {
      setUserSearched(true);
      setUser(userByCPF);
    },
    onError: (error) => {
      setUserSearched(true);
      setUser();
      notification.error({
        message: formatError(error),
      });
    },
  });
  const handleClose = () => {
    form.resetFields();
    setUserSearched(false);
    setUser();
    onClose();
  };

  return (
    <Drawer
      visible={visible}
      width="50%"
      closable
      id="find-user-drawer"
      title="Adicionar usuário"
      primaryButton={{
        children: 'Adicionar',
        id: 'continue-add-business-user-button',
        loading: loading || saveLoading,
        disabled: !userSearched,
        onClick: () => {
          if (user) {
            saveBusinessUser({
              variables: {
                businessId,
                id: user.id,
              },
            });
          } else {
            onAddNewUser(form.getFieldsValue().cpf);
            form.resetFields();
            setUserSearched(false);
            setUser();
          }
        },
      }}
      onClose={handleClose}
    >
      <Form
        layout="vertical"
        form={form}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Item name="cpf" rules={[{ required: true, message: 'Insira o CPF do usuário.' }]}>
              <Input
                type="masked"
                guide
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                placeholder="CPF"
                style={{ marginTop: 10 }}
                onChange={(e) => {
                  if (e.target.value.replace(/\D/g, '').length < 11) {
                    setUserSearched(false);
                    setUser();
                  }
                  if (e.target.value.replace(/\D/g, '').length === 11) {
                    if (CPFValidator(e.target.value.replace(/\D/g, ''))) {
                      getUserByCPF({ variables: { cpf: e.target.value } });
                    } else {
                      notification.error({
                        message: 'CPF inválido',
                        description: 'Insira um CPF válido para continuar',
                      });
                      form.setFieldsValue({ cpf: '' });
                    }
                  }
                }}
              />
            </Item>
          </Col>
        </Row>
      </Form>
      {userSearched && user && (
        <>
          <hr className="divider" />
          <h3 className="title">
            {`Deseja adicionar ${user.firstName} como usuário da empresa?`}
          </h3>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24}>
              <Information detailed label="Nome" value={user.fullName} />
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Information detailed label="Email" value={user.email} />
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Information detailed label="Telefone celular" value={user.phoneNumber} />
            </Col>
          </Row>
        </>
      )}
      {userSearched && !user && (
        <>
          <hr className="divider" />
          <h3 className="title">
            Novo usuário
          </h3>
        </>
      )}
    </Drawer>
  );
};

FindUserDrawer.propTypes = {
  businessId: PropTypes.string,
  refetch: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default FindUserDrawer;
