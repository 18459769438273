import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, message,
} from 'antd';
import { useMutation } from '@apollo/client';
import { formatError } from 'utils';
import {
  Button,
  Input,
} from 'components/gyramais';
import { LOG_IN_PARTNER, ADD_SESSION } from './graphql';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';

import './styles.less';

const { Item, useForm } = Form;

const LogInForm = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  const [form] = useForm();

  const [addSession] = useMutation(ADD_SESSION, {
    onCompleted: () => {
      history.push('/');
    },
  });

  const [logIn, { loading }] = useMutation(LOG_IN_PARTNER, {
    onError: (error) => message.error(formatError(error)),
    onCompleted: ({ logInPartner }) => {
      addSession({
        variables: {
          me: logInPartner,
        },
      });
    },
  });

  return (
    <Form
      id="form-login-component"
      form={form}
      layout="vertical"
      onFinish={(values) => logIn({ variables: { ...values } })}
    >
      <h2>
        Entre no
        {' '}
        <span className="secondary-text">Dashboard</span>
      </h2>

      <br />
      <Item name="email" rules={[{ required: true, message: 'Por favor insira seu e-mail.' }]}>
        <Input
          id="email"
          placeholder="Inserir e-mail"
        />
      </Item>

      <Item name="password" rules={[{ required: true, message: 'Por favor insira sua senha.' }]}>
        <Input
          id="password"
          autoComplete="new-password"
          type="password"
          placeholder="Inserir senha"
        />
      </Item>

      <Button
        link
        block={width <= 768}
        onClick={() => {
          history.push('/esqueci-minha-senha');
        }}
      >
        Esqueci minha senha
      </Button>

      <br />
      {width > 768 && (
        <br />
      )}

      <Button
        block
        loading={loading}
        id="login-button"
        className="button-login"
        htmlType="submit"
      >
        Entrar
      </Button>

      <br />
      <hr />
      <br />

      <div className="form-footer">
        <p>Ainda não é parceiro?</p>
        <Button
          link
          block
          onClick={() => {
            window.open(process.env.GYRAMAIS_LANDING_URL, '_blank');
          }}
        >
          Envie uma solicitação
        </Button>
      </div>

    </Form>
  );
};

export default memo(LogInForm);
