import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  message,
  Modal,
} from 'antd';
import { DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  ME,
} from 'services/graphql/queries';
import {
  REMOVE_BUSINESS_USER,
} from 'services/graphql/mutations';
import { Images } from 'assets';
import { formatError, formatCPF } from 'utils';

const { IconeSocio } = Images;

const BusinessUserCard = ({
  user: {
    id: businessUserId,
    completedAt,
    user,
    types,
  },
  refetch,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const [removeBusinessUser, { loading: removeLoading }] = useMutation(REMOVE_BUSINESS_USER, {
    onCompleted: () => refetch(),
    onError: (error) => message.error(formatError(error)),
    refetchQueries: [{ query: ME }],
  });
  return (
    <div key={id} style={{ display: 'flex' }}>
      <Card
        className={`business-card ${!completedAt ? 'partner-card' : 'partner-card partner-card-success'}`}
        style={{
          borderRight: 'none',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        id={`partner-${user?.fullName?.toLowerCase().split(' ').join('-') || '/'}-card`}
        hoverable
        onClick={() => {
          history.push('/cadastro/empresa-socios', {
            businessUserId,
            businessId: id,
          });
        }}
      >
        <Row style={{ display: 'flex', alignItems: 'center' }} gutter={12}>
          <Col style={{ width: 48, display: 'flex', alignItems: 'center' }}>
            <img className="wl-icon" src={IconeSocio} alt="icone-socio" height={38} />
          </Col>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: 'calc(100% - 48px - 32px)',
            }}
          >
            <h4
              style={{
                margin: 0,
              }}
            >
              {
                completedAt ? `${user.fullName}` : 'INCOMPLETO'
              }
            </h4>
            <Row
              style={{
                fontSize: 12,
                color: '#7c7c7c',
                lineHeight: 1.3,
                marginBottom: 2,
              }}
            >
              {completedAt
                ? types.map(({ id, name }) => (
                  <span style={{ width: '100%' }} key={id}>{`${name}`}</span>
                )) : (user.fullName && <span>{user.fullName}</span>)
                || <span>{user.email}</span>}
            </Row>
            {user.cpf && (
              <Row
                style={{
                  fontSize: 12,
                  color: '#7c7c7c',
                  lineHeight: 1.3,
                }}
              >
                <span>
                  {formatCPF(user.cpf)}
                </span>
              </Row>
            )}
          </Col>
          {completedAt
          && (
            <CheckCircleOutlined />
          )}
        </Row>
      </Card>
      {user
        && (
          <Card
            id={`delete-partner-${user?.fullName?.toLowerCase().split(' ').join('-')}` || '/'}
            className={`business-card ${!completedAt ? 'partner-card' : 'partner-card partner-card-success'}`}
            style={{
              borderLeft: 'none',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              display: 'flex',
              alignItems: 'center',
              width: '20%',
            }}
            hoverable
            onClick={() => {
              Modal.confirm({
                centered: true,
                title: `Você deseja realmente remover a pessoa ${user.email}?`,
                okButtonProps: { id: `delete-partner-${user?.fullName?.toLowerCase().split(' ').join('-') || '/'}-confirm` },
                okText: 'OK',
                cancelText: 'Cancelar',
                confirmLoading: removeLoading,
                onOk: async () => {
                  await removeBusinessUser({
                    variables: {
                      id: businessUserId,
                    },
                  });
                },
              });
            }}
          >
            <DeleteOutlined
              style={{
                color: 'red',
                fontSize: 24,
                transform: 'translateX(-4px)',
              }}
            />
          </Card>
        )}
    </div>
  );
};

BusinessUserCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    completedAt: PropTypes.string,
    user: PropTypes.shape({
      fullName: PropTypes.string,
      types: PropTypes.shape(),
      email: PropTypes.string,
      cpf: PropTypes.string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    types: PropTypes.array,
  }),
  refetch: PropTypes.func,
};

export default BusinessUserCard;
