import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'components/gyramais';

const ContractButton = ({
  statusValue,
  ccb,
  bankerId,
  bankerValue,
  additive,
}) => (
  <Button
    onClick={async () => {
      if (additive) window.open(additive.url);
      if (!additive) {
        if (statusValue === 'awaiting-signature' && bankerValue === 'money-plus') {
          window.open(`${process.env.MONEYPLUS_URI}&code=${bankerId}`);
        } else window.open(ccb.url);
      }
    }}
  >
    {`Ver ${(['awaiting-signature', 'signed', 'active', 'lawsuit']).includes(statusValue) ? 'contrato' : 'aditivo'}`}
  </Button>
);

ContractButton.propTypes = {
  additive: PropTypes.shape(),
  ccb: PropTypes.shape(),
  bankerId: PropTypes.string,
  bankerValue: PropTypes.string,
  statusValue: PropTypes.string,
};

export default ContractButton;
