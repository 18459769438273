import { gql } from '@apollo/client';

const CIVIL_STATUSES = gql`
  query civilStatuses(
    $limit: Int
    $offset: Int
  ) {
    civilStatuses(
      limit: $limit
      offset: $offset
    ) {
      id
      name
      value
    }
  }
`;

export default CIVIL_STATUSES;
export {
  CIVIL_STATUSES,
};
