import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Divider,
  Tag,
} from 'antd';

import './styles.less';

const ObjectInfo = ({
  value,
  child,
}) => (
  <div className={child ? 'object-info child' : 'object-info'}>
    {value.map(({
      title, type, description, required, value: childrenValues,
    }, index) => (
      <div key={title}>
        <Row>
          <b>
            {title}
          </b>
          <div className="type">
            {type}
          </div>
          {required === true && (
            <Tag className="requiredTag" color="red">
              OBRIGATÓRIO
            </Tag>
          )}
        </Row>

        <p>
          {description}
        </p>

        {childrenValues && (
          <ObjectInfo value={childrenValues} child />
        )}

        {index < value.length - 1 && (
          <Divider className="object-info-divider" />
        )}
      </div>
    ))}
  </div>
);

ObjectInfo.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
  child: PropTypes.bool,
};

export default ObjectInfo;
