import { gql } from '@apollo/client';

const ME = gql`
  query me {
    me {
      id
      firstName
      fullName
      cpf
      phoneNumber
      email
      currentBusiness {
        id
        name
        cnpj
        ...on Partner {
          bankAccount {
            id
            bank {
              id
            }
            branchCode
            accountNumber
            currentAccount
            approvedAt
            rejectedAt
          }
          approvedAt
          colors
          logo {
            id
            url
          }
        }
        legalName
      }
    }
  }
`;

const USER = gql`
  query user(
    $id: ID!
  ) {
    user(id: $id) {
      id
      fullName
      email
      phoneNumber
      cpf
    }
  }
`;

export {
  ME,
  USER,
};
