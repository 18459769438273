import React from 'react';
import {
  Layout,
} from 'antd';
import {
  SubHeader,
} from 'components/gyramais';
import {
  ApiUserCard,
  DocCard,
  WebhookCard,
} from 'components';
import './styles.less';
import { useActionContext } from 'contexts/ActionContext';
import BankerUrlConfig from './components/BankerUrlConfig';

const {
  Content,
} = Layout;

const APIScreen = () => {
  const { checkForBusinessAction } = useActionContext();

  return (
    <Layout className="dashboard-api api-screen" id="apiscreen">
      <SubHeader
        title="API"
      />

      <Content>
        <ApiUserCard />
        <WebhookCard />
        {checkForBusinessAction('partner-is-banker') && <BankerUrlConfig />}
        <DocCard />
      </Content>
    </Layout>
  );
};

export default APIScreen;
