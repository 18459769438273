import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Select,
  Button,
  Row,
  message,
  Col,
  Spin,
  Modal,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import {
  BUSINESS_USER,
  CIVIL_STATUSES,
} from 'services/graphql/queries';
import { SAVE_BUSINESS_USER, COMPLETE_BUSINESS_USER } from 'services/graphql/mutations';
import {
  CivilPartnerForm,
  BusinessUserUploadMarriedForm,
} from 'components';
import { Images } from 'assets';
import { formatError } from 'utils';

const { Item, useForm } = Form;
const { LogoGyraG } = Images;

const CivilStatusForm = ({
  id,
  businessId,
  goBack,
}) => {
  const [form] = useForm();
  const history = useHistory();
  const [civilStatus, setCivilStatus] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const {
    data: { businessUser: { user: { fullName } = {} } = {} } = {},
    loading,
  } = useQuery(BUSINESS_USER, {
    skip: !id,
    variables: { id },
    partialRefetch: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ businessUser: { user: { civilStatus } = {} } = {} }) => {
      form.setFieldsValue({
        civilStatusId: civilStatus && civilStatus.id,
      });
    },
  });
  const { data: { civilStatuses = [] } = {} } = useQuery(CIVIL_STATUSES, {
    partialRefetch: true,
    onError: (error) => message.error(formatError(error)),
  });
  const [completeBusinessUser,
    { loading: completeLoading }] = useMutation(COMPLETE_BUSINESS_USER, {
    onCompleted: async () => {
      history.push(`/empresas/${businessId}`);
    },
  });
  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: async ({ saveBusinessUser: { user: { civilStatus } } }) => {
      if (civilStatus.value === 'casado') {
        setCivilStatus('casado');
        setIsVisible(true);
      } else if (civilStatus.value === 'divorciado') {
        setCivilStatus('divorciado');
        setIsVisible(true);
      } else {
        await completeBusinessUser({ variables: { id } });
      }
    },
    onError: (error) => message.error(formatError(error)),
  });
  const onFinish = async (values) => {
    await form.validateFields();
    saveBusinessUser({
      variables: {
        id,
        ...values,
      },
    });
  };
  return (
    <Spin
      indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
      spinning={loading}
      style={{ transform: 'translateX(-16px)' }}
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <h3 className="wl-text" style={{ marginBottom: 24 }}>
          {`Qual o estado civil de ${fullName}?`}
        </h3>
        <Item name="civilStatusId" rules={[{ required: true, message: 'Por favor selecione o seu estado civil.' }]}>
          <Select
            placeholder="Estado Civil"
            style={{ width: '100%' }}
          >
            {civilStatuses.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Row gutter={12} className="fixed-footer">
          <Col span={10}>
            <Button
              block
              disabled={saveLoading || completeLoading}
              ghost
              type="primary"
              onClick={() => goBack()}
            >
              Voltar
            </Button>
          </Col>
          <Col span={14}>
            <Button
              id="business-user-status-continue"
              block
              loading={saveLoading || completeLoading}
              type="primary"
              htmlType="submit"
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        visible={isVisible}
        footer={null}
        onCancel={() => setIsVisible(false)}
      >
        {civilStatus === 'divorciado' && (
          <BusinessUserUploadMarriedForm
            id={id}
            businessId={businessId}
            goBack={() => setIsVisible(false)}
            onCompleted={() => setIsVisible(false)}
          />
        )}
        {civilStatus === 'casado' && (
          <CivilPartnerForm id={id} goBack={() => setIsVisible(false)} onCompleted={() => setCivilStatus('casado docs')} />
        )}
        {civilStatus === 'casado docs' && (
          <BusinessUserUploadMarriedForm
            id={id}
            businessId={businessId}
            goBack={() => setCivilStatus('casado')}
            onCompleted={() => setIsVisible(false)}
          />
        )}
      </Modal>
    </Spin>
  );
};

CivilStatusForm.propTypes = {
  id: PropTypes.string,
  businessId: PropTypes.string,
  goBack: PropTypes.func,
};

export default memo(CivilStatusForm);
