import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Checkbox,
  Row,
  message,
  Col,
  Spin,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { Images } from 'assets';
import {
  BUSINESS_USER,
} from 'services/graphql/queries';
import { SAVE_BUSINESS_USER } from 'services/graphql/mutations';
import { formatError } from 'utils';

const { Item, useForm } = Form;
const { LogoGyraG } = Images;

const BusinessUserTypeForm = ({
  id,
  onCompleted,
  goBack,
}) => {
  const [form] = useForm();
  const {
    data: { businessUser: { user: { fullName } } = {} } = {},
    loading: userLoading,
  } = useQuery(BUSINESS_USER, {
    skip: !id,
    variables: { id },
    partialRefetch: true,
    onCompleted: ({ businessUser: { types = [] } = {} }) => {
      types.forEach(({ value }) => {
        if (value === 'representantelegal') form.setFieldsValue({ legalRepresentative: true });
        if (value === 'socio') form.setFieldsValue({ partner: true });
      });
      form.setFieldsValue({ guarantor: true });
    },
  });
  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: () => onCompleted(),
    onError: (error) => message.error(formatError(error)),
  });
  const onFinish = async (values) => {
    await form.validateFields();
    const { legalRepresentative, partner, guarantor } = values;
    const typesValues = [];
    if (legalRepresentative === true) typesValues.push('representantelegal');
    if (partner === true) typesValues.push('socio');
    if (guarantor === true) typesValues.push('avalista');
    saveBusinessUser({
      variables: {
        id,
        typesValues,
      },
    });
  };
  return (
    <Spin
      indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
      spinning={userLoading}
      style={{ transform: 'translateX(-16px)' }}
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <h3 className="wl-text" style={{ marginBottom: 24 }}>
          {`Quais são as responsabilidades de ${fullName}?`}
        </h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Item name="legalRepresentative" valuePropName="checked">
            <Checkbox>
              Representante Legal
            </Checkbox>
          </Item>
          <Item name="partner" valuePropName="checked">
            <Checkbox>
              Sócio
            </Checkbox>
          </Item>
          <Item name="guarantor" valuePropName="checked">
            <Checkbox disabled>
              Avalista
            </Checkbox>
          </Item>
        </div>
        <Row type="flex" gutter={12} className="fixed-footer">
          <Col span={10}>
            <Button
              block
              disabled={saveLoading}
              ghost
              type="primary"
              onClick={() => goBack()}
            >
              Voltar
            </Button>
          </Col>
          <Col span={14}>
            <Button
              id="business-user-type-continue"
              block
              loading={saveLoading}
              type="primary"
              htmlType="submit"
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

BusinessUserTypeForm.propTypes = {
  id: PropTypes.string,
  onCompleted: PropTypes.func,
  goBack: PropTypes.func,
};

export default memo(BusinessUserTypeForm);
