// @ts-check
import React, { useEffect, useMemo } from 'react';
import {
  Form,
  Row,
  Col,
} from 'antd';
import { Input } from 'components/gyramais';

import { useQuery } from '@apollo/client';

// @ts-ignore
import BUSINESS from '../../../../../../graphql/business.gql';

import './styles.less';

const { Item } = Form;

const CivilPartnerStep = ({
  businessId,
  businessUserId,
  civilPartnerDoeNotExists,
  form,
}) => {
  const {
    data: {
      business,
    } = {},
    loading: businessLoading,
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    fetchPolicy: 'cache-only',
  });

  const businessUser = useMemo(() => {
    const businessUser = business?.users?.find(({ id }) => id === businessUserId);
    return businessUser;
  }, [business]);

  const civilPartner = businessUser?.user?.civilPartner;

  const loading = businessLoading;

  useEffect(() => {
    if (businessUser?.user) {
      const { user } = businessUser;
      const { civilPartner } = user;

      const {
        fullName,
        cpf,
        birthDate,
        phoneNumber,
        email,
      } = civilPartner ?? {};
      let birthDateString = '';

      if (birthDate) {
        const [year, month, day] = birthDate?.split('T')[0]?.split('-');
        birthDateString = `${day}/${month}/${year}`;
      }
      form.setFieldsValue({
        civilPartnerFullName: fullName,
        civilPartnerCpf: cpf,
        civilPartnerBirthDate: birthDateString,
        civilPartnerPhoneNumber: phoneNumber,
        civilPartnerEmail: email,
      });
    } else {
      form.resetFields();
    }
  }, [businessUser, businessId, businessUserId, business]);

  return (
    <Form
      id="profile-step"
      form={form}
    >

      <Row align="middle" id="add-user-account" gutter={24}>

        <Col xs={24} sm={24} md={12}>
          <Item
            label="CPF"
            name="civilPartnerCpf"
            rules={[{
              required: true,
              whitespace: true,
              message: 'Por favor preencha o cpf',
            }]}
          >
            <Input
              type="masked"
              // @ts-ignore
              mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              disabled={loading}
              placeholder="CPF"
            />
          </Item>
        </Col>
        {(civilPartner || civilPartnerDoeNotExists) && (
          <>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="Email"
                name="civilPartnerEmail"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha o email',
                }]}
              >
                <Input
                  disabled={loading}
                  placeholder="Email"
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="Nome completo"
                name="civilPartnerFullName"
                rules={[{
                  required: true,
                  message: 'Por favor preencha o nome',
                }]}
              >
                <Input
                  disabled={loading || !businessUser}
                  placeholder="Nome completo"
                />
              </Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Item
                label="Data de nascimento"
                name="civilPartnerBirthDate"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha a data de nascimento',
                }]}
              >
                <Input
                  type="masked"
                  // @ts-ignore
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  disabled={loading || !businessUser}
                  placeholder="Data de nascimento"
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="TELEFONE CELULAR"
                name="civilPartnerPhoneNumber"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha o telefone celular',
                }]}
              >
                <Input
                  type="masked"
                  guide
                  // @ts-ignore
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  disabled={loading || !businessUser}
                  placeholder="Telefone celular"
                />
              </Item>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default CivilPartnerStep;
