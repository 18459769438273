/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  Upload,
  notification,
} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
  LockOutlined,
} from '@ant-design/icons';
import {
  Information,
  Alert,
  Progress,
  Button,
} from 'components/gyramais';

import { formatError } from 'utils';
import { useMutation } from '@apollo/client';
import { Gif12 } from 'assets/images';
import {
  UPLOAD_FILE,
  UPLOAD_FILE_WITH_SIGNATURE,
  DELETE_FILE,
} from './graphql';

import './styles.less';

const UploadCard = ({
  label,
  onCompleted,
  onDelete,
  id,
  hideFile,
  actualFile,
  alert,
  accept,
  loadingText = 'Carregando arquivo ...',
  loadingSubText = 'Este processo pode levar alguns instantes.',
  multiples,
  refetch,
  validateSignature,
  incompleteAnimation,
  actionButtonText,
  blocked,
  blockMessage,
  loanId,
}) => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);

  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE, {
    onCompleted: ({ uploadFile: { id, url, name } }) => {
      if (multiples) {
        const setup = files.concat([{ id, url, name }]);
        setFiles(setup);
      } else setFiles([{ id, url, name }]);

      onCompleted({ id, url, name });
    },

    onError: (error) => notification.error({ message: formatError(error), duration: 10 }),

    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (ev) => {
          setProgress((ev.loaded / ev.total) * 100);
        },
      },
    },
  });

  const [uploadFileWithSignature, { loading: uploadFileWithSignatureLoading }] = useMutation(UPLOAD_FILE_WITH_SIGNATURE, {
    onCompleted: ({ uploadFile: { id, url, name } }) => {
      if (multiples) {
        const setup = files.concat([{ id, url, name }]);
        setFiles(setup);
      } else setFiles([{ id, url, name }]);

      onCompleted({ id, url, name });
    },

    onError: (error) => notification.error({ message: formatError(error), duration: 10 }),

    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (ev) => {
          setProgress((ev.loaded / ev.total) * 100);
        },
      },
    },
  });

  const [deleteFile, { loading: deleteLoading }] = useMutation(DELETE_FILE, {
    onCompleted: () => {
      notification.success({ message: 'Arquivo removido com sucesso' });
      if (multiples && refetch) refetch();
    },
    onError: (error) => notification.error({ message: formatError(error), duration: 10 }),
  });

  useEffect(() => {
    if (actualFile) setFiles(Array.isArray(actualFile) ? actualFile : [actualFile]);
  }, [actualFile]);

  const loading = uploadFileLoading || uploadFileWithSignatureLoading;
  const shouldShowFileInfo = !loading && !hideFile && files.length > 0;

  const handleUploadFile = ({ file }) => {
    if (validateSignature) {
      uploadFileWithSignature({
        variables: {
          file,
          loanId,
        },
      });
      return;
    }

    uploadFile({
      variables: {
        file,
      },
    });
  };

  return (
    <>
      {Boolean(alert) && (
        <Alert
          className="upload-card-alert"
          message={alert}
        />
      )}

      <Card className={`gyramais-upload-card ${incompleteAnimation ? 'incomplete' : ''}`}>
        <Row align="middle">
          <>
            <Col xs={24} sm={24} md={12}>
              <h4 className="no-margin-bottom">
                {label}
              </h4>
            </Col>

            <Col xs={24} sm={24} md={12} className="upload-button" id={`${id}-tip-container`}>
              {blocked ? (
                <Button disabled block>
                  <LockOutlined />
                  {blockMessage}
                </Button>
              ) : (
                <Upload
                  className="gyramais-btn"
                  id={id}
                  disabled={loading}
                  accept={accept}
                  showUploadList={false}
                  multiple={multiples}
                  disable={loading}
                  beforeUpload={(file) => {
                    const types = accept?.split(',');

                    if (accept === '*' || !accept) {
                      return true;
                    }

                    if (!types.includes(file.type)) {
                      const formattedAcceptFormats = accept.split(',').map((element) => element.split('/')[1]).join(', ');

                      notification.error({
                        // eslint-disable-next-line max-len
                        message: `O arquivo enviado está no formato ${file.type}. Por favor, envie um arquivo de formato ${formattedAcceptFormats} e tente novamente.`,
                      });

                      return false;
                    }

                    return true;
                  }}
                  customRequest={handleUploadFile}
                >
                  <div className="upload-button-label">{actionButtonText || 'Enviar Arquivo'}</div>
                </Upload>
              )}
            </Col>

            {(loading) && (
              <Col xs={24} className="loading">
                <hr className="file-divider" />

                <img
                  src={Gif12}
                  alt="indicador de carregamento"
                />

                <div className="loading-text">
                  {loadingText}
                </div>

                <div className="loading-sub-text">
                  {loadingSubText}
                </div>

                <Progress
                  percent={progress}
                />
              </Col>
            )}

            {shouldShowFileInfo && (
              <Col xs={24} sm={24} md={24}>
                <hr className="file-divider" />

                {files.map((file) => (
                  <Row
                    key={file.id}
                    className={`information-container ${id}-arquivo`}
                    align="middle"
                    justify="space-between"
                    gutter={14}
                  >
                    <Col xs={18} sm={20} md={18}>
                      <Information
                        label="Arquivo"
                        value={file.name}
                      />
                    </Col>

                    <Col xs={3} sm={2} md={1}>
                      <EyeOutlined
                        className="upload-card-icon secondary-text"
                        onClick={() => window.open(file.url, '_blank')}
                      />
                    </Col>

                    {multiples && (
                      <>
                        <Col xs={3} sm={2} md={1}>
                          {deleteLoading ? (
                            <LoadingOutlined className="upload-card-icon danger-icon" />
                          ) : (
                            <DeleteOutlined
                              className="upload-card-icon danger-icon"
                              onClick={async () => {
                                await deleteFile({ variables: { id: file.id } });
                                onDelete({ id: file.id });

                                const newArrayOfFiles = [];
                                files
                                  .map((item) => item.id !== file.id && newArrayOfFiles.push(item));

                                setFiles(newArrayOfFiles);
                              }}
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                ))}
              </Col>
            )}
          </>
        </Row>
      </Card>
    </>
  );
};

UploadCard.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  alert: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape(),
  ]),
  onCompleted: PropTypes.func,
  onDelete: PropTypes.func,
  refetch: PropTypes.func,
  hideFile: PropTypes.bool,
  multiples: PropTypes.bool,
  actualFile: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  accept: PropTypes.string,
  loadingText: PropTypes.string,
  loadingSubText: PropTypes.string,
  validateSignature: PropTypes.bool,
  incompleteAnimation: PropTypes.bool,
  actionButtonText: PropTypes.string,
  loanId: PropTypes.string,
};

export default UploadCard;
