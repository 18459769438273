import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Alert,
  Col,
  Upload,
} from 'antd';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Information,
} from 'components/gyramais';
import './styles.less';

const OptionCard = ({
  label,
  action,
  showFile,
  actualFile,
  alert,
  accept,
  upload,
  caption,
  button,
  buttonLabel,
  buttonId,
  loading,
  primaryBtnClassName,
  secondaryBtn,
  uploadProps,
  multiple,
}) => {
  const [file, setFile] = useState();

  const fileExtension = accept && accept.includes('json') ? 'json' : 'csv';

  useEffect(() => setFile(actualFile), [actualFile]);
  return (
    <>
      {Boolean(alert) && (
        <Alert
          className="upload-card-alert"
          message={alert}
          type="info"
          showIcon
          icon={<ExclamationCircleOutlined className="bank-alert-icon" />}
        />
      )}
      <Card className="gyramais-option-card">
        <Row align="middle" justify="space-between" gutter={[0, 14]}>
          {loading ? (
            <>
              <Col span={24} className="centered-col">
                <h3 className="loading-title">Carregando arquivo...</h3>
                <br />
                <span className="loading-subtitle">Este processo pode levar alguns segundos</span>
              </Col>
            </>
          ) : (
            <>
              <Col xs={24} sm={24} md={24} lg={12}>
                <span className="option-card-label">
                  {label}
                </span>
                <br />
                <span className="option-card-caption">
                  {caption}
                </span>
              </Col>
              {
                upload ? (
                  <>
                    <Col xs={24} sm={24} md={4} offset={2} className="button">
                      {secondaryBtn && (
                      <Button
                        block
                        link
                        className="import-aux-button"
                        onClick={secondaryBtn.onClick}
                      >
                        {secondaryBtn.icon}
                        {' '}
                        {secondaryBtn.text}
                      </Button>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={5} className="upload-button">
                      <Upload
                        id="upload-file"
                        multiple={multiple}
                        className={primaryBtnClassName}
                        disabled={loading}
                        accept={accept}
                        showUploadList={false}
                        disable={loading}
                        beforeUpload={() => false}
                        onChange={({ file }) => {
                          action({ file, fileExtension });
                        }}
                        {...uploadProps}
                      >
                        <Button className="upload-button-wrapper" id={buttonId} block>{buttonLabel || 'Enviar arquivo'}</Button>
                      </Upload>
                    </Col>
                  </>
                )
                  : (
                    <Col xs={24} sm={24} md={24} lg={10} xl={5} className="button">
                      <Button
                        className={`normal-button ${primaryBtnClassName}`}
                        onClick={button}
                        id={buttonId}
                        block
                      >
                        {buttonLabel || 'Adicionar novo cliente'}
                      </Button>
                    </Col>
                  )
              }
              {showFile && file && (
                <>
                  <Col span={24}>
                    <hr className="file-divider" />
                    <Information
                      detailed
                      label="Arquivo"
                      value={file.name}
                    />
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
      </Card>
    </>
  );
};

OptionCard.propTypes = {
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  alert: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  action: PropTypes.func,
  showFile: PropTypes.bool,
  actualFile: PropTypes.shape(),
  accept: PropTypes.string,
  buttonId: PropTypes.string,
  upload: PropTypes.bool,
  caption: PropTypes.string,
  button: PropTypes.func,
  loading: PropTypes.bool,
  primaryBtnClassName: PropTypes.string,
  secondaryBtn: PropTypes.shape({
    icon: PropTypes.node,
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  uploadProps: PropTypes.shape(),
  multiple: PropTypes.bool,
};

export default OptionCard;
