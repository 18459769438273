/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { Skeleton, Tooltip } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import './styles.less';

const ColorPicker = ({
  id,
  color,
  onChange,
  loading,
  info,
}) => {
  const [visible, setVisible] = useState(false);

  if (loading) {
    return (
      <Skeleton.Button
        className="gyramais-color-picker-loading"
        active
        size="large"
      />
    );
  }

  return (
    <>
      <div className="gyramais-color-picker-container">
        <div
          id={id}
          onClick={() => setVisible(!visible)}
          role="button"
          tabIndex="-1"
          onKeyPress={() => false}
          className="gyramais-color-picker"
        >
          <div className="color-picker-preview">
            <div
              className="color-square"
              style={{
                backgroundColor: color,
              }}
            />
            {color.toUpperCase()}
          </div>

          <div className="color-picker-label">
            {visible ? 'Fechar' : 'Alterar'}
          </div>
        </div>

        {info && (
        <Tooltip
          className="tooltip"
          title={(
            <span>
              {info}
            </span>
                    )}
        >
          <ExclamationCircleFilled className="app-color-info" />
        </Tooltip>
        )}
      </div>

      {visible && (
        <div className="picker-container">
          <div
            className="cover-close"
            onClick={() => setVisible(false)}
          />

          <ChromePicker
            color={color}
            disableAlpha
            presetColors={[]}
            onChange={({ hex }) => onChange(hex)}
          />
        </div>
      )}
    </>
  );
};

ColorPicker.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string,
  info: PropTypes.string,
  loading: PropTypes.bool,
};

export default ColorPicker;
