import React, { useState } from 'react';
import {
  message,
  Card,
  Row,
  Col,
  Tooltip,
} from 'antd';

import {
  EditOutlined,
  PlusOutlined,
  CrownOutlined,
  CrownFilled,
  EyeOutlined,
} from '@ant-design/icons';
import { formatError } from 'utils';
import {
  Button,
  Information,
} from 'components/gyramais';

import { useMutation, useQuery } from '@apollo/client';
import AddBankAccountModal from './components/AddBankAccountModal';

import BUSINESS from '../../graphql/business.gql';
import SELECT_BANK_ACCOUNT from '../../graphql/selectBankAccount.gql';

import './styles.less';

const BankAccount = ({
  businessId,
  loading: parentLoading,
}) => {
  const [creationModalVisible, setCreationModalVisible] = useState(false);
  const [bankAccountToEdit, setBankAccountToEdit] = useState(undefined);

  const {
    data: {
      business,
    } = {},
    refetch,
    loading: businessLoading,
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    fetchPolicy: 'cache-only',
  });

  const [selectBankAccount, { loading: selectLoading }] = useMutation(SELECT_BANK_ACCOUNT, {
    onCompleted: () => refetch(),
    onError: (error) => message.error(formatError(error)),
  });

  const loading = parentLoading || businessLoading || selectLoading;
  return (
    <div id="bank-account-card">
      <Tooltip title="Adicionar nova conta bancária" placement="topLeft">
        <Button
          disabled={loading}
          className="fab"
          onClick={() => {
            setCreationModalVisible(true);
          }}
        >
          <PlusOutlined style={{ color: 'white', fontSize: 24 }} />
        </Button>
      </Tooltip>

      <div className="bank-accounts-container">
        {!business?.bankAccounts[0] && (
          <div className="empty-bank-accounts">
            Nenhuma conta bancária encontrada, aperte no
            <div className="fabinho">+</div>
            para
            <Button
              link
              disabled={loading}
              onClick={() => {
                setCreationModalVisible(true);
              }}
            >
              Adicionar uma nova conta
            </Button>
          </div>
        )}
        {business?.bankAccounts.map((bankAccount) => (
          <Card
            key={bankAccount.id}
            className={`bank-account-card ${bankAccount.id === business.bankAccount.id ? 'selected' : ''}`}
          >
            <div className="bank-account-card-header">
              <Tooltip title="Editar conta bancária">
                <Button
                  disabled={loading}
                  link
                  onClick={() => {
                    setCreationModalVisible(true);
                    setBankAccountToEdit(bankAccount);
                  }}
                >
                  <EditOutlined style={{ fontSize: 24 }} />
                </Button>
              </Tooltip>

              <Tooltip
                title={
                  bankAccount.id === business.bankAccount.id
                    ? 'Esta conta bancária é a conta principal da empresa'
                    : 'Marcar como conta principal'
                }
              >
                <Button
                  disabled={loading}
                  link
                  onClick={() => {
                    if (bankAccount.id !== business.bankAccount.id) {
                      selectBankAccount({
                        variables: {
                          businessId: business.id,
                          bankAccountId: bankAccount.id,
                        },
                      });
                    }
                  }}
                >
                  {bankAccount.id === business.bankAccount.id
                    ? <CrownFilled style={{ fontSize: 24 }} />
                    : <CrownOutlined style={{ fontSize: 24 }} />}
                </Button>
              </Tooltip>

            </div>
            <Row>
              <Col sm={24} md={8}>
                <Information
                  detailed
                  label="Banco"
                  value={bankAccount.bank.name}
                />
              </Col>
              <Col sm={24} md={8}>
                <Information
                  detailed
                  label="Agência"
                  value={bankAccount.branchCode}
                />
              </Col>
              <Col sm={24} md={8}>

                <Information
                  detailed
                  label="Conta"
                  value={bankAccount.accountNumber}
                />

              </Col>
            </Row>

            <hr />

            {bankAccount?.statements.map((file) => (
              <Row
                key={file.id}
                className={`information-container ${file.id}-arquivo`}
                align="middle"
                justify="space-between"
                gutter={14}
              >
                <Col xs={18} sm={20} md={18}>
                  <Information
                    label="Arquivo"
                    value={file.name}
                  />
                </Col>

                <Col xs={4} sm={4} md={4}>
                  <EyeOutlined
                    className="upload-card-icon secondary-text"
                    onClick={() => window.open(file.url, '_blank')}
                    style={{ fontSize: '1.6rem' }}
                  />
                </Col>
              </Row>
            ))}
          </Card>
        ))}
      </div>

      <AddBankAccountModal
        visible={creationModalVisible}
        onClose={() => {
          setCreationModalVisible(false);
          setBankAccountToEdit(undefined);
        }}
        bankAccount={bankAccountToEdit}
        business={business}
        refetch={refetch}
      />
    </div>
  );
};

export default BankAccount;
