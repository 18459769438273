import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Carousel,
} from 'antd';
import { OptionCard, ProcessMessage } from 'components';
import { DownloadOutlined } from '@ant-design/icons';
import {
  Drawer,
} from 'components/gyramais';
import {
  downloadImportModel, downloadImportCsvModel,
} from 'utils';
import useDrawerHelpers from 'utils/hooks/useDrawerHelpers';
import useImportFile from 'utils/hooks/useImportFile';

const { Item } = Form;
const { useForm } = Form;

const OPTIONS_STEP = 0;
const PROCESSING_STEP = 1;

const ProductDrawer = ({
  visible,
  onClose,
  onStartImport,
}) => {
  const [form] = useForm();
  const carouselRef = useRef(null);
  const [current, setCurrent] = useState(OPTIONS_STEP);

  const { goToStepSafely } = useDrawerHelpers({ carouselRef });

  const closeDrawer = () => {
    onClose();
    setCurrent(OPTIONS_STEP, 600);
  };

  const {
    loadingUploadFile,
    processFileUpload,
  } = useImportFile({
    fileType: 'import-product',
    onLoading: () => {
      setCurrent(PROCESSING_STEP);
    },
    onImportRequestError: () => {
      setCurrent(OPTIONS_STEP, 600);
    },
    onValidate: () => {
      closeDrawer();
      onStartImport();
    },
  });

  useEffect(() => {
    goToStepSafely(current, 600);
  }, [current]);

  return (
    <Drawer
      visible={visible}
      width="90%"
      className="product-drawer"
      onClose={closeDrawer}
      closable={current === OPTIONS_STEP}
      title={current === OPTIONS_STEP && 'Adicionar produtos'}
      hideFooter
    >
      <div className="drawer-body">
        <Carousel
          ref={(ref) => {
            carouselRef.current = ref;
          }}
          dots={false}
          swipeToSlide={false}
          swipe={false}
          draggable={false}
          accessibility={false}
        >
          {/* ========= OPTIONS STEP ========== */}
          <div className="carousel-content">
            <Form
              className="form-footer"
              form={form}
              layout="vertical"
            >
              <Row>
                <Col span={24}>
                  <Item name="uploadcardjson" rules={[{ required: true, message: 'Por favor, insira o arquivo de produtos' }]}>
                    <OptionCard
                      accept="application/json"
                      upload
                      multiple
                      label="Incluir por arquivo .JSON"
                      action={processFileUpload}
                      loading={loadingUploadFile}
                      caption="Adicione diversos produtos de uma só vez, através do nosso arquivo padrão .JSON com limite de 250MB"
                      identifier="import-products"
                      donwloadMessage="Baixar arquivo"
                      secondaryBtn={{
                        icon: <DownloadOutlined />,
                        text: 'Baixar arquivo',
                        onClick: () => downloadImportModel('produtos'),
                      }}
                    />
                  </Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Item name="uploadcardcsv" rules={[{ required: true, message: 'Por favor, insira o arquivo de produtos' }]}>
                    <OptionCard
                      accept=".csv"
                      upload
                      label="Incluir por arquivo .CSV"
                      action={processFileUpload}
                      loading={loadingUploadFile}
                      caption="Adicione diversos produtos de uma só vez, através do nosso arquivo padrão .CSV com limite de 250MB"
                      identifier="import-products"
                      donwloadMessage="Baixar arquivo"
                      secondaryBtn={{
                        icon: <DownloadOutlined />,
                        text: 'Baixar arquivo',
                        onClick: () => downloadImportCsvModel('produtos'),
                      }}
                    />
                  </Item>
                </Col>
              </Row>
            </Form>
          </div>

          {/* ========= PROCESSING ========== */}
          <div className="carousel-content">
            <ProcessMessage centered />
          </div>
        </Carousel>
      </div>
    </Drawer>
  );
};

ProductDrawer.propTypes = {
  onClose: PropTypes.func,
  onStartImport: PropTypes.func,
  visible: PropTypes.bool,
};

export default ProductDrawer;
