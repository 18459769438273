import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
} from 'antd';
import {
  OnwerInfoForm,
  ConfigLoanCard,
} from 'components';
import './styles.less';

const UserAndLoanStep = ({
  form,
  withLoanCreation,
  setInstallmentsCount,
  setAmount,
}) => (
  <Row align="middle" justify={withLoanCreation ? 'space-around' : 'center'} gutter={[16, 24]} id="user-and-loan-step">
    <Col xs={24} sm={24} md={24} lg={10} xl={8}>
      <Card>
        <div className="card-header">
          <h3>Dados do sócio principal</h3>
        </div>
        <OnwerInfoForm form={form} />
      </Card>
    </Col>
    {withLoanCreation && (
    <Col xs={24} sm={24} md={24} lg={12}>
      <ConfigLoanCard
        onChangeAmount={(e) => setAmount(e)}
        onChangeInstallmentsCount={(e) => setInstallmentsCount(e)}
      />
    </Col>
    )}
  </Row>
);

UserAndLoanStep.propTypes = {
  form: PropTypes.shape(),
  withLoanCreation: PropTypes.bool,
  setInstallmentsCount: PropTypes.func,
  setAmount: PropTypes.func,
};

export default UserAndLoanStep;
