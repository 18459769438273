/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect, memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Menu,
  Drawer,
  Avatar,
  Skeleton,
  Popover,
} from 'antd';
import {
  ImportFeedbackCard,
} from 'components';
import {
  MenuOutlined,
  HomeOutlined,
  DollarCircleOutlined,
  LogoutOutlined,
  CodeOutlined,
  TeamOutlined,
  BarcodeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Images } from 'assets';
import {
  useReactiveVar,
  useQuery,
  useMutation,
  useSubscription,
} from '@apollo/client';
import { useActionContext } from 'contexts/ActionContext';
import { useIdleTimer } from 'react-idle-timer';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import client from '../../../services/graphql';
import {
  ME,
  RUNNING_FILE_SYNCS,
  FILE_SYNC_UPDATED,
  LOG_OUT,
  REMOVE_SESSION,
} from './graphql';
import { meVar } from '../../../services/graphql/cache';
import { BUSINESSES } from '../../../screens/HomeScreen/graphql';

import './styles.less';

const {
  Close, Logo,
} = Images;
const { Item } = Menu;

/**
 * @type {{
 *   id: string,
 *   name: string,
 *   key: string,
 *   action: UserActions,
 *   icon: React.ReactNode,
 *  }[]}
 */
const menus = [
  {
    name: 'Página Inicial',
    key: '/',
    icon: <HomeOutlined />,
  },
  {
    name: 'Clientes',
    id: 'clientes-menu-button',
    key: '/clientes',
    secondaryKey: '/cliente',
    icon: <TeamOutlined />,
  },
  {
    name: 'Empréstimos',
    id: 'emprestimos-menu-button',
    key: '/emprestimos',
    action: 'client-loans-access',
    icon: <DollarCircleOutlined />,
  },
  {
    name: 'Parcelas',
    id: 'parcelas-menu-button',
    key: '/parcelas',
    action: 'client-installments-access',
    icon: <BarcodeOutlined />,
  },
  {
    name: 'API',
    id: 'api-menu-button',
    key: '/API',
    action: 'apis',
    icon: <CodeOutlined />,
  },
];

/**
 * @type {{
  *   className: string,
  *   name: string,
  *   key: string,
  *   action: UserActions,
  *   icon: React.ReactNode,
  *  }[]}
  */
const profileMenus = [
  {
    name: 'Configurações',
    className: 'highlight-option',
    key: '/configuracoes',
    action: 'config',
    icon: <SettingOutlined />,
  },
];

const Header = () => {
  const { checkForBusinessAction } = useActionContext();
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const [visible, setVisible] = useState(false);
  const [withOptions, setWithOptions] = useState(false);
  const [activeKey, setActiveKey] = useState(location.pathname);
  const session = useReactiveVar(meVar);
  const [removeSession] = useMutation(REMOVE_SESSION, {
    onCompleted: () => {
      history.push('/login');
      window.location.reload();
    },
  });
  const [logOut] = useMutation(LOG_OUT, {
    onCompleted: () => removeSession(),
  });
  const {
    data: {
      me = {},
    } = {},
    loading,
  } = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me: { removedAt } = {} } = {}) => {
      if (removedAt) logOut();
    },
    skip: Boolean(!session),
  });

  const {
    data: {
      runningFileSyncs = [],
    } = {},
  } = useQuery(RUNNING_FILE_SYNCS, {
    skip: Boolean(!session),
  });

  useSubscription(FILE_SYNC_UPDATED, {
    variables: { ids: runningFileSyncs.map(({ id }) => id) },
    skip: runningFileSyncs.length === 0,
    onSubscriptionData: ({ subscriptionData: { data: { fileSyncUpdated } = {} } = {} }) => {
      const { type: { value } = {} } = fileSyncUpdated;
      if (value === 'import-business') {
        client.refetchQueries({
          include: [BUSINESSES],
        });
      }
    },
  });

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: () => {
      if (meVar()) logOut();
    },
    debounce: 500,
  });

  useEffect(() => {
    setWithOptions(!location.pathname.includes('/cadastro'));

    if (location?.pathname?.includes('/cliente')) {
      return setActiveKey('/clientes');
    }

    return setActiveKey(location.pathname);
  }, [location.pathname]);

  return (!['/login', '/autenticacao', '/cadastro/senha', '/esqueci-minha-senha'].includes(location.pathname)) && (
    <>
      <div className="gyramais-header">
        <Row align="middle" className="gyramais-header-content">
          <Col xs={20} md={5} lg={6} className="logo-col">
            <Logo className="logo" onClick={() => withOptions && history.push('/')} />
          </Col>

          <Col md={14} lg={12}>
            {withOptions && (
              <Menu
                mode="horizontal"
                onClick={({ key }) => history.push(key)}
                selectedKeys={[activeKey]}
              >
                {menus.map((menu) => (checkForBusinessAction(menu.action) || !menu.action) && (
                  <Item key={menu.key} id={menu.id}>{menu.name}</Item>
                ))}
              </Menu>
            )}
          </Col>

          <Col xs={4} md={5} lg={6} className="right-side">
            {width < 769 ? (
              <>
                {loading ? (
                  <Skeleton.Button className="skeleton-menu-collapse" active />
                ) : (
                  <MenuOutlined className="menu-collapse" onClick={() => setVisible(true)} />
                )}
              </>
            ) : (
              <div className="avatar-container">
                {loading ? (
                  <>
                    <Skeleton.Button
                      active
                      className="skeleton-salutation"
                      shape="square"
                    />
                    <Skeleton.Avatar
                      className="skeleton-avatar"
                      size="large"
                      active
                    />
                  </>
                ) : (
                  <Popover
                    placement="bottomRight"
                    content={(
                      <div className="menu-dropdown">
                        {withOptions && (
                          <Menu onClick={({ key }) => history.push(key)}>
                            {profileMenus.map((menu) => (
                              <Item
                                icon={menu.icon}
                                className={menu.className}
                                key={menu.key}
                              >
                                {menu.name}
                              </Item>
                            ))}
                          </Menu>
                        )}
                        <hr />
                        <Menu>

                          <Item
                            onClick={logOut}
                            icon={<LogoutOutlined />}
                            key="sair"
                          >
                            Sair
                          </Item>
                        </Menu>
                      </div>
                    )}
                  >
                    <div id="header-menu-button">
                      <span className="salutation">
                        Olá,
                        {' '}
                        {me?.firstName}
                      </span>
                      <Avatar className="avatar" size="large">{me?.firstName?.[0]}</Avatar>
                    </div>
                  </Popover>
                )}
              </div>
            )}
          </Col>
        </Row>
      </div>

      <Drawer
        className="mobile-menu"
        bodyStyle={{ margin: 0, padding: 0 }}
        visible={visible}
        width="90%"
        onClose={() => setVisible(false)}
        closeIcon={<Close className="close-button" />}
      >
        <div className="top">
          <div className="avatar-container">
            <Avatar className="avatar" size="large">
              {me?.firstName?.[0]}
            </Avatar>
            <br />
            <div className="infos">
              <div>
                <span className="salutation">{me?.fullName}</span>
                <br />
                <span className="salutation-email">{me?.email}</span>
                <br />
                <span className="salutation-business">
                  {me?.currentBusiness?.name || me?.currentBusiness?.legalName || 'EMPRESA SEM NOME'}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="body">
          {withOptions && (
            <>
              <Menu
                onClick={({ key }) => {
                  history.push(key);
                  setVisible(false);
                }}
              >
                {profileMenus.map((menu) => (
                  <Item
                    icon={menu.icon}
                    className={menu.className}
                    key={menu.key}
                  >
                    {menu.name}
                  </Item>
                ))}
                <hr />
              </Menu>
              <Menu
                onClick={({ key }) => {
                  history.push(key);
                  setVisible(false);
                }}
              >
                {menus.map((menu) => checkForBusinessAction(menu.action) && (
                  <Item icon={menu.icon} key={menu.key} id={menu.id}>
                    {menu.name}
                  </Item>
                ))}
                <hr />
              </Menu>
            </>
          )}
          <Menu>
            <Item onClick={logOut} icon={<LogoutOutlined />}>Sair</Item>
          </Menu>
        </div>
      </Drawer>

      {runningFileSyncs.length > 0 && (
        <ImportFeedbackCard
          runningFileSyncs={runningFileSyncs}
          onClickDispatch={(id) => {
            const updatedData = [];

            for (const fileSync of runningFileSyncs) {
              if (fileSync.id !== id) updatedData.push(fileSync);
            }

            client.writeQuery({
              query: RUNNING_FILE_SYNCS,
              data: { runningFileSyncs: updatedData },
            });
          }}
        />
      )}
    </>
  );
};

export default memo(Header);
