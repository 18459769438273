import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Upload,
  notification,
  message,
  Divider,
} from 'antd';
import {
  UploadOutlined,
} from '@ant-design/icons';
import {
  Drawer,
} from 'components/gyramais';
import {
  formatError,
} from 'utils';
import { useMutation } from '@apollo/client';
import useConfirmBrowserExit from 'utils/hooks/useConfirmBrowserExit ';
import { useConfirmModalContext } from 'contexts/ConfirmModalContext/confirm-modal-context';
import {
  UPLOAD_FILE,
  SET_LOGO,
} from './gql';

import './styles.less';

const LogoEditorDrawer = ({
  lightLogo,
  darkLogo,
  businessId,
  refetch,
  visible,
  onClose,
}) => {
  const { requestConfirmation } = useConfirmModalContext();

  const { enableConfirmation, disableConfirmation } = useConfirmBrowserExit({
    defaultEnabled: false,
    message: 'Você tem modificações que ainda nao foram salvas! Deseja sair mesmo assim?',
  });

  const [localDarkImage, setLocalDarkImage] = useState(null);
  const [localLightImage, setLocalLightImage] = useState(null);
  const [hasEdited, setEdited] = useState(false);

  let filePreview;

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const closeDrawer = () => {
    onClose();
    disableConfirmation();
    setLocalDarkImage(null);
    setLocalLightImage(null);
  };

  const [setLogo, { loading: setLogoLoading }] = useMutation(SET_LOGO, {
    onCompleted: () => {
      notification.success({ message: 'Logo alterada com sucesso.' });
      refetch();
      setEdited(false);
      disableConfirmation();
      closeDrawer();
    },
    onError: (error) => message.error(formatError(error)),
  });

  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);

  const handleChangeImage = async (file, typeLogo) => {
    if (!file.url) {
      filePreview = await getBase64(file);
    }
    if (typeLogo === 'dark') {
      setLocalDarkImage({ file, name: file.name, preview: filePreview });
    }
    if (typeLogo === 'light') {
      setLocalLightImage({ file, name: file.name, preview: filePreview });
    }

    setEdited(true);
  };

  const submitBusiness = async () => {
    let darkLogoId;
    let lightLogoId;

    if (localDarkImage) {
      const response = await uploadFile({
        variables: { file: localDarkImage.file },
      });
      darkLogoId = response?.data?.uploadFile?.id;
    }

    if (localLightImage) {
      const response = await uploadFile({
        variables: { file: localLightImage.file },
      });
      lightLogoId = response?.data?.uploadFile?.id;
    }

    if (darkLogoId || lightLogoId) {
      setLogo({ variables: { partnerId: businessId, darkLogoId, lightLogoId } });
    }
  };

  useEffect(() => {
    if (hasEdited) {
      enableConfirmation();
    }
  }, [hasEdited]);

  return (
    <Drawer
      visible={visible}
      width="50%"
      className="logo-editor-drawer"
      title="Editar logo clara e logo escura"
      onClose={() => {
        if (!hasEdited) {
          closeDrawer();
          return;
        }

        requestConfirmation({
          message: 'Você tem modificações que ainda nao foram salvas! Deseja fechar mesmo assim?',
          onOk: closeDrawer,
          onCancel: () => {},
        });
      }}
      primaryButton={{
        children: 'Salvar logos',
        id: 'confirm-change-logo',
        loading: setLogoLoading,
        onClick: submitBusiness,
      }}
    >
      <Row className="upload-logo-group">
        <Col xs={24} sm={24} md={24}>
          <p>
            <b>
              {!darkLogo ? 'Inserir logo escura' : 'Alterar logo escura' }
            </b>
          </p>

          <div className="edit-area">
            <div className="edit-content">
              <span className="file-name">
                {localDarkImage?.name || darkLogo?.name}
              </span>
              <Upload
                id="upload-dark-logo"
                accept="image/png,image/jpeg"
                showUploadList={false}
                disable={setLogoLoading || uploadFileLoading}
                beforeUpload={() => false}
                onChange={({ file }) => {
                  if (file) { handleChangeImage(file, 'dark'); }
                }}
              >
                <UploadOutlined className="upload-icon" />
              </Upload>
            </div>

          </div>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <p>
            <b>
              {!lightLogo ? 'Inserir logo clara' : 'Alterar logo clara' }
            </b>
          </p>

          <div className="edit-area">
            <div className="edit-content">
              <span className="file-name">
                {localLightImage?.name || lightLogo?.name}
              </span>
              <Upload
                id="upload-light-logo"
                accept="image/png,image/jpeg"
                showUploadList={false}
                disable={setLogoLoading || uploadFileLoading}
                beforeUpload={() => false}
                onChange={({ file }) => {
                  if (file) { handleChangeImage(file, 'light'); }
                }}
              >
                <UploadOutlined className="upload-icon" />
              </Upload>
            </div>

          </div>
        </Col>
      </Row>

      <Divider />

      <h3>Pré-visualização</h3>
      <br />

      <Row className="logos-preview" align="middle" justify="space-between">
        <Col xs={11} sm={11} md={10}>
          <img src={localDarkImage?.preview || darkLogo?.url} alt="logo" />
        </Col>
        <Col xs={11} sm={11} md={10}>
          <img className="light-logo" src={localLightImage?.preview || lightLogo?.url} alt="logo com fundo escuro" />
        </Col>
      </Row>
    </Drawer>
  );
};

LogoEditorDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  darkLogo: PropTypes.shape(),
  lightLogo: PropTypes.shape(),
  refetch: PropTypes.func,
  businessId: PropTypes.string,
};

export default LogoEditorDrawer;
