import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Information,
  Button,
} from 'components/gyramais';
import {
  Row,
  message,
  Col,
} from 'antd';
import {
  CheckCircleFilled,
  CopyOutlined,
} from '@ant-design/icons';
import {
  useMutation,
} from '@apollo/client';
import { formatError, formatDate } from 'utils';
import {
  SAVE_INVOICE,
} from './graphql';

import './styles.less';

const InstallmentRow = ({
  installment: {
    id,
    values: { status },
    number,
    totalAmount,
    dueDate,
    invoices,
  },
  last,
  next,
  detailed,
  late,
  installmentsCount,
  withOptions,
}) => {
  const [invoice, setInvoice] = useState(
    invoices.find((i) => !i.canceledAt
    && new Date(new Date(i.dueDate).setHours(0, 0, 0, 0))
      >= new Date(new Date().setHours(0, 0, 0, 0))),
  );

  const [saveInvoice, { loading: confirmLoading }] = useMutation(SAVE_INVOICE, {
    onCompleted: async ({ saveInvoice: { id, barcode, url } = {} }) => {
      navigator.clipboard.writeText(barcode);
      message.success('Código de Barras copiado com sucesso', 3);
      setInvoice({ id, barcode, url });
    },
    onError: (error) => message.error(formatError(error)),
  });

  return (
    <div className={`installment-container ${next ? 'next' : ''}`}>
      <Row className="installment-row">
        {detailed ? (
          <>
            <Col xs={12} sm={12} md={0} lg={0}>
              <Information
                detailed
                label={`Parcela nº ${number}`}
                value={`${totalAmount}`}
                type="currency"
              />
            </Col>
            <Col xs={0} sm={0} md={12} lg={6}>
              <Information
                detailed
                valuePreffix={detailed && (
                  <>
                    {status.value === 'paid' ? (
                      <CheckCircleFilled className="paid-value-preffix" />
                    ) : (
                      <div className={`information-value-preffix ${late ? 'late' : ''}`}>
                        {number}
                      </div>
                    )}
                  </>
                )}
                value={`${totalAmount}`}
                type="currency"
              />
            </Col>
            <Col xs={0} sm={0} md={12} lg={6}>
              <Information
                detailed
                danger={late}
                value={`${status.value === 'paid' ? 'Pago' : `${late ? 'Venceu' : 'Vence'} em`} ${status.value !== 'paid' ? formatDate(new Date(dueDate)) : ''}`}
              />
            </Col>
            <Col xs={12} sm={12} md={0} lg={0}>
              <Information
                detailed
                danger={late}
                label={`${status.value === 'paid' ? 'Situação' : `${late ? 'Venceu' : 'Vence'} em`}`}
                value={`${status.value !== 'paid' ? formatDate(new Date(dueDate)) : 'Pago'}`}
              />
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} sm={12} md={12} lg={6}>
              <Information
                danger={late}
                label={`${late ? 'Parcela atrasada' : 'Próxima parcela'} (${number}/${installmentsCount})`}
                value={`${dueDate ? formatDate(new Date(dueDate)) : '-'}`}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <Information
                label="-"
                value={`${totalAmount || '-'}`}
                type="currency"
              />
            </Col>
          </>
        )}
        {withOptions && status.value !== 'paid' && (
          <Col xs={24} sm={24} md={24} lg={12} className="actions-area">
            <Button
              link
              id={`installment-${number}-copy-code-button`}
              loading={confirmLoading}
              icon={(<CopyOutlined />)}
              onClick={async () => {
                if (!invoice) {
                  await saveInvoice({ variables: { installmentId: id } });
                } else {
                  navigator.clipboard.writeText(invoice.barcode);
                  message.success('Código de Barras copiado com sucesso', 3);
                }
              }}
            >
              Copiar código do boleto
            </Button>
          </Col>
        )}
      </Row>
      {!last && <hr className="installments-divider" />}
    </div>
  );
};

InstallmentRow.propTypes = {
  installment: PropTypes.shape({
    id: PropTypes.string,
    invoices: PropTypes.arrayOf(PropTypes.shape()),
    next: PropTypes.bool,
    values: PropTypes.shape({
      status: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
    number: PropTypes.number,
    totalAmount: PropTypes.number,
    dueDate: PropTypes.string,
  }),
  last: PropTypes.bool,
  next: PropTypes.bool,
  detailed: PropTypes.bool,
  withOptions: PropTypes.bool,
  late: PropTypes.bool,
  installmentsCount: PropTypes.number,
  // refetch: PropTypes.func,
};

export default InstallmentRow;
