import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Form, Select, message,
} from 'antd';

import { BANKS } from 'services/graphql/queries';
import './styles.less';
import { useQuery, useMutation } from '@apollo/client';
import { formatError } from 'utils';
import { Input, Modal } from 'components/gyramais';
import UploadCard from 'components/cards/UploadCard';
import { SAVE_BANK_ACCOUNT } from './graphql';

const { Item, useForm } = Form;
const { Option } = Select;

const AddBankAccountModal = ({
  bankAccount,
  visible,
  onClose,
  business,
  loanType,
  refetch,
}) => {
  const [form] = useForm();
  const [files, setFiles] = useState([]);

  const { data: { banks = [] } = {} } = useQuery(BANKS, {
    variables: { limit: 500 },
    partialRefetch: true,
    onError: (error) => message.error(formatError(error)),
  });

  const [saveBankAccount, { loading: saveBankAccountLoading }] = useMutation(SAVE_BANK_ACCOUNT, {
    onCompleted: () => {
      message.success('Conta bancária salva com sucesso!');
      refetch();
      setFiles([]);
      form.resetFields();
      onClose();
    },
    onError: (error) => message.error(formatError(error)),
  });

  const handleAddBankAccount = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    values.accountNumber = `${values.accountNumber}-${values.digit}`;

    const variables = {
      businessId: business.id,
      ...values,
      statementFileIds: files.map((file) => file.id),
      pix: {
        key: values.key,
        type: values.type,
      },
    };

    if (bankAccount) {
      variables.id = bankAccount.id;
    }
    await saveBankAccount({ variables });
  };

  useEffect(() => {
    if (visible && bankAccount) {
      form.setFieldsValue({
        bankId: bankAccount.bank.id,
        branchCode: bankAccount.branchCode,
        accountNumber: bankAccount.accountNumber.split('-')[0],
        digit: bankAccount.accountNumber.split('-')[1],
        type: bankAccount.pix.type,
        key: bankAccount.pix.key,
        statementFileIds: bankAccount.statements?.map((statement) => statement.id),
      });
      setFiles(bankAccount.statements);
    }
  }, [visible, bankAccount]);

  return (
    <Modal
      className="add-bank-account-modal new-gyramais-styles"
      visible={visible}
      onCancel={() => {
        onClose();
        setFiles([]);
        form.resetFields();
      }}
      centered
      title="Adicionar conta bancária"
      okText="Fechar"
      width={700}
      cancelButtonProps={{ hidden: true }}
      onOk={handleAddBankAccount}
      okButton={{
        text: bankAccount ? 'Salvar' : 'Adicionar conta',
        onClick: handleAddBankAccount,
        loading: saveBankAccountLoading,
        id: 'add-bank-account-modal-button',
      }}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Row align="middle" id="add-bank-account" gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Item label="Banco" name="bankId" rules={[{ required: true, message: 'Por favor selecione o banco' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children
                  .toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Selecione o banco"
                style={{ width: '100%', fontWeight: '600' }}
              >
                {banks.map(({ id, code, name }) => (
                  <Option key={id} value={id}>
                    {`${code} - ${name}`}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Item
              label="Agência"
              name="branchCode"
              rules={[{
                required: true,
                whitespace: true,
                message: 'Por favor preencha a agência',
                max: 4,
                pattern: '^[0-9]*$',
              }]}
            >
              <Input
                type="masked"
                guide
                mask={[/\d/, /\d/, /\d/, /\d/]}
                placeholder="Agência (sem dígito)"
              />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Item
              label="Conta"
              name="accountNumber"
              onBlur={(args) => {
                const digits = args.target.value.replace(/\D/g, '');
                if (digits) {
                  form.setFieldsValue({
                    accountNumber: `${'0'.repeat(11 - (digits.length))}${digits.substring(0, digits.length)}`,
                  });
                }
              }}
              rules={[{
                required: true,
                whitespace: true,
                message: 'Insira a conta',
                max: 11,
                pattern: '^[0-9]*$',
              }]}
            >
              <Input
                type="masked"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                placeholder="Conta"
              />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Item
              label="Dígito"
              name="digit"
              rules={[{
                required: true,
                whitespace: true,
                message: 'Insira o dígito',
                max: 1,
                pattern: '^[0-9]*$',
              }]}
            >
              <Input
                placeholder="Dígito"
                type="masked"
                mask={[/\d/]}
                guide
                onChange={({ target: { value } }) => {
                  value.replace(/[^0-9]/g, '');
                }}
              />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Item label="Tipo do pix" name="type" rules={loanType === 'receivables-guarantee' && ([{ required: true, message: 'Por favor insira o tipo de pix' }])}>
              <Select
                showSearch
                placeholder="Selecione o tipo de PIX"
                style={{ width: '100%', fontWeight: '600' }}
              >
                <Option key="email" value="email">Email</Option>
                <Option key="cpf" value="cpf">CPF</Option>
                <Option key="cnpj" value="cnpj">CNPJ</Option>
                <Option key="celular" value="celular">Celular</Option>
                <Option key="aleatório" value="aleatório">Aleatório</Option>
              </Select>
            </Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Item label="Chave pix" name="key" rules={loanType === 'receivables-guarantee' && [{ required: true, message: 'Por favor insira a chave pix' }]}>
              <Input
                placeholder="Digite a chave PIX"
                guide
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="statementFileIds" rules={[{ required: true, message: 'Envie o extrato' }]}>
              <UploadCard
                id="statement-upload"
                accept="application/pdf"
                notSetFile
                label="Arquivos do banco"
                multiples
                actualFile={files}
                onCompleted={({ id, name, url }) => {
                  form.setFieldsValue({ statementFileIds: [id] });
                  setFiles([...files, { id, name, url }]);
                }}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

AddBankAccountModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  refetch: PropTypes.func,
};

export default AddBankAccountModal;
