import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import {
//   Row, Col,
// } from 'antd';
import { HorizontalSplit, PasswordForm } from 'components';
import { useMutation } from '@apollo/client';
import { REMOVE_SESSION, SET_LOGGED_OUT } from './graphql';
import { meVar, loggedOutVar } from '../../services/graphql/cache';

const PasswordScreen = () => {
  const [session] = useState();
  const me = meVar();
  const { loggedOut } = loggedOutVar();
  const [setLoggedOut, { loading }] = useMutation(SET_LOGGED_OUT);
  const [removeSession] = useMutation(REMOVE_SESSION, {
    onCompleted: () => setLoggedOut(),
  });
  useEffect(() => {
    if (me && !loggedOut && loading === false) {
      removeSession();
    } else setLoggedOut();
  }, [session]);
  return (
    <HorizontalSplit>
      <PasswordForm />
    </HorizontalSplit>
  );
};

PasswordScreen.propTypes = {
  history: PropTypes.shape({}),
};

export default PasswordScreen;
