/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  toKebabCase,
} from 'utils';
import {
  Button,
} from 'components/gyramais';

import './styles.less';

const ActionButton = ({
  name,
  onClick,
  id,
  numberOfButtons,
}) => (
  <Col
    className={`action-button col-button-${toKebabCase(name)}`}
    key={name}
    xs={12}
    md={Math.floor(24 / numberOfButtons)}
  >
    <Button onClick={onClick} id={id}>
      <PlusCircleOutlined className="button-plus" />
      <h5 className="no-margin-bottom">
        <b className="white-text">
          {name}
        </b>
      </h5>
    </Button>
  </Col>
);

ActionButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  numberOfButtons: PropTypes.number,
};

export default ActionButton;
