import { gql } from '@apollo/client';

const CREATE_API_USER = gql`
  mutation createApiUser {
    createApiUser {
      id
      apiKey
      apiSecret
    }
  }
`;
const REMOVE_API_USER = gql`
mutation removeApiUser(
  $id:ID!
) {
    removeApiUser(id:$id)
}
`;

export {
  CREATE_API_USER,
  REMOVE_API_USER,
};
export default CREATE_API_USER;
