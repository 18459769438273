import { gql } from '@apollo/client';

const SAVE_BANK_ACCOUNT = gql`
  mutation saveBankAccount(
    $id: ID
    $businessId: ID!
    $bankId: ID!
    $branchCode: String!
    $accountNumber: String!
    $currentAccount: Boolean!
  ) {
    saveBankAccount(
      id: $id
      businessId: $businessId
      bankId: $bankId
      branchCode: $branchCode
      accountNumber: $accountNumber
      currentAccount: $currentAccount
    ) {
      id
    }
  }
`;

export {
  SAVE_BANK_ACCOUNT,
};

export default SAVE_BANK_ACCOUNT;
