import React from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
} from 'antd';
import { Menu } from 'components';
import { useLocation } from 'react-router-dom';

const {
  Sider,
} = Layout;

const DefaultSider = ({
  collapsed,
  width,
}) => {
  const location = useLocation();
  return (![
    '/login',
    '/esqueci-minha-senha',
    '/cadastro/empresa-socios',
    '/cadastro/senha',
  ].includes(location.pathname) && (
  <Sider
    collapsible
    collapsed={collapsed}
    trigger={null}
    width={120}
    hidden={(width < 480)}
  >
    <Menu />
  </Sider>
  ));
};

DefaultSider.propTypes = {
  collapsed: PropTypes.bool,
  width: PropTypes.number,
};

export default DefaultSider;
