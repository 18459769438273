import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Select,
} from 'antd';
import {
  Input,
} from 'components/gyramais';

import {
  useQuery,
} from '@apollo/client';

import BUSINESS from '../../graphql/business.gql';
import BUSINESS_PLATFORM_TYPES from '../../graphql/businessPlatformTypes.gql';
import BUSINESS_SEGMENTS from '../../graphql/businessSegments.gql';
import BUSINESS_SECTORS from '../../graphql/businessSectors.gql';

const { Item } = Form;
const { Option } = Select;

const BusinessInfo = ({
  businessId,
  form,
}) => {

  const {
    data: {
      business,
    } = {},
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    onCompleted: ({ business }) => {
      form.setFieldsValue({
        platformType: business.platformType.value,
        sector: business.sector.value,
        segment: business.segment.value,
        employeesNumber: business.employeesNumber,
        revenueAmount: business.revenueAmount,
      });
    },
  });

  const {
    data: {
      businessPlatformTypes,
    } = {},
  } = useQuery(BUSINESS_PLATFORM_TYPES, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSegments,
    } = {},
  } = useQuery(BUSINESS_SEGMENTS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSectors,
    } = {},
  } = useQuery(BUSINESS_SECTORS, {
    fetchPolicy: 'cache-and-network',
  });

  if (!business) return (<></>);

  return (
    <Form
      layout="vertical"
      form={form}
    >
      <Row gutter={12}>
        <Col xs={24} sm={24} lg={6}>
          <Item name="employeesNumber" rules={[{ required: true, message: 'Preencha o número de funcionários' }]}>
            <Input
              type="number"
              placeholder="Nº de funcionários"
            />
          </Item>
        </Col>

        <Col xs={24} sm={24} lg={6}>
          <Item name="revenueAmount" rules={[{ required: true, message: 'Preencha a fatura mensal' }]}>
            <Input
              placeholder="Faturamento Mensal"
              type="currency"
            />
          </Item>
        </Col>

        <Col xs={24} sm={24} lg={12}>
          <Item name="segment" rules={[{ required: true, message: 'Selecione o segmento' }]}>
            <Select
              className="gyramais-input"
              placeholder="Selecione o segmento"
            >
              {businessSegments?.map(({ id, name, value }) => (
                <Option key={id} value={value}>{name}</Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col xs={24} sm={24} lg={12}>
          <Item name="platformType" rules={[{ required: true, message: 'Selecione o ramo de atuação' }]}>
            <Select
              className="gyramais-input"
              placeholder="Selecione o ramo de atuação"
            >
              {businessPlatformTypes?.map(({ id, name, value }) => (
                <Option key={id} value={value}>{name}</Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col xs={24} sm={24} lg={12}>
          <Item name="sector" rules={[{ required: true, message: 'Selecione o setor' }]}>
            <Select
              className="gyramais-input"
              placeholder="Selecione o setor"
            >
              {businessSectors?.map(({ id, name, value }) => (
                <Option key={id} value={value}>{name}</Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

BusinessInfo.propTypes = {
  address: PropTypes.shape(),
};

export default BusinessInfo;
