import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Select,
  Button,
  Row,
  message,
} from 'antd';
import { useMutation } from '@apollo/client';
import { BUSINESS } from 'services/graphql/queries';
import { SAVE_PARTNER_LOAN } from 'services/graphql/mutations';
import { formatError } from 'utils';

const { Option } = Select;

const amounts = [
  'R$ 10.000',
  'R$ 20.000',
  'R$ 30.000',
  'R$ 40.000',
  'R$ 50.000',
  'R$ 60.000',
  'R$ 70.000',
  'R$ 80.000',
  'R$ 90.000',
  'R$ 100.000',
];

const installments = [
  '3 meses',
  '6 meses',
  '12 meses',
  '18 meses',
  '24 meses',
];

const { Item, useForm } = Form;

const LoanForm = ({
  onFinish,
  business,
}) => {
  const [form] = useForm();
  const [savePartnerLoan, { loading: saveLoading }] = useMutation(SAVE_PARTNER_LOAN, {
    onCompleted: () => {
      message.success('Novo empréstimo criado com sucesso');
      onFinish();
    },
    onError: (error) => {
      if (formatError(error) === 'Você já possui uma solicitação de empréstimo sendo processada.') {
        message.error('Este cliente já possui uma solicitação de empréstimo sendo processada.');
      } else message.error(formatError(error));
    },
    update: (cache, { data: { savePartnerLoan } }) => {
      business.loans.push(savePartnerLoan);
      cache.writeQuery({ query: BUSINESS, data: { business } });
    },
  });
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (values) => {
        await savePartnerLoan({
          variables: {
            customerId: business.id,
            amount: Number(values.amount.replace(/[R$.]/g, '')),
            installmentsCount: Number(values.installmentsCount.replace(/[meses]/g, '')),
          },
        });
      }}
    >
      <Item name="amount" rules={[{ required: true, message: 'Por favor selecione o valor.' }]} label="Quanto seu cliente precisa">
        <Select
          placeholder="Selecione o valor (R$)"
          style={{ width: '100%' }}
        >
          {amounts.map((amount) => (
            <Option key={amount}>{amount}</Option>
          ))}
        </Select>
      </Item>
      <Item name="installmentsCount" rules={[{ required: true, message: 'Por favor selecione o número de parcelas.' }]} label="Para ser pago em quantos meses">
        <Select
          placeholder="Selecione o número de parcelas"
          style={{ width: '100%' }}
        >
          {installments.map((installment) => (
            <Option key={installment}>{installment}</Option>
          ))}
        </Select>
      </Item>
      <Row type="flex" style={{ marginTop: 10 }}>
        <Button
          id="confirm-create-loan-button"
          block
          type="primary"
          htmlType="submit"
          loading={saveLoading}
        >
          Confirmar
        </Button>
      </Row>
    </Form>
  );
};

LoanForm.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string,
    loans: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onFinish: PropTypes.func,
};

export default memo(LoanForm);
