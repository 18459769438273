const BUSINESSES_IMPORT_JSON_DOCS_1 = [
  {
    title: 'name',
    type: 'String',
    description: 'Nome fantasia',
    required: true,
  },
  {
    title: 'cnpj',
    type: 'String',
    description: 'CNPJ',
    required: true,
  },
  {
    title: 'legalName',
    type: 'String',
    description: 'Razão social',
    required: true,
  },
  {
    title: 'email',
    type: 'String',
    description: 'Email do cliente',
    required: true,
  },
  {
    title: 'cpf',
    type: 'String',
    description: 'CPF do cliente',
    required: true,
  },
  {
    title: 'phoneNumber',
    type: 'String',
    description: 'Número de telefone do cliente',
    required: true,
  },
  {
    title: 'fullName',
    type: 'String',
    description: 'Nome do cliente',
    required: true,
  }, {
    title: 'employeesNumber',
    type: 'Int',
    description: 'Número de funcionários',
  }, {
    title: 'revenueAmount',
    type: 'Float',
    description: 'Quanto a empresa fatura (A média dos últimos 12 meses.)',
  }, {
    title: 'website',
    type: 'String',
    description: 'Site da empresa',
  }];

const BUSINESSES_IMPORT_JSON_DOCS_2 = [{
  title: 'platformType',
  type: 'String',
  description: `Por onde o cliente vende

Vendas loja física = "1"
Vendas loja online = "2"
Vendas loja online e física = "3"
`,
}, {
  title: 'sector',
  type: 'String',
  description: `Setor de atuação

Artigos culturais = "1"
Artigos médicos, ópticos e ortopédicos = "2"
Artigos recreativos ou esportivos = "3"
Combustíveis para veículos automotores = "4"
Comércio de peças e acessórios para veículos automotores/motocicletas = "5"
Eletroeletrônicos = "6"
Equipamentos de informática, videogames, celulares e comunicação = "7"
Equipamentos e artigos de uso doméstico = "8"
Materiais de construção = "9"
Outro Segmento Comércio = "10"
Outros Serviços = "11"
Produtos alimentícios, bebidas e fumo = "12"
Produtos farmacêuticos, perfumaria e cosméticos = "13"
Serviços Consultoria = "14"
Vestimenta = "15"
`,
},
{
  title: 'segment',
  type: 'String',
  description: `Modalidades de comercialização de produtos

Atacadista = "1"
Serviços = "2"
Varejista = "3"
Indústria, manufatura e produção agrária = "4"
`,
}];

module.exports = {
  BUSINESSES_IMPORT_JSON_DOCS_1,
  BUSINESSES_IMPORT_JSON_DOCS_2,
};
