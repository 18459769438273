import { gql } from '@apollo/client';

const BANKS = gql`
  query banks(
    $limit: Int
    $offset: Int
  ) {
    banks(
      limit: $limit
      offset: $offset
    ) {
      id
      name
      code
    }
  }
`;

export default BANKS;
export {
  BANKS,
};
