/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Spin,
} from 'antd';
import { Waypoint } from 'react-waypoint';
import { useQuery } from '@apollo/client';
import {
  BusinessFilterForm,
  ClientCard,
  ClientDrawer,
  ImportInfosModal,
  LoanStats,
} from 'components';
import {
  Button,
  SubHeader,
} from 'components/gyramais';

import {
  BUSINESSES,
  GET_LOAN_STATS,
} from './graphql';
import './styles.less';

const {
  Content,
} = Layout;

const limit = 20;

const removePartnerFilter = (business) => business.__typename !== 'Partner';

const ClientScreen = () => {
  const [lastPage, setLastPage] = useState(false);
  const [clientDrawer, setClientDrawer] = useState(false);
  const [importAlertVisible, setImportAlertVisible] = useState(false);

  const [variables, setVariables] = useState({ offset: 0, limit, sort: [{ descending: 'updatedAt' }] });
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [forceStatusValue, setForceStatusValue] = useState();

  const {
    data: { businesses = [] } = {},
    loading,
    fetchMore,
    error,
    refetch: refetchBusinesses,
  } = useQuery(BUSINESSES, {
    variables: { ...variables, partner: true },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      getLoanStats,
    } = {},
    loading: loanStatsLoading,
    refetch: refetchLoanStats,
  } = useQuery(GET_LOAN_STATS, {
    fetchPolicy: 'cache-and-network',
  });

  const refetch = () => {
    refetchBusinesses();
    refetchLoanStats();
  };

  const filteredBusinesses = businesses.filter(removePartnerFilter);

  return (
    <Layout id="clientscreen">
      <SubHeader
        title="Clientes"
        buttons={(
          <Button onClick={() => setClientDrawer(true)}>+ Cliente</Button>
        )}
        buttonsPlacement="right"
      />
      <Content>
        <div id="homescreen-tabs-container">
          <LoanStats
            loanStats={getLoanStats}
            loading={loanStatsLoading}
            onChooseStatus={setForceStatusValue}
          />
          <br />

          <BusinessFilterForm
            forceStatusValue={forceStatusValue}
            onSearch={(values) => {
              setVariables({ ...variables, ...values });
              setForceStatusValue(null);
              setLastPage(false);
            }}
          />
          <Row gutter={[0, 32]}>
            {
              filteredBusinesses.map((business) => (
                <Col span={24} key={business.id}>
                  <ClientCard
                    key={business.id}
                    business={business}
                    refetch={refetch}
                    loading={loading}
                  />
                </Col>
              ))
            }

            {
              !loading
              && filteredBusinesses.length === 0
              && (
                <p>
                  Sem clientes cadastrados
                </p>
              )
            }

            {
              (loading || loadingNextPage)
              && (
                <Col span={24}>
                  <Spin />
                </Col>
              )
            }
          </Row>
          {!error && !loading && lastPage === false
            && (
              <Waypoint
                onEnter={() => {
                  setLoadingNextPage(true);
                  fetchMore({
                    variables: {
                      ...variables,
                      offset: businesses.length,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      if (fetchMoreResult.businesses.length === 0) setLastPage(true);
                      setLoadingNextPage(false);
                      return {
                        ...prev, businesses: [...prev.businesses, ...fetchMoreResult.businesses],
                      };
                    },
                  });
                }}
              />
            )}
        </div>
      </Content>

      {clientDrawer && (
        <ClientDrawer
          visible={clientDrawer}
          onStartImport={() => setImportAlertVisible(true)}
          onClose={() => {
            setClientDrawer(false);
          }}
        />
      )}
      <ImportInfosModal
        visible={importAlertVisible}
        onClose={() => setImportAlertVisible(false)}
      />
    </Layout>
  );
};

export default ClientScreen;
