/* eslint-disable max-len */
import React from 'react';
import {
  Card,
  Row,
  Col,
} from 'antd';
import {
  Button,
} from 'components/gyramais';
import { Images } from 'assets';
import './styles.less';

const { LogoGyraDark } = Images;

const DocCard = () => (
  <Card id="doc-card">
    <Row gutter={[16, 16]} align="middle" justify="space-between">
      <Col xs={24} sm={24} md={16}>
        <div className="doc-card-header">
          <img src={LogoGyraDark} alt="logo" className="doc-card-image" />
          <div className="developers-label">DEVELOPERS</div>
        </div>
        <br />
        <div className="doc-card-text">
          Nossa API tem uma estrutura com a flexibilidade necessária para os desenvolvedores mais exigentes.
        </div>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Row justify="end">
          <Button
            onClick={() => window.open('https://developers.gyramais.com.br/', '_blank')}
            color="white"
            block
          >
            Acessar Documentação API
          </Button>
        </Row>
      </Col>
    </Row>
  </Card>
);

export default DocCard;
