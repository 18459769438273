import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
} from 'antd';

const StatusTag = ({
  title,
  backgroundColor,
  style,
}) => (
  <Divider
    orientation="left"
    style={{
      zIndex: 0, padding: 0, margin: '16px -10px', width: 'calc(100% + 20px)', ...style,
    }}
  >
    <div
      className="status-tag"
      style={{
        position: 'absolute',
        transform: 'translateY(-10px)',
        left: 10,
        padding: '2px 20px',
        borderRadius: 20,
        fontSize: 12,
        backgroundColor,
        color: 'white',
        fontWeight: 'bold',
        alignItems: 'center',
        cursor: 'default',
        zIndex: 1,
        minWidth: 180,
        minHeight: 24,
      }}
    >
      {title}
    </div>
  </Divider>
);

StatusTag.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backgroundColor: PropTypes.string,
  style: PropTypes.shape({}),
};

export default memo(StatusTag);
