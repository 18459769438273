import { gql } from '@apollo/client';

const POSTAL_CODE_TO_ADDRESS = gql`
  mutation postalCodeToAddress($postalCode: String!) {
    postalCodeToAddress(postalCode: $postalCode) {
      postalCode
      street
      district
      city
      state
    }
  }
`;

const SAVE_ADDRESS = gql`
  mutation saveAddress(
    $id: ID
    $businessId: ID
    $userId: ID
    $postalCode: String!
    $number: String!
    $complement: String
    $street: String!
    $district: String!
    $city: String!
    $state: String!
  ) {
    saveAddress(
      id: $id
      businessId: $businessId
      userId: $userId
      postalCode: $postalCode
      number: $number
      complement: $complement
      street: $street
      district: $district
      city: $city
      state: $state
    ) {
      id
    }
  }
`;

export {
  POSTAL_CODE_TO_ADDRESS,
  SAVE_ADDRESS,
};
