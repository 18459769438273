import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  notification,
  message,
  Divider,
} from 'antd';
import {
  Drawer,
  Button,
  ColorPicker,
} from 'components/gyramais';
import {
  formatError,
  getContrastYIQ,
} from 'utils';
import { useMutation } from '@apollo/client';
import useConfirmBrowserExit from 'utils/hooks/useConfirmBrowserExit ';
import { useConfirmModalContext } from 'contexts/ConfirmModalContext/confirm-modal-context';
import {
  SET_LANDING_COLORS,
} from './gql';

import './styles.less';

const LandingEditorDrawer = ({
  refetch,
  loading,
  visible,
  businessColors,
  onClose,
  businessId,
}) => {
  const [editedColors, setEditedColors] = useState(businessColors);
  const [hasEdited, setEdited] = useState(false);

  const { requestConfirmation } = useConfirmModalContext();

  const { enableConfirmation, disableConfirmation } = useConfirmBrowserExit({
    defaultEnabled: false,
    message: 'Você tem modificações que ainda nao foram salvas! Deseja sair mesmo assim?',
  });

  const closeDrawer = () => {
    onClose();
    disableConfirmation();
    setEdited(false);
    setEditedColors(businessColors);
  };

  const [setLandingColors, { loading: setLandingColorsLoading }] = useMutation(SET_LANDING_COLORS, {
    onCompleted: () => {
      notification.success({ message: 'Cores alterada com sucesso.' });
      refetch();
      setEdited(false);
      disableConfirmation();
      closeDrawer();
    },
    onError: (error) => message.error(formatError(error)),
  });

  const submitBusiness = () => {
    setLandingColors({ variables: { partnerId: businessId, colors: editedColors } });
  };

  useEffect(() => {
    if (businessColors) {
      setEditedColors(businessColors);
    }
  }, [businessColors]);

  useEffect(() => {
    if (hasEdited) {
      enableConfirmation();
    }
  }, [hasEdited]);

  return (
    <Drawer
      visible={visible}
      width="50%"
      className="landing-editor-drawer"
      title="Editar visual da Landing Page"
      onClose={() => {
        if (!hasEdited) {
          closeDrawer();
          return;
        }

        requestConfirmation({
          message: 'Você tem modificações que ainda nao foram salvas! Deseja fechar mesmo assim?',
          onOk: closeDrawer,
          onCancel: () => {},
        });
      }}
      primaryButton={{
        children: 'Salvar visual',
        id: 'save-landing-colors',
        loading: setLandingColorsLoading,
        disabled: businessColors === editedColors,
        onClick: () => submitBusiness('color'),
      }}
    >
      <Row className="color-picker-group">
        <Col xs={24} sm={24} md={24}>
          <p>
            <b>
              Cor primária
            </b>
          </p>
          <ColorPicker
            id="primary-landing-color"
            color={editedColors?.primary}
            onChange={(hex) => {
              setEditedColors({
                primary: hex,
                secondary: editedColors?.secondary,
                background: editedColors?.background,
              });
              setEdited(true);
            }}
            loading={loading}
          />
        </Col>

        <Col xs={24} sm={24} md={24}>
          <p>
            <b>
              Cor de fundo
            </b>
          </p>
          <ColorPicker
            color={editedColors?.background}
            onChange={(hex) => {
              setEditedColors({
                primary: editedColors?.primary,
                secondary: editedColors?.secondary,
                background: hex,
              });
              setEdited(true);
            }}
            loading={loading}
          />
        </Col>

        <Col xs={24} sm={24} md={24}>
          <p>
            <b>
              Cor secundária
            </b>
          </p>
          <ColorPicker
            color={editedColors?.secondary}
            onChange={(hex) => {
              setEditedColors({
                primary: editedColors?.primary,
                secondary: hex,
                background: editedColors?.background,
              });
              setEdited(true);
            }}
            loading={loading}
          />
        </Col>

      </Row>
      <Divider />
      <h3>Pré-visualização</h3>
      <br />

      <Row className="preview">
        <Col span={24}>
          <Row className="preview-container" style={{ background: editedColors?.background }}>
            <Col className="title-preview">
              <h2 style={{ color: getContrastYIQ((editedColors?.background)?.replace('#', '')) }}>
                <b style={{ color: editedColors?.primary }}> Simplicidade </b>
                <br />
                Conheça nosso processo
              </h2>
            </Col>
            <Col className="button-container" xs={24} sm={24} md={24}>
              <Button style={{ background: editedColors?.primary, color: getContrastYIQ((editedColors?.primary)?.replace('#', '')) }}>Solicitar empréstimo</Button>
            </Col>

          </Row>
        </Col>

        <Col className="title-preview" span={24}>
          <h2>
            <b style={{ color: editedColors?.secondary }}>Feito no Brasil,</b>
            <br />
            para empreendedores brasileiros.
          </h2>
        </Col>
      </Row>
    </Drawer>
  );
};

LandingEditorDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  businessColors: PropTypes.shape({}),
  refetch: PropTypes.func,
  loading: PropTypes.bool,
  businessId: PropTypes.string,
};

export default LandingEditorDrawer;
