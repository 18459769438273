import { gql } from '@apollo/client';

const SAVE_BUSINESS_USER = gql`
  mutation saveBusinessUser(
    $id: ID
    $businessId: ID
    $email: String
    $fullName: String
    $cpf: String
    $phoneNumber: String
    $civilStatusId: ID
    $civilPartner: CivilPartnerInput
    $idCardFileId: ID,
    $proofOfAddressFileId: ID,
    $proofOfIncomeFileId: ID,
    $typesValues: [String!],
  ) {
    saveBusinessUser(
      id: $id
      businessId: $businessId
      email: $email
      fullName: $fullName
      cpf: $cpf
      phoneNumber: $phoneNumber
      civilStatusId: $civilStatusId
      civilPartner: $civilPartner
      idCardFileId: $idCardFileId,
      proofOfAddressFileId: $proofOfAddressFileId,
      proofOfIncomeFileId: $proofOfIncomeFileId,
      typesValues: $typesValues,
    ) {
      id
      user {
        id
        civilStatus {
          id
          value
        }
      }
    }
  }
`;

const REMOVE_BUSINESS_USER = gql`
  mutation removeBusinessUser($id: ID!) {
    removeBusinessUser(id: $id) {
      id
    }
  }
`;

const COMPLETE_BUSINESS_USER = gql`
  mutation completeBusinessUser($id: ID!) {
    completeBusinessUser(id: $id)
  }
`;

export {
  SAVE_BUSINESS_USER,
  REMOVE_BUSINESS_USER,
  COMPLETE_BUSINESS_USER,
};
