import React, { useState } from 'react';
import {
  Card,
  message,
  Modal,
  Input,
  Select,
  Row,
  Col,
  Divider,
  Checkbox,
} from 'antd';
import {
  Button,
  Information,
} from 'components/gyramais';

import { useQuery, useMutation } from '@apollo/client';
import { formatError } from 'utils';
import {
  WEBHOOK_TYPES,
  GET_WEBHOOKS,
  SAVE_WEBHOOK,
  REMOVE_WEBHOOK,
  ME,
} from './graphql';
import './styles.less';

const { Option } = Select;

const WebhookCard = () => {
  const [newWebhookModal, setNewWebhookModal] = useState(false);
  const [websitePrefix, setWebsitePrefix] = useState('https://');
  const [types, setTypes] = useState([]);
  const [url, setUrl] = useState('');

  const {
    data: {
      webhookTypes = [],
    } = {},
  } = useQuery(WEBHOOK_TYPES);

  const {
    data: {
      webhooks,
    } = {},
    refetch,
  } = useQuery(GET_WEBHOOKS);

  const [saveWebhook, { loading }] = useMutation(SAVE_WEBHOOK, {
    onCompleted: () => {
      refetch();
      setUrl('');
      setTypes([]);
      setNewWebhookModal(false);
      message.success('Webhook criado com sucesso');
    },
    onError: (error) => message.error(formatError(error)),
  });
  const [removeWebhook] = useMutation(REMOVE_WEBHOOK, {
    onCompleted: () => {
      refetch();
      message.success('Webhook removido com sucesso');
    },
    onError: (error) => message.error(formatError(error)),
  });

  const {
    data: {
      me: {
        currentBusiness: {
          isToolbox,
        } = {},
      } = {},
    } = {},
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Card id="webhook-card">
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col xs={24} sm={24} md={16}>
            <Information value="Webhooks" />
          </Col>
          <Col xs={24} sm={24} md={5}>
            <Button
              id="webhook-button"
              block
              onClick={() => { setNewWebhookModal(true); }}
              loading={loading}
              color="secondary"
            >
              Novo webhook
            </Button>
          </Col>
        </Row>
        {webhooks?.[0] && (
          <>
            <Divider />
            <Row>
              {webhooks.map(({ id, url }, index) => (
                <Col span={24} key={id}>
                  <Row gutter={[16, 16]} align="middle" justify="space-between">
                    <Col xs={24} sm={24} md={16}>
                      <Information
                        className="webhook-card"
                        label="URL"
                        value={url}
                        detailed
                      />
                    </Col>
                    <Col xs={24} sm={24} md={5}>
                      <Button
                        id="delete-webhook"
                        onClick={() => removeWebhook({ variables: { id } })}
                        color="white"
                        block
                      >
                        Excluir webhook
                      </Button>
                    </Col>
                  </Row>
                  {index !== webhooks.length - 1 && (
                    <Divider className="webhook-divider" />
                  )}
                </Col>
              ))}
            </Row>
          </>
        )}
      </Card>

      <Modal
        visible={newWebhookModal}
        closable={false}
        title="Cadastrar um novo webhook"
        centered
        destroyOnClose
        footer={(
          <>
            <Button
              link
              onClick={() => {
                setTypes([]);
                setUrl('');
                setNewWebhookModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              id="confirm-webhook-button"
              onClick={() => {
                if (types.length === 0) {
                  return message.error('Selecione as informações que deseja receber no webhook');
                }

                const sanitizedUrl = url.trim().toLowerCase();
                if (sanitizedUrl.length === 0) {
                  return message.error('Insira a URL do webhook');
                }

                return saveWebhook({
                  variables: {
                    url: `${websitePrefix}${sanitizedUrl}`,
                    typeIds: types,
                  },
                });
              }}
            >
              Cadastrar webhook
            </Button>
          </>
          )}
      >
        <div id="save-webhook-modal-content">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <h4>
                Quais informações deseja receber neste webhook?
              </h4>
            </Col>
            {webhookTypes
              .filter((type) => (!type.toolbox) || (isToolbox && type.toolbox))
              .map((type, index) => (
                <Col key={type.id} xs={24} sm={12} md={8}>
                  <Checkbox
                    id={`webhook-type-${index + 1}`}
                    defaultChecked={false}
                    onChange={({ target: { checked } = {} }) => {
                      const temp = [...types];

                      if (checked) {
                        temp.push(type.id);
                      } else {
                        const index = temp.indexOf(type.id);
                        delete temp[index];
                      }

                      setTypes(temp.filter(Boolean));
                    }}
                  >
                    {type.name}
                  </Checkbox>
                </Col>
              ))}
          </Row>
          <br />
          <br />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <h4>
                Qual rota irá receber as informações do webhook?
              </h4>
            </Col>
            <Col span={24}>
              <Input
                id="webhook-url"
                placeholder="URL do Webhook"
                value={url}
                onChange={({ target: { value } }) => setUrl(value)}
                addonBefore={(
                  <Select
                    value={websitePrefix}
                    onChange={(e) => setWebsitePrefix(e)}
                  >
                    <Option value="http://">http://</Option>
                    <Option value="https://">https://</Option>
                  </Select>
                )}
              />
            </Col>
          </Row>

        </div>
      </Modal>
    </>
  );
};

export default WebhookCard;
