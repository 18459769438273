import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  AuthenticationScreen,
  HomeScreen,
  LogInScreen,
  LostPasswordScreen,
  NotFoundScreen,
  LoansScreen,
  ConfigScreen,
  BusinessUserRegisterScreen,
  InstallmentsScreen,
  PasswordScreen,
  SandboxScreen,
  NewClientScreen,
  ClientScreen,
  ClientDetailScreen,
  APIScreen,
  NewLoanScreen,
} from 'screens';
import { useReactiveVar } from '@apollo/client';
import { meVar } from 'services/graphql/cache';
import { clarity } from 'react-microsoft-clarity';
import TagManager from 'react-gtm-module';

export const CREATE_CLIENT_WITH_NO_LOAN_PATH = '/adicionar-cliente';
export const CREATE_CLIENT_WITH_LOAN_PATH = '/adicionar-cliente-emprestimo';

if (process.env.ENVIRONMENT === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-MFXZSFG',
  });
}

const routes = [
  {
    path: '/sandbox',
    component: SandboxScreen,
  },
  {
    path: '/login',
    component: LogInScreen,
  },
  {
    path: '/',
    component: HomeScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: '/autenticacao',
    component: AuthenticationScreen,
    exact: true,
  },
  {
    path: '/cadastro/senha',
    component: PasswordScreen,
    exact: true,
  },
  {
    path: '/clientes',
    component: ClientScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: '/cliente/:id',
    component: ClientDetailScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: '/emprestimos',
    component: LoansScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: '/esqueci-minha-senha',
    component: LostPasswordScreen,
    exact: true,
  },
  {
    path: '/configuracoes',
    component: ConfigScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: '/cadastro/empresa-socios',
    component: BusinessUserRegisterScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: '/parcelas',
    component: InstallmentsScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: '/API',
    component: APIScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: [
      CREATE_CLIENT_WITH_NO_LOAN_PATH,
      CREATE_CLIENT_WITH_LOAN_PATH,
    ],
    component: NewClientScreen,
    exact: true,
    redirect: '/login',
  },
  {
    path: '/adicionar-emprestimo',
    component: NewLoanScreen,
    exact: true,
    redirect: '/login',
  },
];

const PrivateRoute = ({ component: Component, me, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!me
      ? (<div><Redirect to={rest.redirect} /></div>)
      : (<Component {...props} />))}
  />
);

PrivateRoute.propTypes = {
  me: PropTypes.shape({}),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape(),
  ]),
};

const Routes = () => {
  const me = useReactiveVar(meVar);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    if (me) {
      clarity.init('dvwaks3knv');
      clarity.identify(me.id);
    }
  }, [me]);

  return (
    <Switch>
      {routes.filter((route) => !route.redirect).map((route) => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}
      {routes.filter(({ redirect }) => redirect).map(({
        path,
        exact,
        redirect,
        component,
      }) => (
        <PrivateRoute
          me={me}
          key={path}
          exact={exact}
          path={path}
          redirect={redirect}
          component={component}
        />
      ))}
      <Route path="*" component={NotFoundScreen} />
    </Switch>
  );
};

export default Routes;
