import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Steps } from 'antd';
import {
  EmailForm,
  FullNameForm,
  CpfForm,
  PhoneNumberForm,
  UserAddressForm,
  BusinessUserUploadForm,
  BusinessUserTypeForm,
  CivilStatusForm,
} from 'components';

const { Step } = Steps;

const BusinessUserRegisterScreen = () => {
  const { state: { businessUserId, businessId, cpf } } = useLocation();
  const [current, setCurrent] = useState(0);
  const steps = [
    {
      title: 'Email',
      content: (
        <EmailForm
          id={businessUserId}
          businessId={businessId}
          cpf={cpf}
          onCompleted={() => setCurrent(current + 1)}
        />
      ),
    },
    {
      title: 'Nome',
      content: (
        <FullNameForm
          id={businessUserId}
          onCompleted={() => setCurrent(current + 1)}
          goBack={() => setCurrent(current - 1)}
        />
      ),
    },
    {
      title: 'CPF',
      content: (
        <CpfForm
          id={businessUserId}
          onCompleted={() => setCurrent(current + 1)}
          goBack={() => setCurrent(current - 1)}
        />
      ),
    },
    {
      title: 'Telefone',
      content: (
        <PhoneNumberForm
          id={businessUserId}
          onCompleted={() => setCurrent(current + 1)}
          goBack={() => setCurrent(current - 1)}
        />
      ),
    },
    {
      title: 'Endereço',
      content: (
        <UserAddressForm
          id={businessUserId}
          onCompleted={() => setCurrent(current + 1)}
          goBack={() => setCurrent(current - 1)}
        />
      ),
    },
    {
      title: 'Documentos',
      content: (
        <BusinessUserUploadForm
          id={businessUserId}
          onCompleted={() => setCurrent(current + 1)}
          goBack={() => setCurrent(current - 1)}
        />
      ),
    },
    {
      title: 'Responsabilidades',
      content: (
        <BusinessUserTypeForm
          id={businessUserId}
          onCompleted={() => setCurrent(current + 1)}
          goBack={() => setCurrent(current - 1)}
        />
      ),
    },
    {
      title: 'Estado Civil',
      content: (
        <CivilStatusForm
          id={businessUserId}
          businessId={businessId}
          goBack={() => setCurrent(current - 1)}
        />
      ),
    },
  ];
  return (
    <div className="steps-container">
      <Steps current={current} className="steps" size="small">
        {steps.map((item, i) => (
          <Step
            key={item.title}
            title={item.title}
            onClick={() => { if (i < current) setCurrent(i); }}
          />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div> </div>
    </div>
  );
};

export default BusinessUserRegisterScreen;
