import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  message,
} from 'antd';
import {
  BusinessInfoForm,
} from 'components';
import { useLazyQuery } from '@apollo/client';
import { formatError } from 'utils';
import {
  BUSINESSES,
} from './graphql';
import './styles.less';

const BusinessInfosStep = ({
  form,
  hasLoanCreation,
}) => {
  const history = useHistory();

  const [businesses] = useLazyQuery(BUSINESSES, {
    onError: (error) => {
      message.error(formatError(error));
    },
    onCompleted: ({ businesses }) => {
      const business = businesses[0];

      if (business) {
        if (hasLoanCreation) {
          message.info('Este CNPJ já está cadastrado, te redirecionamos para a criação de empréstimos!');
          history.push(`/adicionar-emprestimo?cnpj=${business.cnpj}`);
        } else {
          form.setFieldsValue({
            cnpj: '',
          });
          message.error('Este CNPJ já está cadastrado entre seus clientes.');
        }

      }
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Row align="middle" justify="center" id="business-infos-step">
      <Col xs={24} sm={24} md={24} lg={20}>
        <Card>
          <div className="card-header">
            <h3 className="secondary-text no-margin-bottom">Quem é seu cliente?</h3>
            <h3>Insira os dados da empresa.</h3>
          </div>
          <BusinessInfoForm
            form={form}
            businesses={businesses}
          />
        </Card>
      </Col>
    </Row>
  );
};

BusinessInfosStep.propTypes = {
  form: PropTypes.shape().isRequired,
  hasLoanCreation: PropTypes.bool,
};

export default BusinessInfosStep;
