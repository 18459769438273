import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Button,
  Row,
  message,
  Col,
  Card,
  Upload,
  Spin,
} from 'antd';
import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { BUSINESS_USER } from 'services/graphql/queries';
import { UPLOAD_FILE, SAVE_MARRIAGE_OR_DIVORCE_DOCS, COMPLETE_BUSINESS_USER } from 'services/graphql/mutations';
import { Images } from 'assets';
import { formatError } from 'utils';

const { LogoGyraG } = Images;

const BusinessUserUploadMarriedForm = ({
  id,
  businessId,
  onCompleted,
  goBack,
}) => {
  const history = useHistory();
  const {
    data: {
      businessUser: {
        user: {
          id: userId,
          proofOfMariageOrDivorce,
          civilStatus: { value, name } = {},
          civilPartner = {},
        } = {},
      } = {},
    } = {},
    loading,
    refetch,
  } = useQuery(BUSINESS_USER, {
    skip: !id,
    variables: { id },
    partialRefetch: true,
    fetchPolicy: 'network-only',
  });
  const [completeBusinessUser,
    { loading: completeLoading }] = useMutation(COMPLETE_BUSINESS_USER, {
    onCompleted: async () => {
      onCompleted();
      history.push(`/empresas/${businessId}`);
    },
  });
  const [saveIdCard,
    { loading: saveLoadingIdCard }] = useMutation(SAVE_MARRIAGE_OR_DIVORCE_DOCS, {
    onError: (error) => message.error(formatError(error)),
    onCompleted: () => refetch(),
  });
  const [saveProofOfMariageOrDivorce,
    { loading: saveLoadingProofOfMariageOrDivorce }] = useMutation(SAVE_MARRIAGE_OR_DIVORCE_DOCS, {
    onError: (error) => message.error(formatError(error)),
    onCompleted: () => refetch(),
  });
  const [uploadId, { loading: loadingIdCard }] = useMutation(UPLOAD_FILE, {
    onCompleted: ({ uploadFile: { id: idCardFileId } }) => {
      saveIdCard({
        variables: {
          id: userId,
          idCardFileId,
        },
      });
    },
    onError: (error) => message.error(formatError(error)),
  });
  const [uploadProofOfMariageOrDivorce, {
    loading: loadingProofOfMariageOrDivorce,
  }] = useMutation(UPLOAD_FILE, {
    onCompleted: ({ uploadFile: { id: proofOfMariageOrDivorceFileId } }) => {
      saveProofOfMariageOrDivorce({
        variables: {
          id: userId,
          proofOfMariageOrDivorceFileId,
        },
      });
    },
    onError: (error) => message.error(formatError(error)),
  });

  return (
    <Spin
      indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
      spinning={loading}
      style={{ transform: 'translateX(-16px)' }}
    >
      <Form className="form-footer">
        <h3 className="wl-text" style={{ marginBottom: 24 }}>
          {`Upload de documentos de ${name}`}
        </h3>
        {value && value === 'casado' && (
        <Spin spinning={loadingIdCard || saveLoadingIdCard}>
          <Upload
            accept="image/png,image/jpeg,application/pdf"
            showUploadList={false}
            disable={loading}
            beforeUpload={() => false}
            onChange={({ file }) => uploadId({ variables: { file } })}
          >
            <Card
              className={civilPartner && !civilPartner.idCard ? 'upload-card' : 'upload-card upload-card-success'}
              hoverable
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <UploadOutlined style={{ fontSize: 30 }} />
                <div style={{ width: '80%' }}>
                  <h3>
                    {`Identidade/CPF ou CNH de ${civilPartner.fullName}`}
                  </h3>
                  O documento deve estar na validade e a foto em boa qualidade
                </div>
                {civilPartner && civilPartner.idCard
                && (
                  <CheckCircleOutlined />
                )}
              </div>
            </Card>
          </Upload>
        </Spin>
        )}
        <Spin spinning={loadingProofOfMariageOrDivorce || saveLoadingProofOfMariageOrDivorce}>
          <Upload
            accept="image/png,image/jpeg,application/pdf"
            showUploadList={false}
            disable={loadingProofOfMariageOrDivorce}
            beforeUpload={() => false}
            onChange={({ file }) => uploadProofOfMariageOrDivorce({ variables: { file } })}
          >
            <Card
              className={!proofOfMariageOrDivorce ? 'upload-card' : 'upload-card upload-card-success'}
              hoverable
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <UploadOutlined style={{ fontSize: 30 }} />
                <div style={{ width: '80%' }}>
                  <h3>
                    {value && value === 'casado' ? 'Certidão de casamento' : 'Averbação do divórcio'}
                  </h3>
                  {value && value === 'casado' ? 'O documento provando que o sócio e o cônjuge são casados' : 'O documento provando a separação'}
                </div>
                {proofOfMariageOrDivorce
                && (
                  <CheckCircleOutlined />
                )}
              </div>
            </Card>
          </Upload>
        </Spin>
        <Row gutter={12} className="fixed-footer">
          <Col span={10}>
            <Button
              block
              disabled={loading
                || saveLoadingIdCard || loadingProofOfMariageOrDivorce}
              ghost
              type="primary"
              onClick={() => goBack()}
            >
              Voltar
            </Button>
          </Col>
          <Col span={14}>
            <Button
              block
              disabled={loading
                || saveLoadingIdCard || saveLoadingProofOfMariageOrDivorce
                || (civilPartner && !civilPartner.idCard) || !proofOfMariageOrDivorce}
              loading={loading
                || saveLoadingIdCard || saveLoadingProofOfMariageOrDivorce
                || loadingProofOfMariageOrDivorce || completeLoading}
              onClick={async () => {
                await completeBusinessUser({
                  variables: { id },
                });
              }}
              type="primary"
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

BusinessUserUploadMarriedForm.propTypes = {
  id: PropTypes.string,
  businessId: PropTypes.string,
  onCompleted: PropTypes.func,
  goBack: PropTypes.func,
};

export default memo(BusinessUserUploadMarriedForm);
