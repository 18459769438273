import { gql } from '@apollo/client';

const BUSINESS_USER = gql`
  query businessUser(
    $id: ID!
  ) {
    businessUser(id: $id) {
      id
      completedAt
      user {
        id
        fullName
        email
        phoneNumber
        phoneNumberVerified
        cpf
        address {
          id
          postalCode
          street
          number
          complement
          district
          city
          state
        }
        civilStatus {
          id
          name
          value
        }
        civilPartner {
          id
          fullName
          email
          cpf
          idCard {
            id
          }
          phoneNumber
        }
        idCard {
          id
        }
        proofOfMariageOrDivorce {
          id
        }
        proofOfAddress {
          id
        }
        proofOfIncome {
          id
        }
      }
      types {
        id
        name
        value
      }
    }
  }
`;

const BUSINESS_USERS = gql`
  query(
    $businessId: ID!
    $limit: Int
    $offset: Int
    $sort: [SortInput!]
  ) {
    businessUsers(
      businessId: $businessId
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      id
      user {
        id
        fullName
        email
      }
      types {
        id
        name
        value
      }
      completedAt
    }
  }
`;

export default BUSINESS_USER;
export {
  BUSINESS_USER,
  BUSINESS_USERS,
};
