import React, { memo } from 'react';

import {
  HorizontalSplit, LostPasswordForm,
} from 'components';

const LostPasswordScreen = () => (
  <HorizontalSplit className="register-layout">
    <LostPasswordForm />
  </HorizontalSplit>
);

export default memo(LostPasswordScreen);
