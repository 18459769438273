import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Layout,
  Divider,
  Row,
  Col,
  Tabs,
  Table,
  notification,
} from 'antd';
import {
  LockOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import {
  LoanCard,
  ClientCard,
} from 'components';
import { EditClientDrawer } from 'components/drawers';
import {
  Button,
  SubHeader,
} from 'components/gyramais';
import { useActionContext } from 'contexts/ActionContext';
import {
  formatString,
} from 'utils';
import {
  BUSINESS,
  RESET_PASSWORD,
} from './graphql';

import './styles.less';

const {
  Content,
} = Layout;
const {
  TabPane,
} = Tabs;

const ClientDetailScreen = () => {
  const [currentTab, setCurrentTab] = useState('loans');

  const history = useHistory();
  const { id } = useParams();
  const [editClientDrawerVisible, setEditClientDrawerVisible] = useState(false);
  const [loanPermission, setLoanPermission] = useState(false);
  const { checkForBusinessAction, loading } = useActionContext();

  const {
    data: {
      business: {
        loans = [],
        users = [],
      } = {},
      business,
    } = {},
    refetch,
    loading: loadingBusiness,
  } = useQuery(BUSINESS, {
    skip: !id,
    variables: { id },
    fetchPolicy: 'no-cache',
  });
  const [resetPassword, { loading: resetLoading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      notification.success({ message: 'Email para redefinição de senha enviado para o cliente!' });
    },
  });

  const columnsLogic = [
    {
      title: 'Nome',
      className: 'table-column',
      key: 'fullName',
      render: ({ fullName }) => fullName ?? '-',
    },
    {
      title: 'E-mail',
      className: 'table-column',
      key: 'email',
      render: ({ email }) => email ?? '-',
    },
    {
      title: 'CPF',
      className: 'table-column',
      key: 'cpf',
      render: ({ cpf }) => (cpf ? formatString(cpf, 'cpf') : '-'),
    },
    {
      title: 'Telefone',
      className: 'table-column',
      key: 'phoneNumber',
      render: ({ phoneNumber }) => phoneNumber ?? '-',
    },
    {
      title: '',
      className: 'table-column',
      key: 'button',
      render: ({ email }) => (
        <Button
          loading={resetLoading}
          onClick={() => { resetPassword({ variables: { email } }); }}
          link
          icon={<LockOutlined />}
        >
          Redefinir senha
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (!loading) {
      setLoanPermission(checkForBusinessAction('create-client-with-loan'));
    }
  }, [loading]);

  return (
    <Layout id="client-detail-screen">
      <SubHeader
        title="Clientes"
      />
      <Content>
        {id && (
          <div>
            <Button
              link
              onClick={() => history.push('/clientes')}
              className="back-button"
              icon={<LeftOutlined />}
            >
              Voltar para lista de clientes
            </Button>
          </div>
        )}

        <br />
        <br />

        <Row>
          {business && (
            <Col span={24}>
              <ClientCard
                business={business}
                loading={loadingBusiness}
                headerButton={loanPermission && (
                  <Button
                    block
                    onClick={() => history.push(`/adicionar-emprestimo?cnpj=${business.cnpj}`)}
                  >
                    + Empréstimo
                  </Button>
                )}
                detailed
                aggregated
              />
            </Col>
          )}
          <Col span={24}>
            <Divider />

            <Tabs defaultActiveKey={currentTab} type="line" onChange={(activeTab) => { setCurrentTab(activeTab); }}>
              <TabPane tab="Empréstimos" key="loans">
                <Row gutter={[0, 32]}>
                  {loans.map((loan, index) => (
                    <Col span={24} key={loan.id}>
                      <LoanCard
                        index={index}
                        loan={loan}
                        businessId={business.id}
                        revenueStatementValues={business?.revenueStatementValues}
                        revenueStatement={business?.revenueStatement}
                        detailed
                        refetch={refetch}
                      />
                    </Col>
                  ))}
                </Row>
              </TabPane>
              <TabPane tab={`Responsáveis (${users.length})`} key="users">
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columnsLogic}
                  dataSource={users.map(({ user }) => user)}
                  pagination={false}
                  size="small"
                  rowKey="id"
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>

      </Content>

      {business && (
        <EditClientDrawer
          visible={editClientDrawerVisible}
          onClose={() => setEditClientDrawerVisible(false)}
          business={business}
          refetch={refetch}
        />
      )}
    </Layout>
  );
};

export default ClientDetailScreen;
