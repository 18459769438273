/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  Slider,
  Row,
  Col,
  Tooltip,
  Skeleton,
} from 'antd';
import { Input } from 'components/gyramais';

import './styles.less';
import { formatString } from 'utils';

/**
 * @param {{
 *  className?: string,
 *  min: number
 *  max: number
 *  step: number
 *  value: number
 *  onChange: void
 *  showValues?: boolean
 *  valuesFormatter?: void
 *  type?: 'normal' | 'warning' | 'danger'
 *  marks?: { [number]: string }[] | number[]
 *  loading?: boolean
 *  tooltip?: {
 *    message: string
 *    visible: boolean
 *  }
 *  input?: {
 *    type: 'text' | 'currency'
 *  }
 *  disabled?: boolean
 * }} props
 */
const GyramaisSlider = ({
  className,
  min = 10000,
  max = 999999999,
  step = 1000,
  value,
  onChange,
  showValues,
  valuesFormatter,
  type,
  marks,
  loading,
  tooltip,
  input,
  disabled,
}) => (
  <Tooltip title={tooltip?.message} visible={tooltip?.visible}>
    {loading ? (
      <Skeleton.Button active className="gyramais-slider-skeleton" />
    ) : (
      <div className={`gyramais-slider-container ${className || ''}`}>
        <Slider
          className={`gyramais-slider ${['warning', 'danger'].includes(type) ? `${type}-gyramais-slider` : ''}`}
          tooltipVisible={false}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          step={step}
          marks={marks}
          disabled={disabled}
        />

        {!marks && showValues && (
          <Row align="middle" justify="space-between">
            <Col>
              <div className="gyramais-slider-range-value">
                <b className="gray-text">
                  {valuesFormatter ? valuesFormatter(min) : min}
                </b>
              </div>
            </Col>
            <Col>
              {input ? (
                <Input
                  className="gyramais-slider-value-input"
                  placeholder="Valor"
                  type={input.type}
                  value={value}
                  onChange={onChange}
                  maxLength={formatString(max, 'currency').length}
                  disabled={disabled}
                />
              ) : (
                <span className={`gyramais-slider-value ${['warning', 'danger'].includes(type) ? `${type}-text` : ''}`}>
                  {valuesFormatter ? valuesFormatter(value) : value}
                </span>
              )}
            </Col>
            <Col>
              <div className="gyramais-slider-range-value">
                <b className="gray-text">
                  {valuesFormatter ? valuesFormatter(max) : max}
                </b>
              </div>
            </Col>
          </Row>
        )}
      </div>
    )}
  </Tooltip>

);

export default GyramaisSlider;
