import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {
  Form,
  Row,
  Col,
  Select,
  AutoComplete,
  DatePicker,
} from 'antd';
import {
  Button,
} from 'components/gyramais';
import { useQuery } from '@apollo/client';
import { BUSINESS_NAMES } from './graphql';

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;
const installmentStatuses = {
  pending: 'Pendente',
  paid: 'Pago',
  'partially-paid': 'Parcialmente Pago',
};

const InstallmentFilterForm = ({
  onSearch,
}) => {
  const [businessName, setBusinessName] = useState();
  const [businessCnpj, setBusinessCnpj] = useState();
  const {
    data: { businesses = [] } = {},
  } = useQuery(BUSINESS_NAMES, {
    variables: {
      businessName,
      cnpj: businessCnpj,
      limit: 10,
    },
    skip: !businessName && !businessCnpj,
    fetchPolicy: 'network-only',
  });
  return (
    <Form
      layout="vertical"
      className="filter-form"
      initialValues={{ installmentStatusesValues: undefined }}
      onFinish={(values) => {
        const {
          businessName,
          cnpj,
          installmentStatusesValues,
          dueDateRange = [],
        } = values;
        onSearch({
          businessName,
          cnpj,
          installmentStatusesValues: installmentStatusesValues && [installmentStatusesValues],
          startDueDate: dueDateRange?.[0],
          endDueDate: dueDateRange?.[1],
        });
      }}
    >
      <h6>
        <b className="link-text">
          Filtros
        </b>
      </h6>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={5}>
          <Item name="businessName">
            <AutoComplete
              className="gyramais-input"
              onChange={(name) => setBusinessName(name)}
              options={businesses
                .filter(({ name }) => name)
                .map(({ name }) => ({ label: name, value: name }))}
              placeholder="Nome do cliente"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={5}>
          <Item name="cnpj">
            <AutoComplete
              className="gyramais-input"
              onChange={(cnpj) => setBusinessCnpj(cnpj)}
              options={businesses
                .filter(({ name }) => name)
                .map(({ name, cnpj }) => ({ label: name, value: cnpj }))}
              placeholder="CNPJ do cliente"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4}>
          <Item name="installmentStatusesValues">
            <Select
              className="gyramais-input"
              allowClear
              placeholder="Selecione um status"
            >
              {Object.keys(installmentStatuses).map((key) => (
                <Option key={key} value={key}>{installmentStatuses[key]}</Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Item name="dueDateRange">
            <RangePicker
              className="gyramais-ranger-picker gyramais-input"
              format="DD/MM/YYYY"
              placeholder={['Vencimento de', 'até']}
              locale={locale}
              name="Eae"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4}>
          <Button
            block
            id="installments-filter-button"
            htmlType="submit"
          >
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

InstallmentFilterForm.propTypes = {
  onSearch: PropTypes.func,
};

export default memo(InstallmentFilterForm);
