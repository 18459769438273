import { gql } from '@apollo/client';

const SAVE_INVOICE = gql`
  mutation saveInvoice(
    $installmentId: ID!
    $amount: Float
    $dueDate: Date
    $paidAt: Date
    $installmentPaid: Boolean
  ) {
    saveInvoice(
      installmentId: $installmentId
      amount: $amount
      dueDate: $dueDate
      paidAt: $paidAt
      installmentPaid: $installmentPaid
    ) {
      id
      paidAt
      canceledAt
      installment
      url
      amount
      dueDate
    }
  }
`;

export default SAVE_INVOICE;
export {
  SAVE_INVOICE,
};
