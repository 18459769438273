import { gql } from '@apollo/client';

const UPLOAD_FILE = gql`
  mutation uploadFile(
    $file: Upload!
    $fileType: String
    ) {
    uploadFile(
      file: $file
      fileType: $fileType
    ) {
      id
    }
  }
`;

const UPLOAD_MULTIPLE_FILES = gql`
  mutation uploadMultipleFiles($files: [Upload!]!) {
    uploadMultipleFiles(files: $files) {
      id
    }
  }
`;

export { UPLOAD_FILE, UPLOAD_MULTIPLE_FILES };
