import React from 'react';
import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  Input,
} from 'components/gyramais';
import PropTypes from 'prop-types';

const { Item } = Form;

const ClientInfoForm = ({ form, disabled }) => (
  <Form
    form={form}
    layout="vertical"
  >
    <Row gutter={12}>
      <Col xs={24} sm={24} md={12}>
        <Item
          label="Nome do sócio"
          name="fullName"
          rules={[{ required: true, message: 'Por favor preencha o nome do sócio.' }]}
        >
          <Input
            placeholder="Nome do sócio"
            disabled={disabled}
          />
        </Item>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Item
          label="CPF"
          name="cpf"
          rules={[{ required: true, message: 'Por favor preencha o cpf.' }]}
        >
          <Input
            placeholder="CPF"
            type="masked"
            disabled={disabled}
            guide
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          />
        </Item>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Por favor preencha o email.' }]}
        >
          <Input
            placeholder="Email"
            disabled={disabled}
          />
        </Item>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Item
          name="phoneNumber"
          label="Telefone celular"
          rules={[{ required: true, message: 'Por favor, insira o numero de Telefone' }]}
        >
          <Input
            type="masked"
            guide
            disabled={disabled}
            placeholder="Telefone celular"
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          />
        </Item>
      </Col>
    </Row>
  </Form>
);

ClientInfoForm.propTypes = {
  form: PropTypes.shape(),
  disabled: PropTypes.bool,
};

export default ClientInfoForm;
