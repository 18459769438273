/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from 'react';

import './style.less';

/**
 * @type {React.FC<{
 *  primaryButton?: Button.propTypes
 *  backButton?: Button.propTypes
 *  size?: 'small' | 'default'
 *  type?: 'normal' | 'primary' | 'secondary'
 *  title?: string | React.ReactNode
 *  className?: string
 *  selected?: boolean
 *  onClick?: () => void
 * }}
 */
const Card = ({
  title,
  children,
  className,
  selected,
  type = 'normal',
  size = 'default',
  onClick,
  ...props
}) => {

  const handleTitle = () => {
    if (!title) {
      return undefined;
    }

    if (typeof title === 'string') {
      return (
        <div className="title-container">
          {size === 'small' && (
            <h5 className="no-margin-bottom">{title}</h5>
          )}

          {size === 'default' && (
            <h3 className="no-margin-bottom">{title}</h3>
          )}
        </div>
      );
    }

    return (
      <div className="title-container">
        {title}
      </div>
    );
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => false}
      onClick={onClick}
      className={`gyra-card${className ? ` ${className}` : ''} ${size === 'small' ? 'gyra-card-small' : ''} ${!children ? 'gyra-card-without-content' : ''} ${type ? `gyra-card-${type}` : ''} ${onClick ? 'gyra-card-clickable' : ''} ${selected ? 'gyra-card-selected' : ''}`}
      {...props}
    >
      {handleTitle()}
      {children}
    </div>
  );
};

export default Card;
