import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
  Form,
  Modal,
  InputNumber,
  Checkbox,
  message,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { INSTALLMENT } from 'services/graphql/queries';
import { SAVE_INVOICE } from 'services/graphql/mutations';
import moment from 'moment';
import { formatDate, formatError } from '../../utils';

const { success } = Modal;

const { Item, useForm } = Form;

const PaymentModal = ({
  installment,
  onCancel,
  onOk,
}) => {
  const [form] = useForm();
  const [date, setDate] = useState(null);
  useQuery(INSTALLMENT, {
    fetchPolicy: 'network-only',
    partialRefetch: true,
    variables: { id: installment && installment.id, date },
    skip: !installment,
    onCompleted: ({
      installment: {
        movements,
      } = {},
    } = {}) => {
      const confirmedMovements = movements?.filter(({ canceledAt }) => !canceledAt);
      const lastPayment = confirmedMovements?.[confirmedMovements.length - 1];
      form.setFieldsValue({
        amount: lastPayment.balanceAmount.toFixed(2),
      });
    },
  });
  const [saveInvoice, { loading: confirmLoading }] = useMutation(SAVE_INVOICE, {
    onCompleted: () => {
      form.setFieldsValue({
        amount: installment.values.balanceAmount.toFixed(2),
      });
      success({
        centered: true,
        title: 'A movimentação foi gerada com sucesso.',
      });
      onOk();
    },
    onError: (error) => message.error(formatError(error)),
  });
  useEffect(() => {
    if (installment) {
      if (!date) {
        const auxDate1 = new Date();
        const dueDate = new Date(
          auxDate1.getFullYear(),
          auxDate1.getMonth(),
          auxDate1.getDate(), 0, 0, 0,
        );
        setDate(dueDate);
      }
    } else if (date) setDate(null);
  });
  return (
    <Modal
      visible={Boolean(installment)}
      closable={false}
      centered
      title={`Deseja criar um pagamento referente ao vencimento de ${installment && formatDate(new Date(installment.dueDate))}?`}
      confirmLoading={confirmLoading}
      cancelText="Cancelar"
      onCancel={() => onCancel()}
      onOk={async () => {
        const { amount, installmentPaid } = await form.validateFields();
        saveInvoice({
          variables: {
            installmentId: installment && installment.id,
            amount: Number(amount),
            dueDate: date,
            installmentPaid,
            paidAt: date,
          },
        });
      }}
    >
      <Form
        form={form}
        initialValues={{
          paidAt: date && moment(date),
          installmentPaid: false,
        }}
      >
        <Item
          label="Data de pagamento"
          name="paidAt"
          rules={[{
            required: true, message: 'Por favor escolha a data do pagamento.',
          }]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            onChange={(value) => {
              const auxDate1 = value.toDate();
              const dueDate = new Date(
                auxDate1.getFullYear(),
                auxDate1.getMonth(),
                auxDate1.getDate(), 0, 0, 0,
              );
              setDate(dueDate);
            }}
          />
        </Item>
        <Item
          label="Valor"
          name="amount"
          rules={[{
            required: true, message: 'Por favor insira o valor.',
          }]}
        >
          <InputNumber
            placeholder="Valor (R$)"
            style={{ width: '100%' }}
            min={0}
            formatter={(value) => `R$ ${value}`}
            parser={(value) => value.replace(/[R$\s?|(*)]/g, '')}
            autoFocus
          />
        </Item>
        <Item name="installmentPaid" valuePropName="checked">
          <Checkbox
            style={{ marginBottom: 20 }}
          >
            Parcela Paga
          </Checkbox>
        </Item>
      </Form>
    </Modal>
  );
};

PaymentModal.propTypes = {
  installment: PropTypes.shape({
    id: PropTypes.string,
    dueDate: PropTypes.string,
    totalAmount: PropTypes.number,
    paidAmount: PropTypes.number,
    values: PropTypes.shape({
      balanceAmount: PropTypes.number,
      penalty: PropTypes.shape({
        totalAmount: PropTypes.number,
      }),
    }),
  }),
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PaymentModal;
