import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  message,
  Col,
  Card,
  Upload,
  Spin,
} from 'antd';
import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { BUSINESS_USER } from 'services/graphql/queries';
import { SAVE_BUSINESS_USER, UPLOAD_FILE } from 'services/graphql/mutations';
import { Images } from 'assets';
import { formatError } from 'utils';

const { LogoGyraG } = Images;

const BusinessUserUploadForm = ({
  id,
  onCompleted,
  goBack,
}) => {
  const {
    data: {
      businessUser: {
        user: {
          fullName,
          idCard,
          proofOfAddress,
          proofOfIncome,
        } = {},
      } = {},
    } = {},
    loading,
    refetch,
  } = useQuery(BUSINESS_USER, {
    skip: !id,
    variables: { id },
    partialRefetch: true,
    fetchPolicy: 'network-only',
  });
  const [saveIdCard,
    { loading: saveLoadingIdCard }] = useMutation(SAVE_BUSINESS_USER, {
    onError: (error) => message.error(formatError(error)),
    onCompleted: () => refetch(),
  });
  const [saveProofOfIncome,
    { loading: saveLoadingProofOfIncome }] = useMutation(SAVE_BUSINESS_USER, {
    onError: (error) => message.error(formatError(error)),
    onCompleted: () => refetch(),
  });
  const [saveProofOfAddress,
    { loading: saveLoadingProofOfAddress }] = useMutation(SAVE_BUSINESS_USER, {
    onError: (error) => message.error(formatError(error)),
    onCompleted: () => refetch(),
  });
  const [uploadId, { loading: loadingIdCard }] = useMutation(UPLOAD_FILE, {
    onCompleted: ({ uploadFile: { id: idCardFileId } }) => {
      saveIdCard({
        variables: {
          id,
          idCardFileId,
        },
      });
    },
    onError: (error) => message.error(formatError(error)),
  });
  const [uploadProofOfIncome, { loading: loadingProofOfIncome }] = useMutation(UPLOAD_FILE, {
    onCompleted: ({ uploadFile: { id: proofOfIncomeFileId } }) => {
      saveProofOfIncome({
        variables: {
          id,
          proofOfIncomeFileId,
        },
      });
    },
    onError: (error) => message.error(formatError(error)),
  });
  const [uploadProofOfAddress, { loading: loadingProofOfAddress }] = useMutation(UPLOAD_FILE, {
    onCompleted: ({ uploadFile: { id: proofOfAddressFileId } }) => {
      saveProofOfAddress({
        variables: {
          id,
          proofOfAddressFileId,
        },
      });
    },
    onError: (error) => message.error(formatError(error)),
  });
  return (
    <Spin
      indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
      spinning={loading}
      style={{ transform: 'translateX(-16px)' }}
    >
      <Form className="form-footer">
        <h3 className="wl-text" style={{ marginBottom: 24 }}>
          {`Upload de documentos de ${fullName}`}
        </h3>
        <Spin spinning={loadingIdCard || saveLoadingIdCard}>
          <Upload
            id="business-user-identity-upload"
            accept="image/png,image/jpeg,application/pdf"
            showUploadList={false}
            disable={loading}
            beforeUpload={() => false}
            onChange={({ file }) => uploadId({ variables: { file } })}
          >
            <Card
              className={!idCard ? 'upload-card' : 'upload-card upload-card-success'}
              hoverable
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <UploadOutlined style={{ fontSize: 30 }} />
                <div style={{ width: '80%' }}>
                  <h3>
                    Identidade/CPF ou CNH
                  </h3>
                  O documento deve estar na validade e a foto em boa qualidade
                </div>
                {idCard
                && (
                  <CheckCircleOutlined />
                )}
              </div>
            </Card>
          </Upload>
        </Spin>
        <Spin spinning={loadingProofOfIncome || saveLoadingProofOfIncome}>
          <Upload
            id="business-user-income-upload"
            accept="image/png,image/jpeg,application/pdf"
            showUploadList={false}
            disable={loadingProofOfIncome}
            beforeUpload={() => false}
            onChange={({ file }) => uploadProofOfIncome({ variables: { file } })}
          >
            <Card
              className={!proofOfIncome ? 'upload-card' : 'upload-card upload-card-success'}
              hoverable
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <UploadOutlined style={{ fontSize: 30 }} />
                <div style={{ width: '80%' }}>
                  <h3>
                    Comprovante de renda
                  </h3>
                  Declaração Completa do Imposto de Renda + Recibo de entrega do Imposto de Renda
                </div>
                {proofOfIncome
                && (
                  <CheckCircleOutlined />
                )}
              </div>
            </Card>
          </Upload>
        </Spin>
        <Spin spinning={loadingProofOfAddress || saveLoadingProofOfAddress}>
          <Upload
            id="business-user-residence-upload"
            accept="image/png,image/jpeg,application/pdf"
            showUploadList={false}
            disable={loadingProofOfAddress}
            beforeUpload={() => false}
            onChange={({ file }) => uploadProofOfAddress({ variables: { file } })}
          >
            <Card
              className={!proofOfAddress ? 'upload-card' : 'upload-card upload-card-success'}
              hoverable
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <UploadOutlined style={{ fontSize: 30 }} />
                <div style={{ width: '80%' }}>
                  <h3>
                    Comprovante de residência
                  </h3>
                  {`Escolha uma das opções: Conta de luz, água, gás, telefone, internet, com no máximo 90 dias, contrato de locação do imóvel.
                  Enviar conta aberta, não aceitamos somente a capa`}
                </div>
                {proofOfAddress
                && (
                  <CheckCircleOutlined />
                )}
              </div>
            </Card>
          </Upload>
        </Spin>
        <Row gutter={12} className="fixed-footer">
          <Col span={10}>
            <Button
              block
              disabled={loading
                || saveLoadingIdCard || saveLoadingProofOfAddress || saveLoadingProofOfIncome}
              ghost
              type="primary"
              onClick={() => goBack()}
            >
              Voltar
            </Button>
          </Col>
          <Col span={14}>
            <Button
              id="business-user-uploads-continue"
              block
              disabled={loading
                || saveLoadingIdCard || saveLoadingProofOfAddress || saveLoadingProofOfIncome
                || !idCard || !proofOfAddress || !proofOfIncome}
              loading={loading
                || saveLoadingIdCard || saveLoadingProofOfAddress || saveLoadingProofOfIncome}
              onClick={() => {
                onCompleted();
              }}
              type="primary"
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

BusinessUserUploadForm.propTypes = {
  id: PropTypes.string,
  onCompleted: PropTypes.func,
  goBack: PropTypes.func,
};

export default memo(BusinessUserUploadForm);
