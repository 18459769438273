import { gql } from '@apollo/client';

const INTEGRATIONS = gql`
  query integrations(
    $businessId: ID
    $limit: Int
    $offset: Int
    $sort: [SortInput!]
  ) {
    integrations (
      businessId: $businessId
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      id
      type {
        id
        icon {
          id
          url
        }
        name
      }
    }
  }
`;

const ORDERS = gql`
  query orders (
    $limit: Int
    $offset: Int
    $startOrderDate: Date
    $endOrderDate: Date
    $businessId: ID
    $orderId: ID
  ) {
    orders(
      limit: $limit
      offset: $offset
      startOrderDate: $startOrderDate
      endOrderDate: $endOrderDate
      businessId: $businessId
      orderId: $orderId
    ) {
      id
      orderId
      date
      status
      amount
    }
  }
`;

const PAYMENTS = gql`
  query payments (
    $limit: Int
    $offset: Int
    $startPaymentDate: Date
    $endPaymentDate: Date
    $businessId: ID
    $paymentId: ID
  ) {
    payments(
      limit: $limit
      offset: $offset
      startPaymentDate: $startPaymentDate
      endPaymentDate: $endPaymentDate
      businessId: $businessId
      paymentId: $paymentId
    ) {
      id
      paymentId
      date
      amount
      balancedAmount
      status
    }
  }
`;

export {
  INTEGRATIONS,
  ORDERS,
  PAYMENTS,
};
