import Modal from 'antd/lib/modal/Modal';
import { Button } from 'components';
import React, {
  createContext, useContext, useState,
} from 'react';

import './confirm-modal-context.less';

const defaultCallback = () => {
  throw new Error('Você precisa chamar esse contexto dentro do provider!');
};

export const ConfirmModalContext = createContext({
  requestConfirmation: defaultCallback,
});

const ConfirmModalProvider = ({ children }) => {
  const initialState = {
    message: '',
    onOk: () => undefined,
    onCancel: () => undefined,
  };

  const [state, setState] = useState(initialState);
  const [visible, setVisible] = useState(false);

  const resetState = async () => {
    setVisible(false);

    await new Promise((res) => setTimeout(res, 500));

    setState(initialState);
  };

  const requestConfirmation = ({ onOk, onCancel, message }) => {
    setVisible(true);
    setState({
      message,
      onOk: () => {
        onOk?.();
        resetState();
      },
      onCancel: () => {
        onCancel?.();
        resetState();
      },
    });
  };

  return (
    <ConfirmModalContext.Provider
      value={{
        requestConfirmation,
      }}
    >
      <Modal
        visible={visible}
        closable={false}
        onCancel={state.onCancel}
        maskClosable={false}
        footer={(
          <>
            <Button
              link
              onClick={state.onCancel}
              className="context-confirm-modal-cancel-btn"
            >
              Cancelar
            </Button>

            <Button onClick={state.onOk}>
              Confirmar
            </Button>
          </>
          )}
      >
        <h3>Atenção</h3>
        <p>{state.message}</p>
      </Modal>

      {children}
    </ConfirmModalContext.Provider>
  );
};

export default ConfirmModalProvider;

/**
 * @returns {{
 *  requestConfirmation: ({
 *    message: any,
 *    onCancel: any,
 *    onOk: any,
 *  }) => any
 * }}
 */
export function useConfirmModalContext() {
  return useContext(ConfirmModalContext);
}
