import { gql } from '@apollo/client';

const EXPORT_INSTALLMENTS = gql`
  mutation exportInstallments(
    $installmentStatusesValues: [String]
    $loanId: ID
    $loanStatusesValues: [String]
    $loanNumber: Int
    $businessNumber: Int
    $portfoliosIds: [ID]
    $startDisbursementDate: Date
    $endDisbursementDate: Date
    $startDueDate: Date
    $endDueDate: Date
    $fileExtension: String
  ) {
    exportInstallments (
      installmentStatusesValues: $installmentStatusesValues
      loanId: $loanId
      loanStatusesValues: $loanStatusesValues
      loanNumber: $loanNumber
      businessNumber: $businessNumber
      portfoliosIds: $portfoliosIds
      startDisbursementDate: $startDisbursementDate
      endDisbursementDate: $endDisbursementDate
      startDueDate: $startDueDate
      endDueDate: $endDueDate
      fileExtension: $fileExtension
    )
  }
`;

export {
  EXPORT_INSTALLMENTS,
};
export default EXPORT_INSTALLMENTS;
