import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import qs from 'query-string';
import {
  Layout,
  Carousel,
  Row,
  Col,
  message,
  notification,
} from 'antd';
import {
  Progress,
  SubHeader,
  Button,
  CustomStep,
  Input,
} from 'components/gyramais';
import {
  SuccessMessage,
  ConfigLoanCard,
} from 'components';
import { formatError, formatString } from 'utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import useDynamicSteps from 'utils/hooks/useDynamicSteps';
import {
  SAVE_PARTNER_LOAN,
  BUSINESSES,
} from './graphql';

import './styles.less';

let carouselRef;

const { Content } = Layout;

const NewLoanScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search);

  const [cnpj, setCnpj] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState();
  const [amount, setAmount] = useState(10000);
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();

  /**
   * @typedef {"business-step-1" | "business-step-2" | "success" | "loan"} Steps
   *
   * @type {{
   *  getStepIndex: (stepName: Steps) => number
   *  getStepLength: () => number
   * }}
   */
  const { getStepIndex, getStepLength } = useDynamicSteps([
    { name: 'loan' },
    { name: 'success' },
  ]);

  const headerSteps = [
    {
      subheader: {
        title: 'Nos informe para qual cliente deseja solicitar e qual o empréstimo ideal para ele',
        subtitle: 'Informe o cliente e o valor que ele precisa para crescer e em quantas vezes ele gostaria de nos pagar de volta.',
      },
    },
  ];

  const isLastStep = currentStep === getStepLength() - 1;

  const handleSliderChange = (e) => {
    setAmount(e);
  };

  const [businesses] = useLazyQuery(BUSINESSES, {
    onError: (error) => {
      message.error(formatError(error));
    },
    onCompleted: ({ businesses }) => {
      const business = businesses[0];

      if (!business) {
        setCnpj('');
        setCustomerId();
        setCustomerName();

        if (params?.cnpj) {
          history.push('/clientes');
        }

        return notification.error({
          message: 'CNPJ não encontrado entre seus clientes',
          description: 'Por favor, utilize um CNPJ registrado como seu cliente ou cadastre o CNPJ inserido como seu cliente',
          duration: 20,
        });
      }

      setCustomerName(business?.name || business?.legalName || 'SEM NOME');
      setCustomerId(business?.id);
      return true;
    },
  });

  const [savePartnerLoan, { loading: loadingPartnerLoan }] = useMutation(SAVE_PARTNER_LOAN, {
    onCompleted: async () => {
      setCurrentStep(currentStep + 1);
      carouselRef.next();
    },
    onError: (error) => message.error(formatError(error), 2),
  });

  const steps = [
    {
      content: (
        <div className="new-loan-content">
          <Row align="middle" justify="center" gutter={[16, 24]}>
            <Col xs={24} sm={24} md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 1 }}>
              <Input
                disabled={params.cnpj}
                type="masked"
                className="ant-input"
                guide
                id="seller-cnpj-input"
                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                placeholder="CNPJ do cliente"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                onKeyUp={(e) => {
                  const cnpj = e.target.value.replace(/[^0-9]+/g, '');

                  if (cnpj.length === 14) {
                    businesses({ variables: { cnpj } });
                  }
                }}
              />
            </Col>
            <Col xs={0} sm={0} md={0} lg={1} />
            <Col xs={24} sm={24} md={24} lg={12}>
              <ConfigLoanCard
                title={`Configurar empréstimo${customerName ? ` para ${customerName}` : ''}`}
                className={customerId ? 'iterable' : 'blocked'}
                onChangeAmount={(e) => handleSliderChange(e)}
                onChangeInstallmentsCount={(e) => setSelectedOption(e)}
              />
            </Col>
          </Row>
        </div>
      ),
    },
    {
      content: (
        <div className="carousel-content">
          {isLastStep && (
            <SuccessMessage
              centered
              title="Sua solicitação foi concluída."
              subtitle="Fique atento! Em até 24 horas você e seu cliente receberão uma resposta por email ou SMS com os resultados da análise."
              primaryButton={{
                text: 'Ir para a página inicial',
                onClick: () => history.push('/'),
              }}
            />
          )}
        </div>
      ),
    },
  ];

  const handleNext = async () => {
    if (currentStep === getStepIndex('loan')) {
      if (!selectedOption) {
        message.error('Favor inserir prazo', 2);
        return;
      }

      savePartnerLoan({
        variables: {
          customerId,
          amount,
          installmentsCount: selectedOption,
        },
      });
    }
  };

  useEffect(() => {
    if (params?.cnpj) {
      setCnpj(formatString(params.cnpj, 'cnpj'));
      businesses({ variables: { cnpj: params.cnpj } });
    }
  }, []);

  return (
    <Layout id="new-loan-screen">
      {!isLastStep && (
        <SubHeader
          title={headerSteps[currentStep].subheader.title}
          subtitle={headerSteps[currentStep].subheader.subtitle}
        />
      )}

      {!isLastStep && (
        <Progress percent={(100 / getStepLength()) * (currentStep + 1)} />
      )}

      <Content>
        <Carousel
          ref={(c) => { carouselRef = c; }}
          dots={false}
          swipe={false}
          swipeToSlide={false}
          draggable={false}
          accessibility={false}
        >
          <CustomStep
            steps={steps}
            current={currentStep}
          />
        </Carousel>

        {!isLastStep && (
          <div className="carousel-footer">

            {!isLastStep && (
              <Button
                link
                onClick={() => {
                  if (params.cnpj) {
                    history.goBack();
                  } else {
                    history.push('/');
                  }
                }}
              >
                {'< Voltar'}
              </Button>
            )}

            <Button
              color="secondary"
              id="business-register-next-button"
              loading={loadingPartnerLoan}
              disabled={!customerId}
              onClick={() => {
                handleNext();
              }}
            >
              Confirmar e prosseguir
            </Button>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default NewLoanScreen;
