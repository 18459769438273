import {
  makeVar,
} from '@apollo/client';

const getMe = () => {
  try {
    return localStorage.getItem('me') !== undefined ? JSON.parse(localStorage.getItem('me')) : null;
  } catch (e) {
    localStorage.removeItem('me');
    return null;
  }
};

const getSandboxMe = () => (localStorage.getItem('sandboxMe') !== undefined ? JSON.parse(localStorage.getItem('sandboxMe')) : null);

const loanDrawerVar = { loanId: null, visible: false, __typename: 'LoanDrawer' };

const getLoggedOut = () => ({ loggedOut: null });

const meVar = makeVar(getMe());
const sandboxMeVar = makeVar(getSandboxMe());
const testModeVar = makeVar(true);
const loggedOutVar = makeVar(getLoggedOut());

export {
  loanDrawerVar,
  meVar,
  sandboxMeVar,
  testModeVar,
  loggedOutVar,
  getMe,
  getSandboxMe,
};
