import { gql } from '@apollo/client';

const GET_WEBHOOKS = gql`
  query webhooks {
    webhooks {
      id
      url
      events {
        id
        event {
          loan {
            id
            business {
              legalName
            }
          }
        }
        status {
          id
          name
          value
          colors
          step
        }
      }
    }
  }
`;

export {
  GET_WEBHOOKS,
};
export default GET_WEBHOOKS;
