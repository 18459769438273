import React, { useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Spin,
} from 'antd';
import { Waypoint } from 'react-waypoint';
import { useQuery } from '@apollo/client';
import {
  LoanCard,
  LoanFilterForm,
  LoanDrawer,
} from 'components';
import {
  Button,
  SubHeader,
} from 'components/gyramais';
import { LOANS } from './graphql';
import './styles.less';

const {
  Content,
} = Layout;

const limit = 10;

const LoansScreen = () => {
  const [loanDrawer, setLoanDrawer] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [variables, setVariables] = useState({ offset: 0, limit, sort: [{ descending: 'date' }] });
  const {
    data: { loans = [] } = {},
    loading,
    refetch,
    fetchMore,
    error,
  } = useQuery(LOANS, {
    variables: {
      ...variables,
      partner: true,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Layout className="dashboard-layout loan-screen">
      <SubHeader
        title="Empréstimos"
        buttons={(
          <Button onClick={() => setLoanDrawer(true)}>+ Empréstimo</Button>
        )}
        buttonsPlacement="right"
      />

      <Content>
        <LoanFilterForm
          onSearch={(values) => {
            setVariables({ ...variables, ...values });
            setLastPage(false);
          }}
        />
        <Row gutter={[0, 32]}>
          {loans.map((loan) => (
            <Col span={24}>
              <LoanCard key={loan.id} loan={loan} refetch={refetch} detailed />
            </Col>
          ))}
        </Row>
        {loans.length === 0 && !loading
          && (
            <p>
              Sem empréstimos solicitados
            </p>
          )}
        {loading && <Spin />}
        {!error && !loading && !lastPage
          && (
            <Waypoint
              onEnter={() => {
                fetchMore({
                  variables: {
                    ...variables,
                    offset: loans.length,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    if (fetchMoreResult.loans.length === 0) setLastPage(true);
                    return { ...prev, loans: [...prev.loans, ...fetchMoreResult.loans] };
                  },
                });
              }}
            />
          )}
      </Content>
      <LoanDrawer visible={loanDrawer} onClose={() => setLoanDrawer(false)} />
    </Layout>
  );
};

export default LoansScreen;
