import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Progress,
  Skeleton,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';
import './styles.less';

const LoanStatsCard = ({
  label,
  value,
  max,
  color,
  loading,
  onClick,
}) => (
  <Card
    className={`loan-stats-card ${onClick ? 'clickable' : ''}`}
    onClick={onClick}
  >
    <div className="loan-stats-card--text">
      <Skeleton paragraph={{ width: 40, rows: 1 }} title={false} loading={loading}>
        <span>
          <span className="loan-stats-card--text-quantity">{value}</span>

          <span className="loan-stats-card--text-total">
            {' '}
            /
          </span>
          <br />
          <span>
            {' '}
            {max}
          </span>
        </span>
      </Skeleton>
      {' '}
      <Skeleton paragraph={{ width: 40, rows: 1 }} title={false} loading={loading}>
        <span className="loan-stats-card--text-main">{label}</span>
      </Skeleton>
    </div>
    <Skeleton title={false} avatar={{ shape: 'square', size: 40 }} paragraph={false} loading={loading}>
      <Progress type="circle" percent={(value * 100) / max} width={50} strokeColor={color} format={() => <UserOutlined style={{ color: '#c8c8c8' }} />} />
    </Skeleton>
  </Card>
);

LoanStatsCard.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.number,
  max: PropTypes.number,
  loading: PropTypes.bool,
};

export default memo(LoanStatsCard);
