import { gql } from '@apollo/client';

const SAVE_SIGNATURE = gql`
  mutation saveSignature($id: ID!, $ip: String!, $verificationCode: String) {
    saveSignature(id: $id, ip: $ip, verificationCode: $verificationCode) {
      id
    }
  }
`;

export default SAVE_SIGNATURE;
export {
  SAVE_SIGNATURE,
};
