import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Collapse,
} from 'antd';
import {
  Loading3QuartersOutlined,
  FileOutlined,
  CheckOutlined,
  CloseOutlined,
  HourglassOutlined,
} from '@ant-design/icons';
import './styles.less';

const { Panel } = Collapse;

const handleIcon = ({ fileSync, onClickDispatch, hoverId }) => {
  const { error, endedAt, startedAt } = fileSync;

  if (hoverId === fileSync.id && endedAt) {
    return (
      <CloseOutlined
        className="close-icon feedback-icon"
        onClick={() => {
          onClickDispatch(fileSync.id);
        }}
      />
    );
  }

  if (endedAt) {
    if (error) {
      return (
        <CloseOutlined
          className="error-icon feedback-icon"
          onClick={() => {
            onClickDispatch(fileSync.id);
          }}
        />
      );
    }
    return (
      <CheckOutlined className="success-icon feedback-icon" />
    );
  }
  if (startedAt) {
    return (
      <Loading3QuartersOutlined spin className="loading-icon feedback-icon" />
    );
  }

  return (
    <HourglassOutlined className="queue-icon feedback-icon" />
  );
};

const ImportFeedbackCard = ({
  runningFileSyncs,
  onClickDispatch,
}) => {
  const [hoverId, setHoverId] = useState();

  return (
    <>
      <Card id="import-feedback-card">
        <Collapse
          accordion
          expandIconPosition="right"
          defaultActiveKey={['1']}
        >
          <Panel
            header={(
              <div className="collapse-panel-header">
                Realizando importações
              </div>
            )}
            key="1"
          >
            {runningFileSyncs.map((fileSync) => (
              <div
                key={fileSync?.id}
                className="import-feedback-line"
                onMouseEnter={() => setHoverId(fileSync.id)}
                onMouseLeave={() => setHoverId()}
              >
                <div className="import-feedback-line-file-infos">
                  <FileOutlined className="file-icon" />
                  {fileSync?.file?.name}
                </div>

                <div className="import-feedback-line-icon">
                  {!fileSync.endedAt
                  && fileSync.progress !== undefined
                  && fileSync.progress !== null && (
                    <span className="import-feedback-line-label">
                      {fileSync.progress.toFixed(2)}
                      %
                    </span>
                  )}
                  {handleIcon({ fileSync, hoverId, onClickDispatch })}
                </div>
              </div>
            ))}
          </Panel>
        </Collapse>
      </Card>
    </>
  );
};

ImportFeedbackCard.propTypes = {
  runningFileSyncs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClickDispatch: PropTypes.func.isRequired,
};

export default ImportFeedbackCard;
