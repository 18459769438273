import React, { useState } from 'react';
import {
  Card,
  message,
  notification,
  Modal,
  Input,
  Select,
  Row,
  Col,
} from 'antd';
import {
  Button,
  Information,
} from 'components/gyramais';
import './styles.less';

import { useMutation, useQuery } from '@apollo/client';
import { formatError } from 'utils';

import SET_PARTNER_AUTHORIZATION from './graphql/setPartnerAuthorization.gql';
import SET_CCB_ENDPOINTS from './graphql/setCcbEndpoints.gql';
import ME from './graphql/me.gql';

const { Option } = Select;

const BankerUrlConfig = () => {
  const [editUrlsModal, setEditUrlsModal] = useState(false);
  const [editAuthorizationModal, setEditAuthorizationModal] = useState(false);
  const [websitePrefix, setWebsitePrefix] = useState('https://');

  const [createCcbUrl, setCreateCcbUrl] = useState('');
  const [editCcbUrl, setEditCcbUrl] = useState('');
  const [signCcbUrl, setSignCcbUrl] = useState('');

  const [authorizationHeader, setAuthorizationHeader] = useState('');
  const [setCcbEndpoints, { loading: setCcbEndpointsLoading }] = useMutation(SET_CCB_ENDPOINTS, {
    onCompleted: () => {
      setEditUrlsModal(false);
      message.success('Webhook criado com sucesso');
    },
    onError: (error) => message.error(formatError(error)),
  });
  const [
    setPartnerAuthorization, { loading: setPartnerAuthorizationLoading },
  ] = useMutation(SET_PARTNER_AUTHORIZATION, {
    onCompleted: () => {
      setEditAuthorizationModal(false);
      message.success('Authorization salvo!');
    },
    onError: (error) => message.error(formatError(error)),
  });

  const removeProtocolFromUrl = (urlString) => {
    try {
      const url = new URL(urlString);

      return `${url.host}${url.pathname}`;
    } catch (e) {
      return '';
    }
  };

  const {
    data: {
      me: {
        currentBusiness: {
          id,
        } = {},
      } = {},
    } = {},
    loading: loadingMe,
  } = useQuery(ME, {
    onCompleted: ({ me: { currentBusiness } }) => {
      const {
        createCcbUrl,
        editCcbUrl,
        signCcbUrl,
      } = currentBusiness;

      setCreateCcbUrl(removeProtocolFromUrl(createCcbUrl));
      setEditCcbUrl(removeProtocolFromUrl(editCcbUrl));
      setSignCcbUrl(removeProtocolFromUrl(signCcbUrl));
    },
    fetchPolicy: 'cache-and-network',
  });

  const onSaveUrls = () => {
    const sanitizedUrls = [
      {
        name: 'Criação de CCB',
        url: createCcbUrl.trim().toLowerCase(),
      },
      {
        name: 'Edição de CCB',
        url: editCcbUrl.trim().toLowerCase(),
      },
      {
        name: 'Assinatura de CCB',
        url: signCcbUrl.trim().toLowerCase(),
      },
    ];

    let hasError = false;

    for (const { name, url } of sanitizedUrls) {
      if (url.length === 0) {
        notification.error({
          message: 'Erro ao salvar a URL',
          description: `Insira a url de ${name}`,
        });
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return setCcbEndpoints({
      variables: {
        createCcbUrl: `${websitePrefix}${createCcbUrl}`,
        editCcbUrl: `${websitePrefix}${editCcbUrl}`,
        signCcbUrl: `${websitePrefix}${signCcbUrl}`,
        partnerId: id,
      },
    });
  };

  return (
    <>
      <Card id="banker-url-card">
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col xs={24} sm={24} md={16}>
            <Information value="Endpoints para CCB" />
          </Col>
          <Col xs={24} sm={24} md={5}>
            <Button
              id="webhook-button"
              block
              onClick={() => { setEditUrlsModal(true); }}
              loading={setCcbEndpointsLoading || loadingMe}
              color="secondary"
            >
              Editar urls
            </Button>
          </Col>
        </Row>
      </Card>

      <Modal
        title="Editar Header"
        className="banker-authorization-modal"
        visible={editAuthorizationModal}
        centered
        onCancel={() => setEditAuthorizationModal(false)}
        footer={(
          <>
            <Button
              link
              onClick={() => { setEditAuthorizationModal(false); }}
            >
              Cancelar
            </Button>

            <Button
              loading={setPartnerAuthorizationLoading}
              onClick={() => {
                setPartnerAuthorization({
                  variables: {
                    authorization: authorizationHeader,
                    partnerId: id,
                  },
                });
              }}
            >
              Salvar
            </Button>
          </>
        )}
      >
        <p>
          Authorization
        </p>
        <Input
          placeholder="Authorization"
          value={authorizationHeader}
          onChange={(e) => setAuthorizationHeader(e.target.value)}
        />

        <p className="text">O Authorization não será exibido.</p>
      </Modal>

      <Modal
        visible={editUrlsModal}
        onCancel={() => setEditUrlsModal(false)}
        closable={false}
        title="Editar endpoints para ccb"
        className="banker-url-modal"
        centered
        footer={(
          <>
            <Button
              link
              onClick={() => {
                setEditUrlsModal(false);
              }}
            >
              Cancelar
            </Button>

            <Button
              id="confirm-webhook-button"
              loading={setCcbEndpointsLoading}
              onClick={onSaveUrls}
            >
              Salvar URLS
            </Button>
          </>
          )}
      >
        <div id="banker-url-modal-content">
          <Button
            color="link"
            onClick={() => setEditAuthorizationModal(true)}
          >
            Editar segredo na header
          </Button>

          <p>Endpoint para criar CCB</p>

          <Input
            id="webhook-url"
            placeholder="Endpoint para criar CCB"
            value={createCcbUrl}
            onChange={({ target: { value } }) => setCreateCcbUrl(value)}
            addonBefore={(
              <Select
                value={websitePrefix}
                onChange={(e) => setWebsitePrefix(e)}
              >
                <Option value="http://">http://</Option>
                <Option value="https://">https://</Option>
              </Select>
            )}
          />

          <p>Endpoint para editar CCB</p>
          <Input
            id="webhook-url"
            placeholder="Endpoint para editar CCB"
            value={editCcbUrl}
            onChange={({ target: { value } }) => setEditCcbUrl(value)}
            addonBefore={(
              <Select
                value={websitePrefix}
                onChange={(e) => setWebsitePrefix(e)}
              >
                <Option value="http://">http://</Option>
                <Option value="https://">https://</Option>
              </Select>
            )}
          />

          <p>Endpoint para assinar CCB</p>
          <Input
            id="webhook-url"
            placeholder="Endpoint para assinar CCB"
            value={signCcbUrl}
            onChange={({ target: { value } }) => setSignCcbUrl(value)}
            addonBefore={(
              <Select
                value={websitePrefix}
                onChange={(e) => setWebsitePrefix(e)}
              >
                <Option value="http://">http://</Option>
                <Option value="https://">https://</Option>
              </Select>
            )}
          />
        </div>
      </Modal>
    </>
  );
};

export default BankerUrlConfig;
