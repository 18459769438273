/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  Modal,
  Collapse,
} from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import {
  Button,
  Status,
  Information,
  Alert,
} from 'components/gyramais';
import {
  InstallmentRow,
} from 'components';
import ContractButton from './components/ContractButton';

import './styles.less';

const { Panel } = Collapse;

const LoanCard = ({
  loan: {
    number,
    status: {
      name: statusName,
      value: statusValue,
      parent,
    } = {},
    signatures = [],
    amount,
    finalAmount,
    totalAmount,
    installmentAmount,
    installmentsCount,
    finalInstallmentsCount,
    installments = [],
    interestRate,
    date,
    ccb,
    additive,
    bankerId,
    bankerValue,
  },
  refetch,
  index,
}) => {
  const [isCollapseClosed, setCollapse] = useState(true);
  const [signaturesModal, setSignaturesModal] = useState(false);

  const isInstallmentLate = (installment) => installment && installment.values.penalty.debitDays > 0 && installment.values.status.value.includes('late');
  const isInstallmentTheNextOne = (installment) => installment && installment.values.penalty.debitDays === 0 && installment.values.status.value === 'pending';
  const isInstallmentPaid = (installment) => installment && installment.values.status.value === 'paid';

  const lateInstallments = installments.filter(isInstallmentLate);
  const paidInstallments = installments.filter(isInstallmentPaid);
  const pendingInstallments = installments.filter(
    (installment) => !isInstallmentLate(installment) && !isInstallmentPaid(installment),
  );

  const nextPendingInstallment = installments.find(isInstallmentTheNextOne);

  const firstLateInstallment = lateInstallments[0];

  const nextInstallmentToPay = firstLateInstallment || nextPendingInstallment;

  const renderInstallment = (installment, showLineDivider) => {
    const isLate = isInstallmentLate(installment);

    return (
      <div key={installment.id}>
        <InstallmentRow
          installment={installment}
          last={showLineDivider}
          detailed
          refetch={refetch}
          late={isLate}
          withOptions={['active', 'active-renegotiated'].includes(statusValue)}
        />
      </div>
    );
  };

  const renderInstallmentList = (list) => (
    list.map(
      (installment, index) => renderInstallment(installment, index === list.length - 1),
    )
  );

  const showFinalValues = [
    'pre-approved',
    'pending',
    'registered',
    'awaiting-signature',
    'signed',
    'active',
    'pending-renegotiated',
    'active-renegotiated',
    'renegotiated',
    'awaiting-signature-additive',
    'awaiting-payment',
  ].includes(statusValue);

  const showFee = [
    'pending',
    'pre-approved',
    'registered',
    'awaiting-signature',
    'signed',
    'awaiting-signature-additive',
    'awaiting-payment',
    'active-renegotiated',
    'lawsuit',
    'completed',
    'active',
  ].includes(statusValue);

  const showContracts = [
    'awaiting-signature',
    'signed',
    'active',
    'awaiting-signature-additive',
    'active-renegotiated',
    'lawsuit',
  ].includes(statusValue);

  const showAllLoanInformation = [
    'awaiting-signature',
    'awaiting-payment',
    'signed',
    'active',
    'awaiting-signature-additive',
    'active-renegotiated',
    'lawsuit',
    'completed',
  ].includes(statusValue);

  const signatureStatus = [
    'awaiting-signature',
    'signed',
    'awaiting-signature-additive',
  ].includes(statusValue);

  const getStatusValue = () => {
    if (['active', 'active-renegotiated'].includes(statusValue) && firstLateInstallment) {
      return 'late';
    }

    return parent?.value || statusValue;
  };

  const getStatusName = () => {

    if (['active', 'active-renegotiated'].includes(statusValue) && firstLateInstallment) {
      return 'Em atraso';
    }

    return parent?.name || statusName;
  };

  return (
    <>
      <Card
        className={'loan-card \'detailed\''}
        id={`loan-card-${index}`}
      >
        <Row className="loan-card-header" align="middle" justify="space-between" gutter={[16, 16]}>
          <Col className="header-left-side">
            <div className="title-container">
              <h3 className="no-margin-bottom">
                Empréstimo #
                {number}
              </h3>
            </div>

            <Status
              value={getStatusValue()}
              name={getStatusName()}
            />
          </Col>

          {signatureStatus && (
            <Col>
              <Button
                link
                className="see-signatures-button"
                icon={(<EyeOutlined />)}
                onClick={() => setSignaturesModal(true)}
              >
                Visualizar assinaturas
              </Button>
            </Col>
          )}
        </Row>

        <br />

        <div className="loan-card-body">
          <Row align="middle" className="top-side info-row" justify="space-between" gutter={12}>
            {showAllLoanInformation ? (
              <Col xs={24} sm={24} md={24} lg={18}>
                <Row
                  gutter={[
                    20,
                    {
                      md: 30,
                      xs: 0,
                    },
                  ]}
                >
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <Information
                      label="Data"
                      value={`${date ? new Date(date).toLocaleDateString('pt-br') : '-'}`}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={6}>
                    <Information
                      label="Valor Total"
                      type={totalAmount ? 'currency' : null}
                      value={`${totalAmount || '-'}`}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={6}>
                    <Information
                      label="Valor Líquido"
                      type={(finalAmount || amount) ? 'currency' : null}
                      value={`${(showFinalValues && finalAmount) || amount || '-'}`}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={6}>
                    {showFee && (
                      <Information
                        label="Taxa de Juros"
                        type={interestRate ? 'percentage' : null}
                        value={`${interestRate || '-'}`}
                      />
                    )}
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={6}>
                    <Information
                      label="Valor da Parcela"
                      type={installmentAmount ? 'currency' : null}
                      value={`${installmentAmount || '-'}`}
                    />
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={6}>
                    <Information
                      label="Número de Parcelas"
                      value={`${finalInstallmentsCount || installmentsCount || '-'}`}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24} lg={20}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <Information
                      label="Valor"
                      type={(finalAmount || amount) ? 'currency' : null}
                      value={`${(showFinalValues && finalAmount) || amount || '-'}`}
                    />
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={4}>
                    <Information
                      size="large"
                      label="Prazo"
                      value={`${(
                        showFinalValues && finalInstallmentsCount)
                        || installmentsCount} meses
                    `}
                    />
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={4}>
                    {showFee && (
                      <Information
                        size="large"
                        label="Taxa de Juros"
                        type={interestRate ? 'percentage' : null}
                        value={`${interestRate || '-'}`}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            )}

            <Col xs={24} sm={24} md={24} lg={6}>
              <Row gutter={[16, 16]} className="buttons-area">
                {showContracts && (
                  <Col>
                    <ContractButton
                      statusValue={statusValue}
                      ccb={ccb}
                      bankerId={bankerId}
                      bankerValue={bankerValue}
                      additive={additive}
                    />
                  </Col>
                )}

                {/* {(!detailed) && ( */}
                <Col className="details-btn-col">
                  <Button
                    id="loan-details-button"
                    block
                    onClick={() => {
                      // history.push(`/emprestimos?id=${id}`);
                    }}
                  >
                    Ver detalhes
                  </Button>
                </Col>
                {/* )} */}

              </Row>
            </Col>
          </Row>

          {['active', 'active-renegotiated', 'awaiting-signature'].includes(statusValue)
            && installments[0]
            && (
              <>
                <hr className="section-divider" />

                {firstLateInstallment && (
                  <Alert
                    className="late-alert"
                    // eslint-disable-next-line max-len
                    message={`Seu cliente tem ${lateInstallments.length} parcela(s) em atraso. Regularize sua situação.`}
                    type="error"
                    color="red"
                  />
                )}

                <div className="bottom-side">
                  {' '}
                  {isCollapseClosed ? (
                    <>
                      {isCollapseClosed && (
                        <>
                          {
                            isInstallmentLate(nextInstallmentToPay)
                              ? (
                                <h5 className="no-margin-bottom">
                                  <b className="danger-text">
                                    Próxima parcela
                                  </b>
                                </h5>
                              ) : (
                                <h5 className="no-margin-bottom">
                                  <b className="secondary-text">
                                    Próxima parcela
                                  </b>
                                </h5>
                              )
                          }

                          {nextInstallmentToPay && (
                            <InstallmentRow
                              installment={nextInstallmentToPay}
                              last={isCollapseClosed}
                              refetch={refetch}
                              detailed
                              late={Boolean(firstLateInstallment)}
                              withOptions={['active', 'active-renegotiated'].includes(statusValue)}
                            />
                          )}
                        </>
                      )}

                      <Collapse activeKey={!isCollapseClosed ? 1 : undefined} ghost>
                        <Panel showArrow={false} key={1}>

                          {lateInstallments.length > 0 && (
                            <>
                              <h5 className="no-margin-bottom">
                                <b className="danger-text">
                                  Parcelas atrasadas
                                </b>
                              </h5>

                              {renderInstallmentList(lateInstallments)}
                              <br />
                            </>
                          )}

                          {paidInstallments.length > 0 && (
                            <>
                              <h5 className="no-margin-bottom">
                                <b className="secondary-text">
                                  Parcelas Pagas
                                </b>
                              </h5>

                              {renderInstallmentList(paidInstallments)}
                              <br />
                            </>
                          )}

                          {pendingInstallments.length > 0 && (
                            <>
                              <h5 className="no-margin-bottom">
                                <b className="secondary-text">
                                  Próximas parcelas
                                </b>
                              </h5>

                              {renderInstallmentList(pendingInstallments)}
                            </>
                          )}
                        </Panel>
                      </Collapse>

                      <hr />

                      <Button
                        onClick={() => setCollapse(!isCollapseClosed)}
                        link
                        className="collapse-installments-button"
                      >
                        {`${!isCollapseClosed ? 'Ocultar' : 'Ver'} todas as parcelas`}
                      </Button>
                    </>
                  ) : (
                    <>
                      {(firstLateInstallment || nextPendingInstallment) && (
                        <InstallmentRow
                          installment={firstLateInstallment || nextPendingInstallment}
                          last
                          refetch={refetch}
                          installmentsCount={finalInstallmentsCount}
                          late={Boolean(firstLateInstallment)}
                          withOptions={['active', 'active-renegotiated'].includes(statusValue)}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            )}
        </div>
      </Card>

      {signatureStatus && (
        <Modal
          visible={signaturesModal}
          centered
          onCancel={() => setSignaturesModal(false)}
          title={`Assinaturas do empréstimo #${number}`}
          footer={false}
        >
          <div className="show-signatures-area">
            <Row align="middle">
              {signatures.map(({ signedAt, user, id }, index) => (
                <Col span={24} className="signature-data" key={id}>
                  <Information
                    label={`Responsável ${index + 1}`}
                    value={user.fullName}
                    detailed
                  />
                  <Status
                    value={signedAt ? 'completed' : 'pending'}
                    name={signedAt ? 'ASSINATURA OK' : 'PENDENTE ASSINATURA'}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Modal>
      )}
    </>
  );
};

LoanCard.propTypes = {
  loan: PropTypes.shape(),
  index: PropTypes.number,
  refetch: PropTypes.func,
};

export default LoanCard;
