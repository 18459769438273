import { gql } from '@apollo/client';

const SAVE_WEBHOOK = gql`
  mutation saveWebhook(
    $id: ID
    $url: String!
  ){
    saveWebhook(
      id: $id
      url: $url
    ){
      id
      url
    }
  }
`;

const REMOVE_WEBHOOK = gql`
  mutation removeWebhook($id: ID!) {
    removeWebhook(id: $id) { id }
  }
`;

export {
  SAVE_WEBHOOK,
  REMOVE_WEBHOOK,
};
