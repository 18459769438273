import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  message,
  Col,
  Spin,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { BUSINESS_USER } from 'services/graphql/queries';
import { SAVE_BUSINESS_USER } from 'services/graphql/mutations';
import { Images } from 'assets';
import { formatError } from 'utils';

const { Item, useForm } = Form;
const { LogoGyraG } = Images;

const FullNameForm = ({
  id,
  onCompleted,
  goBack,
}) => {
  const [form] = useForm();
  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: () => onCompleted(),
    onError: (error) => message.error(formatError(error)),
  });
  const {
    data: { businessUser: { user: { email } = {} } = {} } = {},
    loading,
  } = useQuery(BUSINESS_USER, {
    skip: !id,
    variables: { id },
    partialRefetch: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ businessUser: { user: { fullName } } = {} } = {}) => {
      form.setFieldsValue({
        fullName,
      });
    },
  });
  const onFinish = async (values) => {
    await form.validateFields();
    saveBusinessUser({
      variables: {
        id,
        ...values,
      },
    });
  };
  return (
    <Spin
      indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
      spinning={loading}
      style={{ transform: 'translateX(-16px)' }}
    >
      {loading === false && (
      <Form
        form={form}
        onFinish={onFinish}
      >
        <h3 className="wl-text" style={{ marginBottom: 24 }}>
          {`Insira o nome completo de ${email}`}
        </h3>
        <Item name="fullName" rules={[{ required: true, message: 'Por favor preencha o nome completo.' }]}>
          <Input placeholder="Nome completo" />
        </Item>
        <Row gutter={12} className="fixed-footer">
          <Col span={10}>
            <Button
              block
              disabled={saveLoading}
              ghost
              type="primary"
              onClick={() => goBack()}
            >
              Voltar
            </Button>
          </Col>
          <Col span={14}>
            <Button
              block
              loading={saveLoading}
              type="primary"
              htmlType="submit"
              id="business-user-fullName-continue"
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
      )}
    </Spin>
  );
};

FullNameForm.propTypes = {
  id: PropTypes.string,
  onCompleted: PropTypes.func,
  goBack: PropTypes.func,
};

export default FullNameForm;
