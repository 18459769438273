import React, { useState } from 'react';
import {
  Row,
  Col,
  Tooltip,
  notification,
} from 'antd';
import {
  Information,
  Drawer,
} from 'components/gyramais';
import GyramaisSlider from 'components/gyramais/Slider';
import { formatError, formatString } from 'utils';
import './styles.less';
import { useMutation } from '@apollo/client';

import SAVE_LOAN from './graphql/saveLoan.gql';
import SAVE_LOAN_EVENT from './graphql/saveLoanEvent.gql';

const interestRateByPortfolio = {
  'Pronampe Mulher': '9,98%',
  'Pronampe Geral': '9,98%',
  'Pronampe Emergencial CP': '6%',
  'Pronampe Emergencial SE': '6%',
};

const PreApprovedDrawer = ({
  loanId,
  loanFinalAmount = 0,
  loanPortfolioName,
  visible,
  onClose,
  refetch,
}) => {
  const [chosenAmount, setChosenAmount] = useState(Math.min(loanFinalAmount, 150000));

  const [saveLoan, { loading: saveLoading }] = useMutation(SAVE_LOAN, {
    onError: (error) => notification.error(formatError(error)),
  });

  const [saveLoanEvent, { loading: saveLoanLoading }] = useMutation(SAVE_LOAN_EVENT, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => notification.error(formatError(error)),
  });

  const onSubmit = async () => {
    await saveLoan({
      variables: {
        id: loanId,
        paymentDay: 15,
      },
    });

    await saveLoanEvent({
      variables: {
        loanId,
        amount: chosenAmount,
        statusValue: 'pending',
        installmentsCount: 36,
      },
    });

    onClose();
  };

  const loading = saveLoading || saveLoanLoading;

  const handleClose = () => {
    if (loading) {
      return;
    }

    onClose();
  };

  return (
    <Drawer
      open={visible}
      onClose={handleClose}
      className="pre-approved-drawer"
      width="70%"
      primaryButton={{
        onClick: () => {
          onSubmit();
        },
      }}
    >
      <Row>
        <Information
          loading={loading}
          label="Total pré-aprovado"
          value={formatString(loanFinalAmount, 'currency')}
        />
      </Row>
      <Row>
        <Information
          loading={loading}
          label="Taxa"
          value={(
            <div>
              <Tooltip title="Variação diária da taxa Selic">
                Selic* +
                {' '}
                {interestRateByPortfolio[loanPortfolioName]}
                {' '}
                a.a.
              </Tooltip>
            </div>
          )}
        />
      </Row>
      <Row>
        <Information
          loading={loading}
          label="Parcelas"
          value="4 trimestrais de carência e 36 mensais de amortização"
        />
      </Row>
      <Row>
        <Information
          loading={loading}
          label="Vencimento"
          value="Todo dia 15"
        />

      </Row>

      <Row gutter={[0, 30]}>
        <Col span={24}>
          <h6 className="no-margin-bottom">
            <b className="secondary-text">
              Valor solicitado
            </b>
          </h6>
        </Col>

        <Col span={24}>

          <GyramaisSlider
            tooltipVisible={false}
            min={20000}
            max={Math.min(loanFinalAmount, 150000)}
            value={chosenAmount}
            onChange={(e) => setChosenAmount(e)}
            step={1000}
            showValues
            valuesFormatter={(e) => formatString(e, 'currency')}
            input={{ type: 'currency' }}
            loading={loading}
          />
        </Col>

      </Row>
    </Drawer>
  );
};

export default PreApprovedDrawer;
