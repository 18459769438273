import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Carousel,
  notification,
} from 'antd';
import { SuccessMessage } from 'components';
import {
  Drawer,
  CustomStep,
  Input,
} from 'components/gyramais';
import { CPFValidator, formatError } from 'utils';
import { useMutation } from '@apollo/client';
import {
  SAVE_PARTNER_USER,
  REMOVE_PARTNER_USER,
} from './graphql';

import './styles.less';

const { Item } = Form;
const { useForm } = Form;
let carouselRef;

const CreateUserDrawer = ({
  user,
  businessId,
  visible,
  refetch,
  onClose,
  onClickAddNewUser,
}) => {
  const [form] = useForm();
  const [current, setCurrent] = useState(0);
  const [fullName, setFullName] = useState('');

  const handleClose = () => {
    onClose();
    form.resetFields();
    setFullName('');
    setCurrent(0);
  };

  const [savePartnerUser, { loading }] = useMutation(SAVE_PARTNER_USER, {
    onCompleted: () => {
      setCurrent(1);
      refetch();
    },
    onError: (error) => notification.error({ message: formatError(error) }),
  });

  const [removePartnerUser, { loading: removeLoading }] = useMutation(REMOVE_PARTNER_USER, {
    onCompleted: () => {
      handleClose();
      notification.success({ message: 'Usuário removido com sucesso' });
      refetch();
    },
    onError: (error) => notification.error({ message: formatError(error) }),
  });

  useEffect(() => {
    if (carouselRef) {
      setTimeout(() => {
        carouselRef.goTo(current);
      }, 200);
    }
  }, [current]);

  useEffect(() => {
    if (!user) {
      return;
    }

    form.setFieldsValue({
      ...user,
      presetValue: user.roles?.find((role) => role.name.startsWith('preset-'))?.name,
    });

    setFullName(user?.fullName);
  }, [user]);

  const handleConfirmBtn = async () => {
    await form.validateFields();
    let values = form.getFieldsValue();

    if (!values.presetValue) {
      form.setFieldsValue({ ...values });
      values = form.getFieldsValue();
    }

    if (values.cpf.includes('_')) {
      return notification.error({
        message: 'CPF inválido',
        description: 'Insira um CPF válido para continuar',
      });
    }

    setFullName(values?.fullName);

    await savePartnerUser({
      variables: {
        ...values,
        businessId,
        id: user?.id,
      },
    });
    return true;
  };

  const steps = [
    {
      content: (
        <div className="carousel-content">
          <Form
            className="form-footer"
            form={form}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <Item name="fullName" rules={[{ required: true, message: 'Insira o nome completo' }]}>
                  <Input placeholder="Nome completo" />
                </Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Item name="cpf" rules={[{ required: true, message: 'Insira o CPF' }]}>
                  <Input
                    type="masked"
                    guide
                    disabled
                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                    placeholder="CPF"
                    onChange={(e) => {
                      if (e.target.value.replace(/\D/g, '').length === 11) {
                        if (!CPFValidator(e.target.value.replace(/\D/g, ''))) {
                          notification.error({
                            message: 'CPF inválido',
                            description: 'Insira um CPF válido para continuar',
                          });
                          form.setFieldsValue({ cpf: '' });
                        }
                      }
                    }}
                  />
                </Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Item name="email" rules={[{ required: true, message: 'Insira o email' }]}>
                  <Input placeholder="Email" disabled={Boolean(user?.id)} />
                </Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Item name="phoneNumber" rules={[{ required: true, message: 'Insira o telefone' }]}>
                  <Input
                    type="masked"
                    mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    placeholder="Telefone celular"
                  />
                </Item>
              </Col>

            </Row>
          </Form>
        </div>
      ),
    },
    {
      content: (
        <div className="carousel-content">
          <SuccessMessage
            centered
            title={`Usuário ${fullName} foi ${user?.id ? 'editado' : 'adicionado'}.`}
            subtitle={!user?.id && (
              <>
                Enviaremos o email de criação de senha
                {' '}
                <span className="highlight-text">
                  para a caixa de entrada do usuário.
                </span>
              </>
            )}
            primaryButton={{
              text: 'Adicionar outro usuário',
              id: 'add-partner-user-drawer-button',
              onClick: () => {
                onClickAddNewUser();
                setCurrent(0);
              },
            }}
            secondaryButton={{
              text: 'Ir para a página de configurações',
              id: 'exit-partner-user-drawer-button',
              onClick: handleClose,
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <Drawer
      visible={visible}
      width="90%"
      closable={current === 0}
      onClose={handleClose}
      title={current === 0 && (user?.id ? 'Editar usuário' : 'Adicionar usuário')}
      primaryButton={{
        children: user?.id ? 'Salvar alterações' : 'Confirmar e adicionar',
        id: 'confirm-add-new-user',
        loading,
        disabled: removeLoading,
        onClick: handleConfirmBtn,
        visible: current === 0,
      }}
      backButton={{
        children: 'Remover usuário',
        id: 'remove-partner-user',
        loading: removeLoading,
        disabled: loading,
        onClick: async () => {
          await removePartnerUser({
            variables: {
              id: user.id,
            },
          });
        },
        visible: current === 0 && !!user?.id,
      }}
    >
      <Carousel
        ref={(c) => { carouselRef = c; }}
        dots={false}
        swipeToSlide={false}
        swipe={false}
        draggable={false}
        accessibility={false}
      >
        <CustomStep steps={steps} current={current} />
      </Carousel>
    </Drawer>
  );
};

CreateUserDrawer.propTypes = {
  businessId: PropTypes.string,
  onClose: PropTypes.func,
  refetch: PropTypes.func,
  onClickAddNewUser: PropTypes.func,
  visible: PropTypes.bool,
  user: PropTypes.shape(),
};

export default CreateUserDrawer;
