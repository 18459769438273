// @ts-check

import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { UPLOAD_FILE } from 'services/graphql/mutations';
import { formatError } from 'utils';
import { RUNNING_FILE_SYNCS } from 'components/gyramais/Header/graphql';
import { IMPORT_DATA } from './graphql';

/**
 * @param {{
 *  fileType: "import-business" | "import-order" | "import-payment" | "import-product"
 *  onImportRequestError?: () => void
 *  onLoading?: () => void
 *  onValidate?: () => void
 * }} args
 * @returns
 */
const useImportFile = ({
  fileType,
  onLoading,
  onValidate,
  onImportRequestError,
}) => {
  const [uploadFile, { loading: loadingUploadFile }] = useMutation(UPLOAD_FILE, {
    onError: (error) => {
      notification.error({
        message: formatError(error),
        duration: 0,
      });
      onImportRequestError?.();
    },
  });

  const [importData] = useMutation(IMPORT_DATA, {
    refetchQueries: [{ query: RUNNING_FILE_SYNCS }],
    onCompleted: () => onValidate(),
    onError: async (error) => {
      notification.error({
        message: error.message,
        duration: 0,
      });
      onImportRequestError?.();
    },
  });

  const processFileUpload = async ({ file }) => {
    onLoading?.();

    const uploadResponse = await uploadFile({
      variables: {
        file,
      },
    });

    if (!uploadResponse || !uploadResponse.data) {
      return;
    }

    await importData({
      variables: {
        fileId: uploadResponse?.data?.uploadFile?.id,
        fileType,
      },
    });
  };

  return {
    processFileUpload,
    loadingUploadFile,
  };
};

export default useImportFile;
