import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'components/gyramais';
import UploadCard from 'components/cards/UploadCard';
import {
  Col,
  Select,
  Form,
  message,
  Checkbox,
} from 'antd';
import { useMutation } from '@apollo/client';
import { formatError } from 'graphql';
import { SAVE_LOAN_EVENT } from 'components/drawers/RevenueStatementDrawer/graphql';
import { SIGNATURE_TYPE, SAVE_CCB_LOAN } from './graphql';

const CcbModal = ({
  visible,
  onClose,
  loanId,
  refetch,
  loading,
}) => {
  const { Item } = Form;
  const { Option } = Select;

  const [accepted, setAccepted] = useState(false);
  const [signatureTypeSelect, setSignatureTypeSelect] = useState();
  const [ccbFileSignature, setCcbFileSignature] = useState();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [uploadKey, setUploadKey] = useState(Date.now());

  const [signatureType] = useMutation(SIGNATURE_TYPE, {
    onCompleted: () => { },
    onError: (error) => message.error(formatError(error)),
  });

  const [saveLoanEvent] = useMutation(SAVE_LOAN_EVENT, {
    onCompleted: () => {
      message.success('Empréstimo salvo com sucesso!');
      refetch();
    },
    onError: (error) => message.error(formatError(error)),
  });

  const [saveCcbLoan] = useMutation(SAVE_CCB_LOAN, {
    onCompleted: () => { },
    onError: (error) => message.error(formatError(error)),
  });

  useEffect(() => {
    setUploadKey(Date.now());
  }, [signatureTypeSelect]);

  return (
    <>
      <Modal
        style={{ whiteSpace: 'pre-line' }}
        title="Adicionar documento CCB"
        centered
        visible={visible}
        onCancel={onClose}
        okButton={{
          visible: true,
          text: 'Salvar',
          onClick: () => setModalConfirm(true),
          disabled: !(accepted && signatureTypeSelect && ccbFileSignature),
        }}
      >
        <Col xs={24} sm={24} md={24}>
          <Item name="signatureType">
            <Select
              className="gyramais-input"
              placeholder="Escolha o tipo da assinatura"
              onChange={(e) => {
                setSignatureTypeSelect(e);
                setCcbFileSignature(false);
                setUploadKey(Date.now());
              }}
            >
              <Option value="PhysicsWithNotarization">Física com reconhecimento em cartório</Option>
              <Option value="physicalSignature">Somente física</Option>
              <Option value="gov">GOV</Option>
            </Select>
          </Item>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Item name="uploadCcbDocumentId">
            <UploadCard
              key={uploadKey}
              id="uploadCcbDocumentId"
              accept="application/pdf, .pdf"
              loading={loading}
              label="Documento CCB"
              validateSignature={signatureTypeSelect === 'gov'}
              onCompleted={async ({ id: fileId }) => {
                setCcbFileSignature(fileId);
              }}
              loanId={loanId}
            />
          </Item>
        </Col>

        <Item name="acceptTermCcbDocument">
          <Checkbox
            checked={accepted}
            id="ccb-document-terms"
            onChange={({ target: { checked } }) => setAccepted(checked)}
          >
            <p>
              <b>
                Confirmo que o PDF está assinado por todas as partes do contrato
              </b>
            </p>
          </Checkbox>
        </Item>
      </Modal>

      {modalConfirm && (
        <Modal
          style={{ width: '100px' }}
          title="Confirma o envio da CCB assinada?"
          centered
          visible={modalConfirm}
          okButton={{
            visible: true,
            text: 'Confirmar',
            onClick: () => {
              signatureType({
                variables: {
                  loanId,
                  typeValue: signatureTypeSelect,
                },
              });

              saveCcbLoan({
                variables: {
                  id: loanId,
                  ccbFileId: ccbFileSignature,
                },
              });

              saveLoanEvent({
                variables: {
                  loanId,
                  statusValue: 'signed',
                },
              });

              setModalConfirm(false);
              onClose();
            },
          }}
          onClose={() => setModalConfirm(false)}
          cancelButton={{
            visible: true,
            text: 'Cancelar',
            color: 'danger',
            onClick: () => setModalConfirm(false),
          }}
        >
          <></>
        </Modal>
      )}
    </>
  );
};

CcbModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(CcbModal);
