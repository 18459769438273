/* eslint-disable react/prop-types */
import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Layout,
  Table,
} from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import {
  InstallmentFilterForm,
} from 'components';
import {
  Button,
  SubHeader,
  Status,
} from 'components/gyramais';
import {
  formatDate,
  formatCurrency,
} from 'utils';
import { Waypoint } from 'react-waypoint';
import { useQuery } from '@apollo/client';
import { INSTALLMENTS } from './graphql';
import './styles.less';

const {
  Content,
} = Layout;

const limit = 20;

const InstallmentsScreen = () => {
  const history = useHistory();
  const [lastPage, setLastPage] = useState(false);
  const [variables, setVariables] = useState({ loanStatusesValues: ['active'], offset: 0, limit });

  const {
    data: { installments = [] } = {}, loading, fetchMore, error,
  } = useQuery(INSTALLMENTS, {
    variables: { ...variables, partner: true },
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Layout id="installment-screen">
      <SubHeader
        title="Parcelas"
      />
      <Content>
        <InstallmentFilterForm
          onSearch={(values) => {
            setVariables({ ...variables, ...values });
            setLastPage(false);
          }}
        />
        {installments.length === 0 ? (
          <p>
            Sem parcelas geradas
          </p>
        ) : (
          <>
            <br />
            <Table
              size="small"
              pagination={false}
              scroll={{ x: 'max-content' }}
              dataSource={installments}
              columns={[
                {
                  title: 'Vencimento',
                  dataIndex: 'dueDate',
                  key: 'createdAt',
                  width: 160,
                  render: (text) => <span>{formatDate(new Date(text))}</span>,
                },
                {
                  title: 'Cliente',
                  dataIndex: 'loan',
                  key: 'loan',
                  width: 160,
                  render: ({ business: { name } = {} }) => name,
                },
                {
                  title: 'Valor',
                  dataIndex: 'totalAmount',
                  key: 'totalAmount',
                  width: 160,
                  render: (text) => (
                    <div className="currency-container">
                      {formatCurrency(text)}
                    </div>
                  ),
                },
                {
                  title: 'Pago',
                  dataIndex: 'paidAmount',
                  key: 'paidAmount',
                  width: 160,
                  render: (text) => (
                    <div className="currency-container">
                      {formatCurrency(text)}
                    </div>
                  ),
                },
                {
                  title: 'Juros/Multa',
                  dataIndex: 'values',
                  key: 'penaltyAmount',
                  width: 160,
                  render: (values) => (
                    <div className="currency-container">
                      {formatCurrency(values.penalty.amount)}
                    </div>
                  ),
                },
                {
                  title: 'Saldo',
                  dataIndex: 'values',
                  key: 'balanceAmount',
                  width: 140,
                  render: (values) => (
                    <div className="currency-container">
                      {formatCurrency(values.balanceAmount)}
                    </div>
                  ),
                },
                {
                  title: 'Dias em atraso',
                  dataIndex: 'values',
                  key: 'debitDays',
                  align: 'center',
                  width: 140,
                  render: (values) => values.penalty.debitDays,
                },
                {
                  title: 'Status',
                  dataIndex: 'values',
                  key: 'status',
                  align: 'center',
                  width: 160,
                  render: (values) => (
                    <div>
                      <Status name={values.status.name} value={values.status.value} />
                    </div>
                  ),
                },
                {
                  dataIndex: 'loan',
                  key: 'action',
                  fixed: 'right',
                  render: ({ id }) => (
                    <div className="action-table-button">
                      <Button
                        link
                        icon={(<EyeOutlined />)}
                        id="edit-partner-user-button"
                        onClick={() => history.push(`/emprestimos?id=${id}`)}
                      >
                        Detalhes
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
            {!error && loading === false && lastPage === false
            && (
            <Waypoint
              onEnter={() => {
                fetchMore({
                  variables: {
                    ...variables,
                    offset: installments.length,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    if (fetchMoreResult.installments.length === 0) setLastPage(true);
                    return {
                      ...prev,
                      installments: [...prev.installments, ...fetchMoreResult.installments],
                    };
                  },
                });
              }}
            />
            )}
          </>
        )}
      </Content>
    </Layout>
  );
};

export default memo(InstallmentsScreen);
