import React, { useState } from 'react';
import {
  Card,
  message,
  Modal,
  Row,
  Col,
  Divider,
} from 'antd';
import {
  Button,
  Information,
} from 'components/gyramais';
import {
  CopyOutlined,
} from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { formatError } from 'utils';
import { Images } from 'assets';
import { GET_API_USERS, CREATE_API_USER, REMOVE_API_USER } from './graphql';

import './styles.less';

const { SuccessHangLoose } = Images;

const ApiUserCard = () => {
  const [apiUser, setApiUser] = useState(false);
  const [deleteApi, setDeleteApi] = useState(false);
  const {
    data: {
      apiUsers,
    } = {},
    refetch,
  } = useQuery(GET_API_USERS);
  const [createApiUser, { loading }] = useMutation(CREATE_API_USER, {
    onCompleted: ({ createApiUser: { apiKey, apiSecret } }) => {
      setApiUser({ apiKey, apiSecret });
      refetch();
      message.success('Credenciais criadas com sucesso!');
    },
    onError: (error) => message.error(formatError(error)),
  });
  const [removeApiUser] = useMutation(REMOVE_API_USER, {
    onCompleted: () => {
      refetch();
      setDeleteApi(false);
      message.success('Credencial removida com sucesso');
    },
    onError: (error) => message.error(formatError(error)),
  });

  // TODO: MOVER TODOS OS MODAIS DO SISTEMA PARA UMA PASTA MODAIS
  return (
    <>
      <Card id="api-card">
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col xs={24} sm={24} md={16}>
            <Information value="Credenciais da API" />
          </Col>
          <Col xs={24} sm={24} md={5}>
            <Button
              id="create-credential-button"
              onClick={() => createApiUser()}
              loading={loading}
              color="secondary"
              block
            >
              Novas credenciais
            </Button>
          </Col>
        </Row>
        {apiUsers?.[0] && (
          <>
            <Divider />
            <Row>
              {apiUsers.map(({ id, apiKey }, index) => (
                <Col span={24} key={id}>
                  <Row gutter={[16, 16]} align="middle" justify="space-between">
                    <Col xs={24} sm={24} md={16}>
                      <Information
                        id
                        className="api-user-card"
                        label={`API Key ${index + 1}`}
                        value={apiKey}
                        detailed
                        valueSuffix={(
                          <Button
                            className="api-key-link"
                            onClick={() => {
                              navigator.clipboard.writeText(apiKey);
                              message.success('API Key copiada com sucesso');
                            }}
                            link
                            icon={(<CopyOutlined />)}
                          >
                            Copiar API Key
                          </Button>
                        )}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={5}>
                      <Button
                        id="delete-credential-desktop"
                        onClick={() => {
                          setDeleteApi(id);
                        }}
                        block
                        color="white"
                      >
                        Excluir credencial
                      </Button>

                    </Col>

                  </Row>
                  {index !== apiUsers.length - 1 && (
                    <Divider className="api-user-divider" />
                  )}
                </Col>
              ))}
            </Row>
          </>
        )}
      </Card>

      <Modal
        className="api-modal"
        visible={apiUser}
        closable={false}
        centered
        title="Guarde bem suas credenciais! O API Secret não será mais exibido."
        footer={(
          <Button
            id="dispatch-credential-modal"
            onClick={() => setApiUser()}
          >
            Concluir e fechar
          </Button>
        )}
      >
        <div>
          <img src={SuccessHangLoose} alt="sucesso ao criar api key" className="api-creation-image" />
        </div>

        {apiUser && (
          <div className="api-user-creation-infos">
            <Information
              label="API Key"
              value={apiUser.apiKey}
              detailed
              valueSuffix={(
                <Button
                  className="api-key-link"
                  onClick={() => {
                    navigator.clipboard.writeText(apiUser.apiKey);
                    message.success('API Key copiada com sucesso');
                  }}
                  link
                  icon={(<CopyOutlined />)}
                >
                  Copiar API Key
                </Button>
              )}
            />
            <br />
            <Information
              label="API Secret"
              value={apiUser.apiSecret}
              detailed
              valueSuffix={(
                <Button
                  className="api-key-link"
                  onClick={() => {
                    navigator.clipboard.writeText(apiUser.apiSecret);
                    message.success('API Key copiada com sucesso');
                  }}
                  link
                  icon={(<CopyOutlined />)}
                >
                  Copiar API Key
                </Button>
              )}
            />
          </div>
        )}
      </Modal>

      <Modal
        closable={false}
        className="api-modal"
        title="Remover credencial"
        centered
        visible={!!deleteApi}
        footer={(
          <>
            <Button
              link
              onClick={() => setDeleteApi()}
            >
              Cancelar
            </Button>
            <Button
              id="confirm-delete-credential"
              onClick={() => removeApiUser({ variables: { id: deleteApi } })}
            >
              Continuar
            </Button>
          </>
        )}
      >
        <div className="remove-api-content">
          <h4>
            Deseja realmente remover esta credencial?
          </h4>
          <h6>
            Todas as integrações que utilizam esta credencial
            {' '}
            <b className="secondary-text">
              pararão de funcionar
            </b>
            .
          </h6>
        </div>
      </Modal>
    </>
  );
};

export default ApiUserCard;
