import {
  ApolloClient,
  InMemoryCache,
  split,
  gql,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client/core';
import {
  meVar,
  sandboxMeVar,
  testModeVar,
  loanDrawerVar,
  loggedOutVar,
  getMe,
  getSandboxMe,
} from './cache';

const typeDefs = gql`
  input SortInput {
    asceding: String
    descending:  String
  }

  input Session {
    id: String
    sessionToken: String
    firstName: String
  }
  
  extend type Mutation {
    addSession: Boolean
    removeSession: Boolean
    setTestMode: Boolean
    changeEnvironment: String
  }
`;

const resolvers = {
  Mutation: {
    setLoanDrawer: (_, { visible, loanId }) => loanDrawerVar({ loanId: loanId || null, visible, __typename: 'LoanDrawer' }),
    addSession: async (parent, {
      me,
    }) => {
      localStorage.setItem('me', JSON.stringify(me));
      meVar(me);

      // eslint-disable-next-line no-use-before-define
      wsLink?.subscriptionClient?.close(false, false);
      return true;
    },
    removeSession: async () => {
      localStorage.removeItem('me');
      localStorage.removeItem('sandboxMe');
      localStorage.removeItem('environment');
      loggedOutVar(true);
      return true;
    },
  },
};

const cache = new InMemoryCache({
  possibleTypes: {
    Business: ['Customer', 'Partner', 'Seller'],
    CustomerOrSeller: ['Customer', 'Seller'],
  },
  typePolicies: {
    Query: {
      fields: {
        currentSession: {
          read() {
            return meVar();
          },
        },
        environment: {
          read() {
            return 'production';
          },
        },
        loanDrawer: {
          read() {
            return loanDrawerVar();
          },
        },
        testMode: {
          read() {
            return testModeVar();
          },
        },
        loggedOut: {
          read() {
            return loggedOutVar();
          },
        },
      },
    },
  },
});

const getSessionToken = (environment) => {
  let sessionToken;

  if (environment === 'production') {
    sessionToken = getMe()?.sessionToken;
  } else {
    sessionToken = getSandboxMe()?.sessionToken;
  }

  return sessionToken;
};

let wsLink;

const logLink = new ApolloLink((operation, forward) => {
  const { operationName, variables } = operation;

  return (
    forward(operation).map((result) => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.info({
          operationName,
          variables,
          responseData: result?.data[operationName],
          errors: result?.errors,
        });
      }
      return result;
    }));
});

/**
 *
 * @param {'production' | 'sandbox'} environment
 * @returns {ApolloClient}
 */
const setupClient = (environment) => {
  const httpLink = createUploadLink({
    uri: environment === 'production'
      ? process.env.GRAPHQL_URI
      : process.env.GRAPHQL_SANDBOX_URI,
  });

  const authLink = setContext((_, { headers }) => {
    const user = environment === 'production' ? meVar() : sandboxMeVar();
    return {
      headers: {
        ...headers,
        authorization: (user && user.sessionToken) || null,
        // bypassing validation tunnel security reminder
        ...(
          process.env.TUNNEL_VALIDATION
            ? { 'Bypass-Tunnel-Reminder': 1 }
            : undefined
        ),
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => {
        if (message === 'Token de acesso inválido.') {
          localStorage.removeItem('me');
          localStorage.removeItem('sandboxMe');
          localStorage.removeItem('environment');
          meVar();
          sandboxMeVar();
          window.location.reload();
        }
        return null;
      });
    }
  });

  wsLink = new WebSocketLink({
    uri: environment === 'production'
      ? process.env.GRAPHQL_WEBSOCKET_URI
      : process.env.GRAPHQL_SANDBOX_WEBSOCKET_URI,

    options: {
      reconnect: true,
      connectionParams: () => ({
        authorization: getSessionToken(environment),
      }),
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsLink,
    logLink.concat(httpLink),
  );

  const client = new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: errorLink.concat(authLink.concat(splitLink)),
    cache,
    resolvers,
    typeDefs,
  });

  return client;
};

export default setupClient('production');
