/* eslint-disable react/prop-types */
import React from 'react';
import {
  Alert,
} from 'antd';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import './styles.less';

/**
 * @type {React.FC<import('antd/lib/alert').AlertProps & {
 *  color?: 'red' | 'green'
 * }}
 */
const GyramaisAlert = ({
  className,
  showIcon = true,
  icon,
  color,
  type = 'info',
  ...props
}) => (
  <Alert
    className={`
      gyramais-alert 
      ${className || ''}
      ${color ? (`color-${color}`) : ''}
    `}
    type={type}
    showIcon={showIcon}
    icon={icon || <ExclamationCircleOutlined className="gyramais-alert-icon" />}
    {...props}
  />
);

export default GyramaisAlert;
