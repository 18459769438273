import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  DatePicker,
  Col,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Item } = Form;

const PaymentsFilterForm = ({
  onSearch,
}) => (
  <Form
    layout="vertical"
    onFinish={(values) => {
      const {
        paymentId,
        startPaymentDate,
        endPaymentDate,
      } = values;
      onSearch({
        paymentId,
        startPaymentDate,
        endPaymentDate,
      });
    }}
  >
    <Row gutter={12} align="middle">
      <Col xs={24} sm={24} md={10} lg={9} xl={8}>
        <Item label="ID DO PAGAMENTO" name="paymentId">
          <Input placeholder="ID" />
        </Item>
      </Col>
      <Col xs={24} sm={24} md={10} lg={15} xl={10} style={{ display: 'flex', alignItems: 'center' }}>
        <Item label="DATA DO PAGAMENTO" name="startPaymentDate" style={{ marginRight: 10 }}>
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="Início"
          />
        </Item>
        <Item label=" " name="endPaymentDate">
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="Fim"
          />
        </Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 2, offset: 2 }} style={{ textAlign: 'right' }}>
        <Button
          id="payments-filter-button"
          type="primary"
          icon={<SearchOutlined />}
          htmlType="submit"
        >
          Pesquisar
        </Button>
      </Col>
    </Row>
  </Form>
);

PaymentsFilterForm.propTypes = {
  onSearch: PropTypes.func,
};

export default memo(PaymentsFilterForm);
