import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import Routes from 'routes';
import {
  Header,
} from 'components';
import ActionProvider from 'contexts/ActionContext';
import ConfirmModalProvider from 'contexts/ConfirmModalContext/confirm-modal-context';
import { notification } from 'antd';

const App = () => {

  useEffect(() => {
    notification.config({
      duration: 10,
      placement: 'topRight',
    });
  }, []);

  return (
    <Router>
      <ConfirmModalProvider>
        <ActionProvider>
          <Header />
          <ScrollMemory />
          <div>
            <Routes />
          </div>
        </ActionProvider>
      </ConfirmModalProvider>
    </Router>
  );
};
export default App;
