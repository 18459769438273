import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
} from 'antd';
import { StatusTag } from 'components';

const SignatureCard = ({
  signature: {
    user,
    signedAt,
  } = {},
  style,
}) => (
  <Card
    style={style}
    bodyStyle={{ padding: 10 }}
  >
    <StatusTag title={signedAt ? 'Assinado' : 'Pendente'} backgroundColor={signedAt ? '#CADB2C' : '#6D6F72'} />
    {user.fullName && user.fullName.toUpperCase()}
    <Row>
      <b>{user.email}</b>
    </Row>
    <Row>
      <b>{user.phoneNumber}</b>
    </Row>
    {user.verificationCode && (
    <Row>
      {`Código de Verificação: ${user.verificationCode}`}
    </Row>
    )}
  </Card>
);

SignatureCard.propTypes = {
  signature: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

export default memo(SignatureCard);
