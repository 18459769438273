import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Form,
  Select,
  Button,
  Row,
  message,
  Alert,
  Col,
} from 'antd';
import { AlertOutlined } from '@ant-design/icons';
import MaskedTextInput from 'react-text-mask';
import { useQuery, useMutation } from '@apollo/client';
import {
  BANKS,
} from 'services/graphql/queries';
import { SAVE_BANK_ACCOUNT } from 'services/graphql/mutations';
import { formatError } from 'utils';

const { Item, useForm } = Form;
const { Option } = Select;

const BankAccountForm = ({
  businessId,
  bankAccount,
  refetch,
}) => {
  const [form] = useForm();
  const [disableBankAccount, setDisableBankAccount] = useState(false);
  const { data: { banks = [] } = {} } = useQuery(BANKS, {
    variables: { limit: 500 },
    partialRefetch: true,
    onError: (error) => message.error(formatError(error)),
  });
  const [saveBankAccount, { loading: saveLoading }] = useMutation(SAVE_BANK_ACCOUNT, {
    onCompleted: () => {
      setDisableBankAccount(true);
      message.success('Conta bancária salva com sucesso!');
      refetch();
    },
    onError: (error) => message.error(formatError(error)),
  });
  const onFinish = async (values) => {
    await form.validateFields();
    saveBankAccount({
      variables: {
        ...values,
        id: bankAccount && bankAccount.id,
        businessId,
        currentAccount: values.currentAccount === 'conta-corrente',
      },
    });
  };
  useEffect(() => {
    if (bankAccount) {
      form.setFieldsValue({
        bankId: (bankAccount.bank && bankAccount.bank.id) || '',
        branchCode: (bankAccount.branchCode && bankAccount.branchCode) || '',
        accountNumber: (bankAccount.accountNumber && bankAccount.accountNumber) || '',
        currentAccount: (bankAccount === null
          || (bankAccount && bankAccount.currentAccount === true))
          ? 'conta-corrente' : 'conta-poupanca' || '',
      });
    }
  }, [bankAccount]);
  return (
    <>
      <b style={{ marginLeft: 10 }}>Conta bancária</b>
      <Card>
        <Form
          form={form}
          onFinish={onFinish}
        >
          <Card className="upload-card" style={{ marginBottom: 20, marginTop: 8 }}>
            <Row align="middle" justify="space-between">
              <Col span={3}>
                <AlertOutlined style={{ color: 'orange', fontSize: '2.5rem' }} />
              </Col>
              <Col span={20}>
                <span>
                  Importante: Trabalhamos APENAS com conta bancária PJ (Pessoa Jurídica).
                  NÃO trabalhamos com conta PF (Pessoa Física).
                  <br />
                  Pode ser necessário completar com ZEROS (à esquerda)
                  para finalizar o cadastro da sua conta
                </span>
              </Col>
            </Row>
          </Card>
          {bankAccount && bankAccount.rejectedAt && (
            <Alert
              message="Esta conta bancária foi rejeitada. Insira outra para reavaliação"
              type="error"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          {bankAccount && bankAccount.approvedAt && (
            <Alert
              message="Esta conta bancária foi aprovada."
              type="success"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          {bankAccount && (!bankAccount.approvedAt && !bankAccount.rejectedAt) && (
            <Alert
              message="Esta conta bancária está em análise."
              type="info"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          <Item name="bankId" rules={[{ required: true, message: 'Por favor selecione o banco.' }]}>
            <Select
              showSearch
              disabled={!(!bankAccount || (bankAccount && bankAccount.rejectedAt))
                || disableBankAccount}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children
                .toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="Selecione o banco"
              style={{ width: '100%' }}
            >
              {banks.map(({ id, code, name }) => (
                <Option key={id} value={id}>
                  {`${code} - ${name}`}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name="branchCode" rules={[{ required: true, message: 'Por favor preencha a agência.' }]}>
            <MaskedTextInput
              disabled={!(!bankAccount || (bankAccount && bankAccount.rejectedAt))
              || disableBankAccount}
              className="ant-input"
              guide
              mask={[/\d/, /\d/, /\d/, /\d/]}
              placeholder="Agência (sem dígito)"
            />
          </Item>
          <Item
            name="accountNumber"
            onBlur={(args) => {
              const digits = args.target.value.replace(/\D/g, '');
              if (digits) {
                form.setFieldsValue({
                  accountNumber: `${'0'.repeat(10 - (digits.length - 2))}${digits.substring(0, digits.length - 1)}-${digits.substring(digits.length - 1)}`,
                });
              }
            }}
            rules={[
              { required: true, message: 'Por favor preencha a conta corrente.' },
              () => ({
                validator(rule, value) {
                  if (!value || value.replace(/\D/g, '').length === 12) {
                    return Promise.resolve();
                  }
                  const emailsDontMatch = new Error('Preencha todos os digitos');
                  return Promise.reject(emailsDontMatch);
                },
              }),
            ]}
          >
            <MaskedTextInput
              disabled={!(!bankAccount || (bankAccount && bankAccount.rejectedAt))
                || disableBankAccount}
              className="ant-input"
              guide
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
              placeholder="Conta corrente (com dígito)"
            />
          </Item>
          <Item name="currentAccount" rules={[{ required: true, message: 'Por favor selecione o tipo de conta.' }]}>
            <Select
              disabled={!(!bankAccount || (bankAccount && bankAccount.rejectedAt))
              || disableBankAccount}
              placeholder="Tipo de conta"
              style={{ width: '100%' }}
            >
              <Option value="conta-corrente">
                Conta Corrente
              </Option>
              <Option value="conta-poupanca">
                Conta Poupança
              </Option>
            </Select>
          </Item>
          {(!bankAccount || (bankAccount && bankAccount.rejectedAt)) && !disableBankAccount && (
            <Row>
              <Col>
                <Button
                  id="save-bank-account-button"
                  loading={saveLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </Card>
    </>
  );
};

BankAccountForm.propTypes = {
  businessId: PropTypes.string,
  refetch: PropTypes.func,
  bankAccount: PropTypes.shape({
    id: PropTypes.string,
    accountNumber: PropTypes.string,
    branchCode: PropTypes.string,
    currentAccount: PropTypes.bool,
    approvedAt: PropTypes.string,
    rejectedAt: PropTypes.string,
    bank: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default memo(BankAccountForm);
