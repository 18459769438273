import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  Divider,
  Statistic,
} from 'antd';
import { formatDate } from 'utils';

const PaymentCard = ({
  payment: {
    paymentId,
    date,
    amount,
    balancedAmount,
    status,
  },
}) => (
  <Card
    bodyStyle={{ padding: 10 }}
    style={{ marginBottom: 10 }}
  >
    <Row
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
      }}
    >
      <Col xs={24} sm={24} md={8} lg={6}>
        <div className="card-info">
          <Row>ID</Row>
          <b>{paymentId}</b>
        </div>
      </Col>
      <Col className="desktop-only">
        <Divider type="vertical" style={{ height: 44 }} />
      </Col>
      <Col xs={24} sm={24} md={4} lg={3}>
        <div className="card-info">
          <Row>Data</Row>
          <b>{formatDate(new Date(date))}</b>
        </div>
      </Col>
      <Col className="desktop-only">
        <Divider type="vertical" style={{ height: 44 }} />
      </Col>
      <Col xs={24} sm={24} md={4} lg={3}>
        <div className="card-info">
          <Row>Status</Row>
          <b>{status}</b>
        </div>
      </Col>
      <Col className="desktop-only">
        <Divider type="vertical" style={{ height: 44 }} />
      </Col>
      <Col xs={24} sm={24} md={4} lg={3}>
        <div className="card-info">
          <Row>Total</Row>
          <b>
            <Statistic
              decimalSeparator=","
              groupSeparator="."
              value={amount}
              precision={2}
              prefix="R$"
            />
          </b>
        </div>
      </Col>
      <Col className="desktop-only">
        <Divider type="vertical" style={{ height: 44 }} />
      </Col>
      <Col xs={24} sm={24} md={4} lg={3}>
        <div className="card-info">
          <Row>Total pós</Row>
          <b>
            <Statistic
              decimalSeparator=","
              groupSeparator="."
              value={balancedAmount}
              precision={2}
              prefix="R$"
            />
          </b>
        </div>
      </Col>
    </Row>
  </Card>
);

PaymentCard.propTypes = {
  payment: PropTypes.shape({
    paymentId: PropTypes.string,
    date: PropTypes.string,
    status: PropTypes.string,
    amount: PropTypes.number,
    balancedAmount: PropTypes.number,
  }),
};

export default memo(PaymentCard);
