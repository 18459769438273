import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  message,
  Col,
  Spin,
  Select,
} from 'antd';
import MaskedTextInput from 'react-text-mask';
import { useQuery, useMutation } from '@apollo/client';
import {
  BUSINESS_USER,
} from 'services/graphql/queries';
import { POSTAL_CODE_TO_ADDRESS, SAVE_ADDRESS } from 'services/graphql/mutations';
import { Images } from 'assets';
import { formatError, states } from 'utils';

const { Item, useForm } = Form;
const { LogoGyraG } = Images;

message.config({
  maxCount: 1,
});

const UserAddressForm = ({
  id,
  onCompleted,
  goBack,
}) => {
  const [form] = useForm();
  const [error, setError] = useState([]);
  const {
    data: { businessUser: { user: { id: userId, address, fullName } = {} } = {} } = {},
    loading: userLoading,
  } = useQuery(BUSINESS_USER, {
    skip: !id,
    variables: { id },
    partialRefetch: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ businessUser: { user: { address } = {} } = {} }) => {
      if (address) {
        form.setFieldsValue({
          postalCode: (address.postalCode && address.postalCode) || '',
          street: (address.street && address.street) || '',
          city: (address.city && address.city) || '',
          state: (address.state && address.state) || '',
          number: (address.number && address.number) || '',
          complement: (address.complement && address.complement) || '',
          district: (address.district && address.district) || '',
        });
      }
    },
  });
  const [saveAddress, { loading: saveLoading }] = useMutation(SAVE_ADDRESS, {
    onCompleted: () => onCompleted(),
    onError: (error) => message.error(formatError(error)),
  });
  const [postalCodeToAddress, {
    loading: postalCodeLoading,
  }] = useMutation(POSTAL_CODE_TO_ADDRESS, {
    onError: (error) => {
      if (error.message.includes('ETIMEDOUT')) setError(['postalCode']);
      else message.error(formatError(error));
    },
    onCompleted: ({ postalCodeToAddress }) => {
      if (postalCodeToAddress) {
        const {
          street,
          district,
          city,
          state,
        } = postalCodeToAddress;
        if (!street) error.push('street');
        if (!district) error.push('district');
        if (!city) error.push('city');
        if (!state) error.push('state');
        setError(error);
        form.setFieldsValue({
          street,
          district,
          city,
          state,
        });
      }
    },
  });
  const onFinish = async (values) => {
    await form.validateFields();
    saveAddress({
      variables: {
        ...values,
        id: address && address.id,
        userId,
      },
    });
  };
  return (
    <Spin
      indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
      spinning={userLoading || postalCodeLoading}
      style={{ transform: 'translateX(-16px)' }}
    >
      <Form
        className="form-footer"
        form={form}
        onFinish={onFinish}
      >
        <h3 className="wl-text" style={{ marginBottom: 24 }}>
          {`Qual é o endereço de ${fullName}?`}
        </h3>
        <Item name="postalCode" rules={[{ required: true, message: 'Por favor insira o CEP.' }]}>
          <MaskedTextInput
            disabled={postalCodeLoading}
            className="ant-input"
            guide
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholder="CEP"
            onKeyUp={(e) => {
              const postalCode = e.target.value.replace(/[_-\s]/g, '');
              if (postalCode.replace(/[_-\s]/g, '').length === 8) {
                postalCodeToAddress({ variables: { postalCode } });
              } else {
                setError([]);
                form.setFieldsValue({
                  street: null,
                  district: null,
                  city: null,
                  state: null,
                });
              }
            }}
          />
        </Item>
        <Item name="street" rules={[{ required: true, message: 'Por favor insira o endereço.' }]}>
          <Input disabled={!error.includes('postalCode') && !error.includes('street')} type="text" placeholder="Rua/Av" />
        </Item>
        <Row type="flex" gutter={12}>
          <Col span={12}>
            <Item
              name="number"
              rules={[{
                required: true,
                whitespace: true,
                message: 'Por favor insira um número válido.',
                pattern: '^[0-9]*$',
                max: 10,
              }]}
            >
              <Input type="text" placeholder="Número" />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="complement">
              <Input type="text" placeholder="Complemento" />
            </Item>
          </Col>
        </Row>
        <Item name="district" rules={[{ required: true, message: 'Por favor insira o bairro.' }]}>
          <Input disabled={!error.includes('postalCode') && !error.includes('district')} type="text" placeholder="Bairro" />
        </Item>
        <Item name="city" rules={[{ required: true, message: 'Por favor insira a cidade.' }]}>
          <Input disabled={!error.includes('postalCode') && !error.includes('city')} type="text" placeholder="Cidade" />
        </Item>
        {!error.includes('postalCode')
        && (
          <Item name="state" rules={[{ required: true, message: 'Por favor insira o estado.' }]}>
            <Input disabled={!error.includes('postalCode') && !error.includes('state')} type="text" placeholder="Estado" />
          </Item>
        )}
        {error.includes('postalCode')
        && (
          <Item name="state" rules={[{ required: true, message: 'Por favor selecione o estado.' }]}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children
                .toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="Estado"
              style={{ width: '100%' }}
            >
              {Object.keys(states).map((key) => (
                <Select.Option key={key} value={key}>
                  {states[key]}
                </Select.Option>
              ))}
            </Select>
          </Item>
        )}
        <Row className="fixed-footer" gutter={12}>
          <Col span={10}>
            <Button
              block
              disabled={userLoading || saveLoading}
              ghost
              type="primary"
              onClick={() => goBack()}
            >
              Voltar
            </Button>
          </Col>
          <Col span={14}>
            <Button
              id="address-continue-button"
              block
              loading={saveLoading}
              type="primary"
              htmlType="submit"
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

UserAddressForm.propTypes = {
  id: PropTypes.string,
  onCompleted: PropTypes.func,
  goBack: PropTypes.func,
};

export default memo(UserAddressForm);
