import { gql } from '@apollo/client';

const LOANS = gql`
  query loans(
    $businessId: ID
    $limit: Int
    $offset: Int
    $sort: [SortInput!]
    $businessName: String
    $businessNumber: Int
    $statuses: [String]
    $cnpj: String
    $fromPartner: Boolean
  ) {
    loans (
      limit: $limit
      offset: $offset
      businessId: $businessId
      sort: $sort
      businessName: $businessName
      businessNumber: $businessNumber
      statuses: $statuses
      cnpj: $cnpj
      fromPartner: $fromPartner
    ) {
      id
      date
      amount
      finalAmount
      totalAmount
      installmentsCount
      finalInstallmentsCount
      installmentAmount
      interestRate
      iofAmount
      installmentsCount
      number
      installmentAmount
      interestRate
      status {
        id
        name
        value
        colors
        step
        parent {
          id
          name
        }
      }
      business {
        id
        number
        name
        legalName
      }
    }
  }
`;

const LOAN = gql`
  query loan(
    $id: ID!
  ) {
    loan (
      id: $id
    ) {
      id
      date
      firstPaymentDate
      amount
      installmentsCount
      installmentAmount
      finalAmount
      totalAmount
      installmentsCount
      finalInstallmentsCount
      installmentAmount
      interestRate
      iofAmount
      installmentsCount
      installmentAmount
      gyraMaisFeeAmount
      partnerFeeAmount
      annualCET
      interestRate
      business {
        id
        legalName
        name
        users {
          id
          completedAt
        }
        address {
          id
          proofOfAddress {
            id
          }
        }
        bankAccount {
          id
          statements {
            id
          }
        }
        bylaws {
          id
        }
      }
      status {
        id
        name
        value
        colors
        step
        parent {
          id
          name
        }
      }
      bankerId
      bankerValue
      signatures {
        id
        signedAt
        user {
          id
          email
          fullName
          phoneNumber
        }
      }
    }
  }
`;

const LOAN_STATUSES = gql`
  query loanStatuses{
    loanStatuses {
      id
      name
      value
      step
      parent {
        value
      }
    }
  }
`;

export {
  LOANS,
  LOAN,
  LOAN_STATUSES,
};
