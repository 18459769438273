import React from 'react';
import {
  Form,
  Row,
  Col,
  Select,
} from 'antd';
import { useQuery } from '@apollo/client';
import {
  Input,
} from 'components/gyramais';
import {
  BUSINESS_SEGMENTS,
  BUSINESS_SECTORS,
  BUSINESS_PLATFORM_TYPES,
} from 'services/graphql/queries';
import PropTypes from 'prop-types';

import './style.less';

const { Item } = Form;

const BusinessInfoForm = ({
  form,
  businesses,
}) => {
  const {
    data: {
      businessSegments,
    } = {},
  } = useQuery(BUSINESS_SEGMENTS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessSectors,
    } = {},
  } = useQuery(BUSINESS_SECTORS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessPlatformTypes,
    } = {},
  } = useQuery(BUSINESS_PLATFORM_TYPES, {
    fetchPolicy: 'cache-and-network',
  });

  const { Option } = Select;

  if (businessSegments
    && businessSectors
    && businessPlatformTypes) {
    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          segment: undefined,
          sector: undefined,
          platformType: undefined,
        }}
      >
        <Row gutter={12}>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="cnpj"
              rules={[{ required: true, message: 'Por favor preencha o cnpj.' }]}
            >
              <Input
                type="masked"
                className="ant-input"
                guide
                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                placeholder="CNPJ do cliente"
                onChange={async (e) => {
                  const cnpj = e.target.value.replace(/[^0-9]+/g, '');

                  if (cnpj.length === 14) {
                    await businesses({ variables: { cnpj } });
                  }
                }}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="name"
              rules={[{ required: true, message: 'Por favor preencha o nome fantasia.' }]}
            >
              <Input placeholder="Nome fantasia do cliente" />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="legalName"
              rules={[{ required: true, message: 'Por favor preencha a razão social.' }]}
            >
              <Input placeholder="Razão social do cliente" />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="employeesNumber"
              rules={[{ required: true, message: 'Por favor preencha o número de funcionários.' }]}
            >
              <Input
                placeholder="Número de funcionários"
                type="number"
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="revenueAmount"
              rules={[{ required: true, message: 'Por favor preencha o faturamento.' }]}
            >
              <Input
                type="currency"
                placeholder="Faturamento mensal (média 12 meses)"
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="website"
            >
              <Input
                placeholder="Site da empresa do cliente"
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="platformType"
              rules={[{ required: true, message: 'Por favor preencha o ramo de atuação.' }]}
            >
              <Select id="platformType" placeholder="Selecione o ramo de atuação">
                {businessPlatformTypes && businessPlatformTypes.map(({ id, name, value }) => (
                  <Option key={id} value={value}>{name}</Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="sector"
              rules={[{ required: true, message: 'Por favor preencha o setor.' }]}
            >
              <Select id="sector" placeholder="Selecione o setor">
                {businessSectors && businessSectors.map(({ id, name, value }) => (
                  <Option key={id} value={value}>{name}</Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <Item
              name="segment"
              rules={[{ required: true, message: 'Por favor preencha o segmento.' }]}
            >
              <Select id="segment" placeholder="Selecione o segmento">
                {businessSegments && businessSegments.map(({ id, name, value }) => (
                  <Option key={id} value={value}>{name}</Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
  return null;
};

BusinessInfoForm.propTypes = {
  form: PropTypes.shape(),
  businesses: PropTypes.func,
};

export default BusinessInfoForm;
