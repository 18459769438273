import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  message,
  Col,
  Spin,
  Modal,
} from 'antd';
import MaskedTextInput from 'react-text-mask';
import { useQuery, useMutation } from '@apollo/client';
import { BUSINESS_USER } from 'services/graphql/queries';
import { SAVE_BUSINESS_USER, GET_USER_BY_CPF } from 'services/graphql/mutations';
import { Images } from 'assets';
import { formatError, CPFValidator } from 'utils';

const { Item, useForm } = Form;
const { LogoGyraG } = Images;

const CivilPartnerForm = ({
  id,
  onCompleted,
  goBack,
}) => {
  const [form] = useForm();
  const [cpf, setCPF] = useState('');
  const [MarriageInfo, setMarriageInfo] = useState(null);
  const [nextStep, setNextStep] = useState(false);
  const {
    data: { businessUser: { user: { fullName, civilPartner } = {} } = {} } = {},
    loading,
  } = useQuery(BUSINESS_USER, {
    skip: !id,
    variables: { id },
    partialRefetch: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ businessUser: { user: { civilPartner } = {} } = {} }) => {
      form.setFieldsValue({
        fullName: (civilPartner && civilPartner.fullName) || '',
        cpf: (civilPartner && civilPartner.cpf) || '',
        email: (civilPartner && civilPartner.email) || '',
        phoneNumber: (civilPartner && civilPartner.phoneNumber) || '',
      });
    },
  });

  const [getUserByCPF, { loading: loadingUser }] = useMutation(GET_USER_BY_CPF, {
    onCompleted: ({
      userByCPF,
    }) => {
      if (userByCPF === null) {
        setNextStep(true);
        setMarriageInfo(null);
      } else {
        const {
          fullName, email, phoneNumber, cpf,
        } = userByCPF;
        setMarriageInfo({
          fullName, email, phoneNumber, cpf,
        });
      }
    },
    onError: (error) => message.error(formatError(error)),
  });

  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: () => onCompleted(),
    onError: (error) => message.error(formatError(error)),
  });

  const changeForAsterisk = (number) => {
    if (Number.isNaN(number)) return ('*');

    let asterisk = '';
    for (let i = 0; i < number; i += 1) {
      asterisk = `${asterisk}*`;
    }
    return asterisk;
  };

  const onFinish = async (values) => {
    await form.validateFields();
    saveBusinessUser({
      variables: {
        id,
        civilPartner: {
          ...values,
          id: civilPartner && civilPartner.id,
        },
      },
    });
  };
  return (
    <Spin
      indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
      spinning={loading}
      style={{ transform: 'translateX(-16px)' }}
    >
      <Modal
        visible={MarriageInfo != null}
        okText="Confirmar"
        centered
        onOk={() => {
          const {
            fullName, email, phoneNumber,
          } = MarriageInfo;
          saveBusinessUser({
            variables: {
              id,
              civilPartner: {
                fullName,
                email,
                phoneNumber,
                cpf,
                id: civilPartner && civilPartner.id,
              },
            },
          });
        }}
        onCancel={() => {
          form.setFieldsValue({
            cpf: '',
          });
          setMarriageInfo(null);
        }}
        cancelText="Cancelar"
      >
        <p style={{ fontWeight: 600, fontSize: '1.3rem' }}>Esse usuario já existe. Você está confirmando o usuario com as informações abaixo?</p>
        {MarriageInfo === null ? null : (
          <>
            <p>
              Nome:
              {` ${MarriageInfo.fullName.split(' ')[0]} ${MarriageInfo.fullName.split(' ').splice(-1, 1)} `}
            </p>
            <p>
              Email:
              {` ${MarriageInfo.email.substring(0, 3)}${changeForAsterisk(MarriageInfo.email.split('@')[0].length - 3)}@${MarriageInfo.email.split('@')[1]}`}
            </p>
            <p>
              Telefone:
              {` ${MarriageInfo.phoneNumber.substring(0, 4)}${changeForAsterisk(6)}${MarriageInfo.phoneNumber.substring(12)}`}
            </p>
          </>
        )}
      </Modal>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <h3 className="wl-text" style={{ marginBottom: 24 }}>
          {`Dados do conjuge de ${fullName}`}
        </h3>
        <Item name="cpf" rules={[{ required: true, message: 'Por favor preencha o cpf.' }]}>
          <MaskedTextInput
            className="ant-input"
            guide
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholder="CPF"
            onChange={(e) => {
              setCPF(e.target.value);
              if (e.target.value.replace(/\D/g, '').length === 11) {
                if (CPFValidator(e.target.value.replace(/\D/g, ''))) {
                  getUserByCPF({ variables: { cpf: e.target.value.replace(/\D/g, '') } });
                } else {
                  message.error('Digite um CPF válido', 3);
                }
              }
            }}
            disabled={loadingUser}
          />
        </Item>

        <Item name="fullName" rules={[{ required: true, message: 'Por favor preencha o nome completo.' }]}>
          <Input placeholder="Nome completo" disabled={loadingUser || !nextStep} />
        </Item>
        <Item name="email" rules={[{ required: true, message: 'Por favor preencha o email.' }]}>
          <Input placeholder="E-mail" disabled={loadingUser || !nextStep} />
        </Item>

        <Item name="phoneNumber" rules={[{ required: true, message: 'Por favor preencha o telefone.' }]}>
          <MaskedTextInput
            className="ant-input"
            guide
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholder="Telefone com DDD"
            disabled={loadingUser || !nextStep}
          />
        </Item>
        <Row gutter={12} className="fixed-footer">
          <Col span={10}>
            <Button
              block
              disabled={loading || saveLoading}
              ghost
              type="primary"
              onClick={() => {
                goBack();
              }}
            >
              Voltar
            </Button>
          </Col>
          <Col span={14}>
            <Button
              block
              disabled={loading}
              loading={saveLoading}
              type="primary"
              htmlType="submit"
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

CivilPartnerForm.propTypes = {
  id: PropTypes.string,
  onCompleted: PropTypes.func,
  goBack: PropTypes.func,
};

export default memo(CivilPartnerForm);
