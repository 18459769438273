import { gql } from '@apollo/client';

const ADD_SESSION = gql`
  mutation addSession(
    $session: Session!
  )  {
    addSession(
      session: $session
    ) @client
  }
`;

const REMOVE_SESSION = gql`
  mutation removeSession {
    removeSession @client
  }
`;

const SET_LOAN_DRAWER = gql`
  mutation setLoanDrawer($visible: Boolean!, $loanId: ID)  {
    setLoanDrawer(visible: $visible, loanId: $loanId) @client
  }
`;

const CHANGE_ENVIRONMENT = gql`
  mutation changeEnvironment($environment: String!)  {
    changeEnvironment(environment: $environment) @client
  }
`;

export {
  ADD_SESSION,
  REMOVE_SESSION,
  SET_LOAN_DRAWER,
  CHANGE_ENVIRONMENT,
};
