import React from 'react';

import { HorizontalSplit, LogInForm } from 'components';

const LogInScreen = () => (
  <HorizontalSplit className="login-layout">
    <LogInForm />
  </HorizontalSplit>
);

export default LogInScreen;
