import React from 'react';
import {
  Row,
  Col,
} from 'antd';
import { LoanStatsCard } from 'components';

import './styles.less';

const LoanStats = ({
  loanStats = {},
  loading,
  onChooseStatus,
}) => {
  const items = [
    {
      name: 'Novo',
      value: 'new',
      statusValue: 'new',
    }, {
      name: 'PRE-APROVADOS',
      value: 'preApproved',
      statusValue: 'pre-approved',
    }, {
      name: 'CADASTRO',
      value: 'pending',
      statusValue: 'pending',
    }, {
      name: 'ASSINATURA',
      value: 'signature',
      statusValue: 'awaiting-signature',
    }, {
      name: 'ATIVOS',
      value: 'active',
      statusValue: 'active',
    }, {
      name: 'INADIMPLENTES',
      value: 'late',
    },
  ];

  return (
    <div className="loan-stats-container">
      <h6>
        <b className="link-text">
          Estatísticas de clientes
        </b>
      </h6>

      <Row align="middle" justify="space-between" gutter={[12, 12]}>
        {items.map((item) => (
          <Col xs={24} sm={12} md={8} lg={8} xl={4}>
            <LoanStatsCard
              label={item.name}
              value={loanStats[item.value]}
              max={loanStats.total}
              color="#6a0dad"
              loading={loading}
              onClick={() => {
                onChooseStatus(item.statusValue);
              }}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

LoanStats.propTypes = {};

export default LoanStats;
