import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Tooltip,
} from 'antd';

import {
  EditOutlined,
  PlusOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import {
  Button,
  Information,
} from 'components/gyramais';

import {
  useQuery,
} from '@apollo/client';
import CreateOrEditUserModal from './components/CreateOrEditUserModal';

import BUSINESS from '../../graphql/business.gql';

import './styles.less';

const BusinessUser = ({
  businessId,
  loading: parentLoading,
}) => {
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [userToEdit, setUserToEdit] = useState(undefined);
  const [overwriteUserToEdit, setOverwriteUserToEdit] = useState(undefined);

  const {
    data: {
      business,
    } = {},
    refetch,
    loading: businessLoading,
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (business && overwriteUserToEdit) {
      const businessUser = business
        .users
        .find((businessUser) => businessUser.id === overwriteUserToEdit);

      if (businessUser) {
        setUserToEdit(businessUser);
        setOverwriteUserToEdit(undefined);
      }
    }

  }, [business, overwriteUserToEdit]);

  const loading = parentLoading || businessLoading;
  return (
    <div id="user-step-card">
      <Tooltip title="Criar sócio" placement="topLeft">
        <Button
          disabled={loading}
          className="fab"
          onClick={() => {
            setUserModalVisible(true);
            setUserToEdit(undefined);
          }}
        >
          <PlusOutlined style={{ color: 'white', fontSize: 24 }} />
        </Button>
      </Tooltip>

      <div className="user-accounts-container">
        {business?.users.map((businessUser) => (
          <Card
            key={businessUser.id}
            className={`user-account-card ${businessUser.completedAt && 'completed'}`}
          >
            <div className="user-account-card-header">
              <Tooltip title="Editar usuário">
                <Button
                  className="edit-button"
                  disabled={loading}
                  link
                  onClick={() => {
                    setUserModalVisible(true);
                    setUserToEdit(businessUser);
                  }}
                >
                  <EditOutlined style={{ fontSize: 24 }} />
                </Button>
              </Tooltip>
              {businessUser.completedAt && (
                <Tooltip title="Usuário completo">
                  <CheckOutlined style={{ fontSize: 24 }} />
                </Tooltip>
              )}
            </div>
            <Row>
              {businessUser.user.fullName && (
                <>
                  <Col xs={12} md={20}>
                    <Information
                      detailed
                      label="Nome"
                      value={businessUser.user.fullName}
                    />
                  </Col>
                  <Col xs={12} md={4} />
                </>
              )}
              <Col xs={12} md={12}>
                <Information
                  detailed
                  label="CPF"
                  value={businessUser.user.cpf}
                />
              </Col>
              <Col xs={12} md={12}>
                <Information
                  detailed
                  label="Email"
                  value={businessUser.user.email}
                />
              </Col>
              {businessUser.user.phoneNumber && (
                <Col xs={12} md={12}>
                  <Information
                    detailed
                    label="Telefone"
                    value={businessUser.user.phoneNumber}
                  />
                </Col>
              )}
              {businessUser.user.civilPartner && (
                <Col xs={12} md={12}>
                  <Information
                    detailed
                    label="Cônjuge"
                    value={businessUser.user.civilPartner.fullName}
                  />
                </Col>
              )}
            </Row>
          </Card>
        ))}
      </div>

      <CreateOrEditUserModal
        businessId={businessId}
        businessUserId={userToEdit?.id}
        setBusinessUserId={(buId) => {
          setOverwriteUserToEdit(buId);
        }}
        visible={userModalVisible}
        refetch={refetch}
        onClose={() => {
          setUserToEdit(undefined);
          setUserModalVisible(false);
        }}
      />
    </div>
  );
};

export default BusinessUser;
