import { gql } from '@apollo/client';

const INVOICE = gql`
  query invoice(
    $id: ID!
  ) {
    invoice (
      id: $id
    ) {
      id
      url
      canceledAt
      paidAt
    }
  }
`;

export {
  INVOICE,
};
export default INVOICE;
