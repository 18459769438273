/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  Layout,
  Table,
  Tabs,
} from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import {
  formatDate,
} from 'utils';
import {
  useHistory,
} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Button,
  SubHeader,
} from 'components/gyramais';
import {
  ClientDrawer,
  PaymentDrawer,
  OrderDrawer,
  LoanDrawer,
  ProductDrawer,
  ImportInfosModal,
} from 'components';
import { useActionContext } from 'contexts/ActionContext';
import {
  ActionButton,
} from './components';
import {
  BUSINESSES,
} from './graphql';

import './styles.less';

const { TabPane } = Tabs;
const {
  Content,
} = Layout;

const HomeScreen = () => {
  const { checkForBusinessAction } = useActionContext();
  const [clientDrawer, setClientDrawer] = useState(false);
  const [loanDrawer, setLoanDrawer] = useState(false);
  const [paymentDrawer, setPaymentDrawer] = useState(false);
  const [orderDrawer, setOrderDrawer] = useState(false);
  const [productDrawer, setProductDrawer] = useState(false);
  const [importAlertVisible, setImportAlertVisible] = useState(false);

  const history = useHistory();
  const { data: { businesses } = {} } = useQuery(BUSINESSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 50,
      sort: [{
        descending: 'createdAt',
      }],
      partner: true,
    },
  });

  const columns = [
    {
      title: 'Cliente',
      dataIndex: 'legalName',
      key: 'name',
      width: '60%',
    },
    {
      title: 'Adicionado em',
      dataIndex: 'createdAt',
      width: '20%',
      key: 'createdAt',
      render: (createdAt) => (createdAt && formatDate(new Date(createdAt))) || '-',
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'x',
      render: (a) => (
        <div>
          <Button
            onClick={() => history.push(`/cliente/${a.id}`)}
            link
            icon={<EyeOutlined />}
          >
            Ver detalhes
          </Button>
        </div>
      ),
    },
  ];

  /**
   * Se qualquer action existir o elemento ficara visível
   * @type {{
   *   name: string,
   *   action: (UserActions | BusinessActions)[],
   *   onClick: () => void,
   *  }[]}
   */
  const homeButtons = [
    {
      name: 'Clientes',
      id: 'upload-clients-button',
      action: [
        'client-upload',
        'create-client-with-loan',
        'create-client-with-no-loan',
      ],
      onClick: () => {
        setClientDrawer(true);
      },
    },
    {
      name: 'Empréstimos',
      id: 'upload-loans-button',
      action: [
        'create-client-with-loan',
      ],
      onClick: () => {
        setLoanDrawer(true);
      },
    },
    {
      name: 'Pagamentos',
      id: 'upload-payments-button',
      action: ['payment-upload'],
      onClick: () => {
        setPaymentDrawer(true);
      },
    },
    {
      name: 'Vendas',
      id: 'upload-orders-button',
      action: ['order-upload'],
      onClick: () => {
        setOrderDrawer(true);
      },
    },
    {
      name: 'Produtos',
      id: 'upload-products-button',
      action: ['product-upload'],
      onClick: () => {
        setProductDrawer(true);
      },
    },
  ];

  const activeHomeButtons = homeButtons.filter(
    ({ action }) => !!action.find((el) => checkForBusinessAction(el)),
  );

  return (
    <Layout id="homescreen">
      {activeHomeButtons.length > 0 && (
        <SubHeader
          id="homescreen-subheader"
          onlyButtons
          buttons={activeHomeButtons.map((btn) => (
            <ActionButton key={btn.id} {...btn} numberOfButtons={activeHomeButtons.length} />
          ))}
        />
      )}

      <Content>
        <div id="homescreen-tabs-container">
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={`Adicionados recentemente (${
                businesses
                  ?.filter((business) => business.__typename !== 'Partner')
                  .map((business) => ({
                    ...business,
                    key: business.id,
                  })).length
              })`}
              key="1"
            >
              {businesses?.filter((business) => business.__typename !== 'Partner').length === 0
                ? (
                  <p>
                    Sem clientes adicionados
                  </p>
                ) : (
                  <Table
                    size="small"
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    dataSource={
                      businesses
                        ?.filter((business) => business.__typename !== 'Partner')
                        .map((business) => ({
                          ...business,
                          key: business.id,
                        }))
                    }
                  />
                )}
            </TabPane>
          </Tabs>
        </div>
      </Content>

      <ClientDrawer
        visible={clientDrawer}
        onStartImport={() => setImportAlertVisible(true)}
        onClose={() => {
          setClientDrawer(false);
        }}
      />

      <LoanDrawer
        visible={loanDrawer}
        onClose={() => setLoanDrawer(false)}
      />

      <PaymentDrawer
        visible={paymentDrawer}
        onStartImport={() => setImportAlertVisible(true)}
        onClose={() => {
          setPaymentDrawer(false);
        }}
      />

      <OrderDrawer
        visible={orderDrawer}
        onStartImport={() => setImportAlertVisible(true)}
        onClose={() => {
          setOrderDrawer(false);
        }}
      />

      <ProductDrawer
        visible={productDrawer}
        onStartImport={() => setImportAlertVisible(true)}
        onClose={() => {
          setProductDrawer(false);
        }}
      />

      <ImportInfosModal
        visible={importAlertVisible}
        onClose={() => setImportAlertVisible(false)}
      />

    </Layout>
  );
};

export default HomeScreen;
