import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  notification,
  message,
  Divider,
  Avatar,
} from 'antd';
import {
  CheckOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  Button,
  ColorPicker,
} from 'components';
import {
  Drawer,
  Alert,
} from 'components/gyramais';
import {
  formatError,
  getContrastYIQ,
  isBrightColor,
} from 'utils';

import { useMutation } from '@apollo/client';
import { useConfirmModalContext } from 'contexts/ConfirmModalContext/confirm-modal-context';
import useConfirmBrowserExit from 'utils/hooks/useConfirmBrowserExit ';
import {
  SET_APP_COLORS,
} from './gql';

import './styles.less';

const AppEditorDrawer = ({
  refetch,
  darkLogo,
  lightLogo,
  loading,
  visible,
  businessId,
  businessColors,
  onClose,
}) => {
  const { requestConfirmation } = useConfirmModalContext();

  const { enableConfirmation, disableConfirmation } = useConfirmBrowserExit({
    defaultEnabled: false,
    message: 'Você tem modificações que ainda nao foram salvas! Deseja sair mesmo assim?',
  });

  const [editedColors, setEditedColors] = useState(businessColors);
  const [hasEdited, setEdited] = useState(false);
  const [logo, setLogo] = useState({});

  const choiceOfLogo = () => {
    const brightColor = isBrightColor(editedColors?.primary);
    if (brightColor) {
      setLogo(darkLogo);
    } else {
      setLogo(lightLogo);
    }
  };

  const closeDrawer = () => {
    onClose();
    disableConfirmation();
    setEdited(false);
    setEditedColors(businessColors);
  };

  const [setAppColors, { loading: setAppColorsLoading }] = useMutation(SET_APP_COLORS, {
    onCompleted: () => {
      notification.success({
        message: 'Cores alterada com sucesso.',
      });
      refetch();
      setEdited(false);
      disableConfirmation();
      closeDrawer();
    },
    onError: (error) => message.error(formatError(error)),
  });

  const submitBusiness = () => {
    setAppColors({ variables: { partnerId: businessId, colors: editedColors } });
  };

  useEffect(() => {
    if (businessColors) {
      setEditedColors(businessColors);
    }
  }, [businessColors]);

  useEffect(() => {
    choiceOfLogo();
  }, [editedColors?.primary]);

  useEffect(() => {
    if (hasEdited) {
      enableConfirmation();
    }
  }, [hasEdited]);

  return (
    <Drawer
      visible={visible}
      width="50%"
      className="app-editor-drawer"
      title="Editar visual do Portal"
      onClose={() => {
        if (!hasEdited) {
          closeDrawer();
          return;
        }

        requestConfirmation({
          message: 'Você tem modificações que ainda nao foram salvas! Deseja fechar mesmo assim?',
          onOk: closeDrawer,
          onCancel: () => {},
        });
      }}
      primaryButton={{
        children: 'Salvar visual',
        id: 'save-app-colors',
        loading: setAppColorsLoading,
        disabled: businessColors === editedColors,
        onClick: () => submitBusiness('color'),
      }}
    >
      <Alert
        type="warning"
        message="As cores do Portal também serão utilizadas nas emails disparados pelo sistema"
      />

      <Row className="color-picker-group">
        <Col span={24}>
          <p>
            <b>
              Cor primária
            </b>
          </p>
          <ColorPicker
            id="primary-app-color"
            color={editedColors?.primary}
            onChange={(hex) => {
              setEditedColors({
                primary: hex,
                primaryButton: editedColors?.primaryButton,
                secondaryButton: editedColors?.secondaryButton,
                linkButton: editedColors?.linkButton,
              });
              setEdited(true);
            }}
            loading={loading}
            info="Recomendamos o uso de uma cor escura"
          />
        </Col>

        <Col className="col-color-picker-primary-button" xs={24} sm={24} md={24}>
          <p>
            <b>
              Cor do botão primário
            </b>
          </p>
          <ColorPicker
            id="app-color-picker"
            color={editedColors?.primaryButton}
            onChange={(hex) => {
              setEditedColors({
                primary: editedColors?.primary,
                primaryButton: hex,
                secondaryButton: editedColors?.secondaryButton,
                linkButton: editedColors?.linkButton,
              });
              setEdited(true);
            }}
            loading={loading}
            info="Deve manter contraste com a cor do banner e o fundo branco"
          />
        </Col>

        <Col span={24}>
          <p>
            <b>
              Cor do botão secundário
            </b>
          </p>
          <ColorPicker
            color={editedColors?.secondaryButton}
            onChange={(hex) => {
              setEditedColors({
                primary: editedColors?.primary,
                primaryButton: editedColors?.primaryButton,
                secondaryButton: hex,
                linkButton: editedColors?.linkButton,
              });
              setEdited(true);
            }}
            loading={loading}
            info="Deve manter contraste com o fundo branco"
          />
        </Col>

        <Col className="col-color-picker-button-link" span={24}>
          <p>
            <b>
              Cor do botão link e detalhes
            </b>
          </p>
          <ColorPicker
            color={editedColors?.linkButton}
            onChange={(hex) => {
              setEditedColors({
                primary: editedColors?.primary,
                primaryButton: editedColors?.primaryButton,
                secondaryButton: editedColors?.secondaryButton,
                linkButton: hex,
              });
              setEdited(true);
            }}
            loading={loading}
            info="Favor, não inserir a cor branca. Caso contrário, iremos alterá-la nos lugares necessários para preservar o contraste."
          />
        </Col>

      </Row>

      <Divider />

      <h3>Pré-visualização</h3>
      <br />

      <Row className="preview" gutter={[32, 32]}>
        <Col span={24}>
          <Row className="header-preview-container" style={{ background: editedColors?.primary }}>
            <Col className="logo-container" span={12}>
              <img src={logo?.url} alt="logo" />
            </Col>

            <Col className="header-menu-button" span={12}>
              <span className="salutation">
                <span style={{ color: getContrastYIQ(editedColors?.primary?.replace('#', '')) }}>
                  Gyra
                  <DownOutlined />
                </span>
              </span>

              <Avatar className="avatar" size="large" style={{ background: editedColors?.primaryButton }}>
                <span style={{ color: getContrastYIQ(editedColors?.primaryButton?.replace('#', '')) }}>G</span>
              </Avatar>
            </Col>

            <Col className="button-container" xs={24} sm={24} md={24}>
              <Button
                style={{
                  background: editedColors?.primaryButton,
                  color: getContrastYIQ(editedColors?.primaryButton?.replace('#', '')),
                }}
              >
                Primário
              </Button>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Button
            style={{
              background: editedColors?.primaryButton,
              color: getContrastYIQ(editedColors?.primaryButton?.replace('#', '')),
            }}
          >
            Primário
          </Button>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Button
            color="secondary"
            style={{
              background: editedColors?.secondaryButton,
              color: getContrastYIQ(editedColors?.secondaryButton?.replace('#', '')),
            }}
          >
            Secundário
          </Button>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Button link style={{ color: editedColors?.linkButton }}>
            <CheckOutlined className="link-button-icon" />
            Link com ícone
          </Button>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <p>
            <b style={{ color: editedColors?.primary }}>
              Título
            </b>
          </p>
        </Col>
      </Row>
    </Drawer>
  );
};

AppEditorDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  businessColors: PropTypes.shape({}),
  darkLogo: PropTypes.shape({}),
  lightLogo: PropTypes.shape({}),
  businessId: PropTypes.string,
  refetch: PropTypes.func,
  loading: PropTypes.bool,
};

export default AppEditorDrawer;
