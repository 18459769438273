import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  AutoComplete,
  message,
} from 'antd';
import {
  Input,
} from 'components/gyramais';
import { formatError, states } from 'utils';
import {
  useMutation,
} from '@apollo/client';
import POSTAL_CODE_TO_ADDRESS from './graphql/postalCodeToAddress.gql';

const { Item } = Form;
const { Option } = AutoComplete;

const AddressForm = ({
  address,
  form,
  loading: motherLoading,
}) => {
  const {
    id,
    postalCode,
  } = address ?? {};

  const [blockedFields, setBlockedFields] = useState(
    (id && postalCode) ? ['district', 'street', 'state', 'city']
      : ['district', 'street', 'state', 'city', 'number', 'complement'],
  );

  const [
    postalCodeToAddress,
    { loading: postalCodeToAddressLoading },
  ] = useMutation(POSTAL_CODE_TO_ADDRESS, {
    onError: (error) => {
      setBlockedFields(['district', 'street', 'state', 'city', 'number', 'complement']);
      message.error(formatError(error));
    },
    onCompleted: ({ postalCodeToAddress }) => {
      const filledValues = [];
      if (postalCodeToAddress) {
        const {
          street,
          district,
          city,
          state,
        } = postalCodeToAddress;
        if (street?.length > 0) filledValues.push('street');
        if (district?.length > 0) filledValues.push('district');
        if (city?.length > 0) filledValues.push('city');
        if (state?.length > 0) filledValues.push('state');
        form.resetFields(filledValues);
        setBlockedFields(filledValues);
        form.setFieldsValue({
          street,
          district,
          city,
          state,
        });
      }
    },
  });

  const loading = motherLoading || postalCodeToAddressLoading;

  useEffect(() => {
    if (address) {
      form.setFieldsValue(address);
    }

    return () => {
      form.resetFields();
    };
  }, []);

  return (
    <Form
      layout="vertical"
      form={form}
    >
      <Row gutter={12}>
        <Col xs={24} sm={24} md={12}>
          <Item name="postalCode" label="CEP" rules={[{ required: true, message: 'Por favor insira o CEP.' }]}>
            <Input
              disabled={blockedFields.includes('postalCode') || loading}
              type="masked"
              className="ant-input"
              guide
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              placeholder="CEP"
              onKeyUp={(e) => {
                const postalCode = e.target.value.replace(/[_-\s]/g, '');
                if (postalCode.replace(/[_-\s]/g, '').length === 8) {
                  postalCodeToAddress({ variables: { postalCode } });
                }
              }}
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Item name="district" label="Bairro" rules={[{ required: true, message: 'Por favor insira o bairro.' }]}>
            <Input
              disabled={blockedFields.includes('district')}
              loading={loading}
              type="text"
              placeholder="Bairro"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Item
            name="street"
            label={(
              <>
                <span>Endereço</span>
                {' '}
              </>
            )}
            rules={[{ required: true, message: 'Por favor insira o endereço.' }]}
          >
            <Input
              disabled={blockedFields.includes('street')}
              loading={loading}
              type="text"
              placeholder="Endereço"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Item
            name="number"
            label="Número"
            rules={[{
              required: true,
              whitespace: true,
              message: 'Por favor insira um número válido.',
              max: 10,
              pattern: '^[0-9]*$',
            }]}
          >
            <Input
              disabled={blockedFields.includes('number')}
              loading={loading}
              type="text"
              placeholder="Número"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Item name="complement" label="Complemento" rules={[{ required: false, max: 49, message: 'Complemento com no máximo 49 caracteres' }]}>
            <Input
              disabled={blockedFields.includes('complement')}
              loading={loading}
              type="text"
              placeholder="Complemento"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Item name="state" label="UF do Estado" rules={[{ required: true, message: 'Por favor insira o UF do Estado.', len: 2 }]}>
            <Input
              disabled={blockedFields.includes('state')}
              loading={loading}
              type="text"
              placeholder="UF"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Item name="city" label="Cidade" rules={[{ required: true, message: 'Por favor selecione a cidade.' }]}>
            <AutoComplete
              showSearch
              disabled={blockedFields.includes('city')}
              className="gyramais-input"
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children
                .toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="Cidade"
              style={{ width: '100%' }}
              loading={loading}
            >
              {Object.keys(states).map((key) => (
                <Option key={key} value={states[key]}>
                  {states[key]}
                </Option>
              ))}
            </AutoComplete>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

AddressForm.propTypes = {
  address: PropTypes.shape(),
};

export default AddressForm;
