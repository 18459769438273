import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  AutoComplete,
  Select,
  DatePicker,
} from 'antd';
import { useQuery } from '@apollo/client';
import {
  Button,
} from 'components/gyramais';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { BUSINESS_NAMES, LOAN_STATUSES } from './graphql';

const { Option } = Select;
const { Item } = Form;
const { RangePicker } = DatePicker;

const LoanFilterForm = ({
  onSearch,
}) => {
  const [businessName, setBusinessName] = useState();
  const [businessCnpj, setBusinessCnpj] = useState();
  const [filteredStatuses, setFilteredStatuses] = useState();

  const {
    data: { businesses = [] } = {},
  } = useQuery(BUSINESS_NAMES, {
    variables: {
      businessName,
      cnpj: businessCnpj,
      limit: 10,
    },
    skip: !businessName && !businessCnpj,
    fetchPolicy: 'network-only',
  });

  useQuery(LOAN_STATUSES, {
    onCompleted: ({ loanStatuses }) => {
      const filtered = loanStatuses.filter((status) => {
        if (status?.parent?.value === 'awaiting-re-evaluation') {

          return false;
        }

        return true;
      });

      setFilteredStatuses([...filtered]);
    },
  });
  return (
    <Form
      layout="vertical"
      className="loans-filter-form"
      onFinish={(values) => {
        const {
          businessName,
          businessNumber,
          cnpj,
          status,
          dateRange,
        } = values;
        onSearch({
          businessName,
          businessNumber: Number(businessNumber),
          cnpj,
          statuses: status && status.length > 0 ? status : undefined,
          startDisbursementDate: dateRange?.[0],
          endDisbursementDate: dateRange?.[1],
        });
      }}
    >
      <h6>
        <b className="link-text">
          Filtros
        </b>
      </h6>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={5}>
          <Item name="businessName">
            <AutoComplete
              className="gyramais-input"
              value={businessName}
              onChange={(name) => setBusinessName(name)}
              onSelect={(name) => setBusinessName(name)}
              options={businesses
                .filter(({ name }) => name)
                .map(({ name }) => ({ label: name, value: name }))}
              placeholder="Nome do cliente"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={5}>
          <Item name="cnpj">
            <AutoComplete
              className="gyramais-input"
              onChange={(cnpj) => setBusinessCnpj(cnpj)}
              options={businesses
                .filter(({ name }) => name)
                .map(({ name, cnpj }) => ({ label: name, value: cnpj }))}
              placeholder="CNPJ do cliente"
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={5}>
          <Item name="status">
            <Select placeholder="Situação do empréstimo" className="gyramais-input">
              <Option value="">Todos</Option>
              {filteredStatuses && filteredStatuses.map((status) => (
                <Option key={status.id} value={status.value}>{status.name}</Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={5}>
          <Item name="dateRange">
            <RangePicker
              className="gyramais-input gyramais-ranger-picker"
              format="DD-MM-YYYY"
              placeholder={['Desembolsado de', 'até']}
              locale={locale}
            />
          </Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4}>
          <Button
            block
            htmlType="submit"
            id="filterBtn"
          >
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

LoanFilterForm.propTypes = {
  onSearch: PropTypes.func,
};

export default memo(LoanFilterForm);
