import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  message,
} from 'antd';
import { useMutation } from '@apollo/client';
import MaskedTextInput from 'react-text-mask';
import { SAVE_ME } from 'services/graphql/mutations';
import { formatError } from 'utils';
import client from 'services/graphql';

const { Item, useForm } = Form;

const UserForm = ({
  fullName,
  email,
  cpf,
  phoneNumber,
  phoneNumberVerified,
  onCompleted,
  refetch,
}) => {
  const [form] = useForm();
  const [saveMe, { loading }] = useMutation(SAVE_ME, {
    onError: (error) => message.error(formatError(error)),
  });
  useEffect(() => {
    form.setFieldsValue({
      fullName,
      email,
      cpf: `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(6, 9)}-${cpf.substring(9, 11)}`,
      phoneNumber,
    });
  }, []);
  const onFinish = async (values) => {
    await form.validateFields();
    try {
      await saveMe({
        variables: values,
        client,
      });
      message.success('Seus dados foram atualizados com sucesso.');
      refetch();
      onCompleted();
    } catch (error) {
      message.error('Ocorreu um erro ao salvar. Entre em contato caso o problema persista', 3);
      await saveMe({
        variables: {
          fullName,
          email,
          cpf,
          phoneNumber,
        },
        client,
      });
    }
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <Item label="Nome Completo" name="fullName" rules={[{ required: true, message: 'Por favor preencha seu nome completo' }]}>
        <Input />
      </Item>
      <Item label="E-mail" name="email" rules={[{ required: true, message: 'Por favor preencha seu email' }]}>
        <Input />
      </Item>
      <Item label="CPF" name="cpf" rules={[{ required: true, message: 'Por favor preencha o cpf.' }]}>
        <MaskedTextInput
          className="ant-input"
          guide
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholder="CPF"
        />
      </Item>
      <Item label="Telefone com DDD" name="phoneNumber" rules={[{ required: true, message: 'Por favor preencha o telefone.' }]}>
        <MaskedTextInput
          className="ant-input"
          disabled={phoneNumberVerified}
          guide
          mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          placeholder="Telefone com DDD"
        />
      </Item>
      <Button
        type="primary"
        htmlType="submit"
        block
        loading={loading}
      >
        Salvar
      </Button>
    </Form>
  );
};

UserForm.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  cpf: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneNumberVerified: PropTypes.bool,
  onCompleted: PropTypes.func,
  refetch: PropTypes.func,
};

export default memo(UserForm);
