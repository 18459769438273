import { gql } from '@apollo/client';

const CREATE_PASSWORD_PARTNER = gql`
  mutation createPasswordPartner($password: String!, $passwordToken: String, $updatePasswordToken: String) {
    createPasswordPartner(password: $password, passwordToken: $passwordToken, updatePasswordToken: $updatePasswordToken) {
      id
      sessionToken
      firstName
    }
  }
`;

const CREATE_VERIFICATION_CODE = gql`
  mutation createVerificationCode {
    createVerificationCode
  }
`;

const VERIFY_CODE = gql`
  mutation verifyCode($verificationCode: String!) {
    verifyCode(verificationCode: $verificationCode)
  }
`;

const LOG_IN_PARTNER = gql`
  mutation logInPartner($email: String!, $password: String!) {
    logInPartner(email: $email, password: $password) {
      id
      firstName
      sessionToken
    }
  }
`;

const LOG_OUT = gql`
  mutation logOut {
    logOut
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $partner: Boolean) {
    resetPassword(email: $email, partner: $partner)
  }
`;

const SAVE_ME = gql`
  mutation saveMe(
    $email: String!
    $fullName: String!
    $cpf: String!
    $phoneNumber: String!
  ) {
     saveMe(
      email: $email,
      fullName: $fullName
      cpf: $cpf
      phoneNumber: $phoneNumber
    )
  }
`;

const SAVE_MARRIAGE_OR_DIVORCE_DOCS = gql`
  mutation saveMarriageOrDivorceDocs(
    $id: ID!
    $idCardFileId: ID
    $proofOfMariageOrDivorceFileId: ID
  ) {
    saveMarriageOrDivorceDocs(
      id: $id
      idCardFileId: $idCardFileId
      proofOfMariageOrDivorceFileId :$proofOfMariageOrDivorceFileId
    ) {
      id
    }
  }
`;

const GET_USER_BY_CPF = gql`
  mutation userByCPF(
      $cpf: String!
    ) {
    userByCPF(
      cpf:$cpf
    ) {
      fullName
      phoneNumber
      email
    }
  }
`;

const VALIDATE_CPF = gql`
  mutation validateCPF($cpf: String!) {
    validateCPF(cpf: $cpf)
  }
`;

const VALIDATE_EMAIL = gql`
  mutation validateEmail($email: String!) {
    validateEmail(email: $email)
  }
`;

const VALIDATE_PHONE_NUMBER = gql`
  mutation validatePhoneNumber($phoneNumber: String!) {
    validatePhoneNumber(phoneNumber: $phoneNumber)
  }
`;

export {
  CREATE_PASSWORD_PARTNER,
  CREATE_VERIFICATION_CODE,
  VERIFY_CODE,
  LOG_IN_PARTNER,
  LOG_OUT,
  RESET_PASSWORD,
  SAVE_ME,
  SAVE_MARRIAGE_OR_DIVORCE_DOCS,
  GET_USER_BY_CPF,
  VALIDATE_CPF,
  VALIDATE_EMAIL,
  VALIDATE_PHONE_NUMBER,
};
