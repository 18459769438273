import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Carousel,
  Modal,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import {
  Drawer,
  Button,
} from 'components/gyramais';
import { OptionCard, ObjectInfo, ProcessMessage } from 'components';
import {
  downloadImportModel, downloadImportCsvModel,
} from 'utils';
import { useActionContext } from 'contexts/ActionContext';
import { CREATE_CLIENT_WITH_LOAN_PATH, CREATE_CLIENT_WITH_NO_LOAN_PATH } from 'routes';
import useDrawerHelpers from 'utils/hooks/useDrawerHelpers';
import useImportFile from 'utils/hooks/useImportFile';
import { BUSINESSES_IMPORT_JSON_DOCS_1, BUSINESSES_IMPORT_JSON_DOCS_2 } from 'utils/business-import-file-docs';

const { Item } = Form;
const { useForm } = Form;

const OPTIONS_STEP = 0;
const PROCESSING_STEP = 1;

const ClientDrawer = ({
  visible,
  onClose,
  onStartImport,
}) => {
  const carouselRef = useRef(null);
  const { checkForBusinessAction } = useActionContext();
  const [current, setCurrent] = useState(OPTIONS_STEP);
  const [importItemsDocumentationModal, setImportItemsDocumentationModal] = useState(false);
  const history = useHistory();
  const [form] = useForm();

  const { goToStepSafely } = useDrawerHelpers({ carouselRef });

  const closeDrawer = () => {
    onClose();
    setCurrent(OPTIONS_STEP, 600);
  };

  const {
    loadingUploadFile,
    processFileUpload,
  } = useImportFile({
    fileType: 'import-business',
    onLoading: () => {
      setCurrent(PROCESSING_STEP);
    },
    onImportRequestError: () => {
      setCurrent(OPTIONS_STEP, 600);
    },
    onValidate: () => {
      closeDrawer();
      onStartImport();
    },
  });

  const handleDownloadModel = () => {
    downloadImportModel('clientes');
    setImportItemsDocumentationModal(true);
  };

  useEffect(() => {
    goToStepSafely(current, 600);
  }, [current]);

  return (
    <Drawer
      open={visible}
      width="90%"
      className="client-drawer"
      onClose={closeDrawer}
      closable={current === OPTIONS_STEP}
      title={current === OPTIONS_STEP && 'Adicionar clientes'}
      hideFooter
    >
      <div className="drawer-body">
        <Carousel
          ref={(ref) => {
            carouselRef.current = ref;
          }}
          dots={false}
          swipeToSlide={false}
          swipe={false}
          draggable={false}
          accessibility={false}
        >
          {/* ========= OPTIONS STEP ========== */}
          <div className="carousel-content">
            <Form
              className="form-footer"
              form={form}
              layout="vertical"
            >
              {checkForBusinessAction('create-client-with-no-loan') && (
                <Row>
                  <Col span={24}>
                    <Item>
                      <OptionCard
                        label="Inclusão de cliente individual"
                        caption="Adicione um novo cliente e sócio principal"
                        button={() => history.push(CREATE_CLIENT_WITH_NO_LOAN_PATH)}
                        primaryBtnClassName="only-new-client"
                      />
                    </Item>
                  </Col>
                </Row>
              )}

              {checkForBusinessAction('create-client-with-loan') && (
                <Row>
                  <Col span={24}>
                    <Item>
                      <OptionCard
                        label="Inclusão de cliente e solicitação de empréstimo"
                        caption="Adicione um novo cliente e solicite um empréstimo"
                        button={() => history.push(CREATE_CLIENT_WITH_LOAN_PATH)}
                        primaryBtnClassName="new-client"
                      />
                    </Item>
                  </Col>
                </Row>
              )}

              {checkForBusinessAction('client-upload') && (
                <>
                  <Row>
                    <Col span={24}>
                      <Item name="uploadcardjson" rules={[{ required: true, message: 'Por favor, insira o comprovante de endereço' }]}>
                        <OptionCard
                          accept="application/json"
                          upload
                          multiple
                          label="Inclusão de grupo de clientes por arquivo .JSON"
                          action={processFileUpload}
                          loading={loadingUploadFile}
                          caption="Adicione diversos clientes de uma só vez através do nosso arquivo padrão .JSON com limite de 250MB"
                          primaryBtnClassName="import-client"
                          secondaryBtn={{
                            icon: <DownloadOutlined />,
                            text: 'Baixar arquivo',
                            onClick: handleDownloadModel,
                          }}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Item name="uploadcardcsv" rules={[{ required: true, message: 'Por favor, insira o comprovante de endereço' }]}>
                        <OptionCard
                          accept=".csv"
                          upload
                          label="Inclusão de grupo de clientes por arquivo .CSV"
                          action={processFileUpload}
                          loading={loadingUploadFile}
                          caption="Adicione diversos clientes de uma só vez através do nosso arquivo padrão .CSV com limite de 250MB"
                          primaryBtnClassName="import-client"
                          secondaryBtn={{
                            icon: <DownloadOutlined />,
                            text: 'Baixar arquivo',
                            onClick: () => downloadImportCsvModel('clientes'),
                          }}
                        />
                      </Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </div>

          {/* ========= PROCESSING ========== */}
          <div className="carousel-content">
            <ProcessMessage centered />
          </div>
        </Carousel>
      </div>

      <Modal
        visible={importItemsDocumentationModal}
        closable={false}
        width={720}
        centered
        footer={(
          <Button
            onClick={() => setImportItemsDocumentationModal(false)}
          >
            Fechar
          </Button>
        )}
      >
        <h3>Parâmetros do arquivo JSON</h3>

        <Row gutter={14}>
          <Col md={12}>
            <ObjectInfo
              value={BUSINESSES_IMPORT_JSON_DOCS_1}
            />
          </Col>
          <Col md={12} className="import-items-documentation-second-column">
            <ObjectInfo
              value={BUSINESSES_IMPORT_JSON_DOCS_2}
            />
          </Col>
        </Row>

      </Modal>
    </Drawer>
  );
};

ClientDrawer.propTypes = {
  onClose: PropTypes.func,
  onStartImport: PropTypes.func,
  visible: PropTypes.bool,
};

export default ClientDrawer;
