import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  message,
  notification,
} from 'antd';
import { useMutation } from '@apollo/client';
import { formatError } from 'utils';
import {
  Button,
  Input,
} from 'components/gyramais';
import { LeftOutlined } from '@ant-design/icons';
import { RESET_PASSWORD } from './graphql';

import './styles.less';

const { Item, useForm } = Form;

const LostPasswordForm = () => {
  const history = useHistory();
  const [form] = useForm();

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => notification.success({ message: 'O e-mail foi enviado com sucesso.' }),
    onError: (error) => message.error(formatError(error)),
  });

  const onFinish = async (values) => {
    await form.validateFields();
    await resetPassword({ variables: values });
    form.resetFields();
  };

  return (
    <div id="lost-password-component">
      <Button
        link
        onClick={() => history.goBack()}
        icon={(<LeftOutlined />)}
      >
        Voltar
      </Button>

      <br />

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="form-footer"
      >
        <h2>
          Esqueceu sua
          {' '}
          <span className="secondary-text">senha?</span>
        </h2>

        <p className="justified-text">
          <b>
            Digite seu endereço de e-mail abaixo.
            Caso haja uma conta com o endereço inserido,
            você receberá um link por e-mail para criar uma nova senha.
          </b>
        </p>

        <br />

        <Item name="email" rules={[{ required: true, message: 'Por favor insira o seu e-mail.' }]}>
          <Input id="email" placeholder="E-mail" />
        </Item>

        <Button
          block
          loading={loading}
          htmlType="submit"
          className="button-lost-password"
        >
          Redefinir senha
        </Button>

      </Form>
    </div>
  );
};

export default LostPasswordForm;
