import React, { memo, useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Select,
} from 'antd';
import {
  Button,
  Input,
} from 'components/gyramais';
import { useQuery } from '@apollo/client';
import { LOAN_STATUSES } from './graphql';

const { Item } = Form;
const { Option } = Select;

const BusinessFilterForm = ({
  forceStatusValue,
  onSearch,
}) => {
  const [form] = Form.useForm();

  const [filteredStatuses, setFilteredStatuses] = useState();
  useQuery(LOAN_STATUSES, {
    onCompleted: ({ loanStatuses }) => {
      const filtered = loanStatuses.filter((status) => {
        if (status?.parent?.value === 'awaiting-re-evaluation') {

          return false;
        }

        return true;
      });

      setFilteredStatuses([...filtered]);
    },
  });

  useEffect(() => {
    if (forceStatusValue) {
      form.setFieldValue('statusValue', forceStatusValue);
      form.setFieldValue('cnpj', undefined);
      onSearch({
        statusValue: forceStatusValue,
      });
    }
  }, [forceStatusValue]);

  return (
    <Form
      form={form}
      layout="vertical"
      className="businesses-filter-form"
      onFinish={(values) => {
        const {
          cnpj,
          statusValue,
        } = values;
        onSearch({
          cnpj,
          statusValue,
        });
      }}
    >
      <h6>
        <b className="link-text">
          Filtros
        </b>
      </h6>
      <Row gutter={12} justify="space-between">
        <Col xs={24} sm={24} md={12}>
          <Item name="cnpj">
            <Input placeholder="CNPJ do cliente" />
          </Item>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Item name="statusValue">
            <Select
              className="gyramais-input"
              defaultValue=""
              placeholder="Selecione um status"
              loading={!filteredStatuses}
            >
              <Option value="">Todos</Option>
              {filteredStatuses?.map((element) => (
                <Option key={element.id} value={element.value}>{element.name}</Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col xs={24} sm={24} md={4}>
          <Button
            block
            htmlType="submit"
          >
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(BusinessFilterForm);
