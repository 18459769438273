import React, { useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Table,
  Spin,
  Divider,
} from 'antd';
import {
  CreateUserDrawer,
  FindUserDrawer,
  OptionCard,
  LogoEditorDrawer,
  AppEditorDrawer,
  LandingEditorDrawer,
} from 'components';
import {
  SubHeader,
  Button,
} from 'components/gyramais';
import {
  EyeOutlined,
} from '@ant-design/icons';
import {
  formatDate,
} from 'utils';
import { useActionContext } from 'contexts/ActionContext';
import { useQuery } from '@apollo/client';
import {
  ME,
} from './graphql';
import './styles.less';

const {
  Content,
} = Layout;

const ConfigScreen = () => {
  const {
    checkForBusinessAction,
    loading: loadingActions,
  } = useActionContext();
  const [findUser, setFindUser] = useState(false);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState();
  const [logoEditorDrawer, setLogoEditorDrawer] = useState(false);
  const [appEditorDrawer, setAppEditorDrawer] = useState(false);
  const [landingEditorDrawer, setLandingEditorDrawer] = useState(false);

  const {
    data: {
      me: {
        currentBusiness: {
          id,
          appColors,
          landingColors,
          lightLogo,
          darkLogo,
          partnerUsers = [],
        } = {},
      } = {},
    } = {},
    refetch,
    loading,
  } = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
  });

  const partnerUserPreset = (roles) => (
    roles?.find((role) => role.name.startsWith('preset-'))
  );

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Perfil',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles) => (<span>{partnerUserPreset(roles)?.displayName}</span>),
    },
    {
      title: 'Adicionado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id) => (
        <div>
          <Button
            link
            id="edit-partner-user-button"
            icon={(<EyeOutlined />)}
            onClick={() => {
              setUser(partnerUsers.find((i) => i.id === id));
              setVisible(true);
            }}
          >
            Ver detalhes
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Layout id="config-screen">
      <SubHeader
        title="Configurações"
        buttonsPlacement="right"
        buttons={(
          <Button onClick={() => setFindUser(true)} id="add-partner-user-button">+ Usuário</Button>
        )}
      />

      {loadingActions ? (
        <Content className="loading-wrapper">
          <Spin />
        </Content>
      ) : (
        <Content>
          <Row>
            {checkForBusinessAction('white-label') && (
            <Col className="option-cards-group" span={24}>
              <OptionCard
                label="Logo clara e logo escura"
                caption="Adicione uma logo para cor de fundo claro e outra para cor de fundo escuro"
                button={() => setLogoEditorDrawer(true)}
                buttonLabel="Editar logo"
                primaryBtnClassName="edit-logo"
              />
              <OptionCard
                label="Visual do Portal"
                caption="Edite o visual do Portal"
                button={() => setAppEditorDrawer(true)}
                buttonLabel="Editar visual"
                primaryBtnClassName="edit-app-color"
              />
              <OptionCard
                label="Visual da Landing Page"
                caption="Edite o visual do landing"
                button={() => setLandingEditorDrawer(true)}
                buttonLabel="Editar visual"
                primaryBtnClassName="edit-landing-color"
              />
            </Col>
            )}

            <Divider className="config-screen-divider" />

            <Col span={24}>
              <div className="users-table-title">{`Usuários (${partnerUsers.length})`}</div>
              <Table
                dataSource={partnerUsers.map((i) => ({ ...i, key: i.id }))}
                columns={columns}
                size="small"
                pagination={false}
                scroll={{ x: 'max-content' }}
                locale={{ emptyText: 'Sem usuários adicionados' }}
              />
            </Col>
            {(!checkForBusinessAction('white-label')) && (
            <>
              <h2>Você não tem permissão para realizar ações nessa tela</h2>
              <br />
              <span>
                Para saber mais sobre as ações ou solicitar acesso,
                entre em contato com o
                <b className="highlight-text">Suporte de Parceiros Gyramais</b>
              </span>
            </>
            )}
          </Row>
        </Content>
      )}

      <FindUserDrawer
        visible={findUser}
        refetch={refetch}
        businessId={id}
        onAddNewUser={(cpf) => {
          setUser({ cpf });
          setFindUser(false);
          setVisible(true);
        }}
        onClose={() => setFindUser(false)}
      />

      <CreateUserDrawer
        visible={visible}
        refetch={refetch}
        user={user}
        onClickAddNewUser={() => {
          setUser();
          setVisible(false);
          setFindUser(true);
        }}
        onClose={() => {
          setVisible(false);
          setUser();
        }}
        businessId={id}
      />

      <LogoEditorDrawer
        darkLogo={darkLogo}
        lightLogo={lightLogo}
        refetch={refetch}
        businessId={id}
        visible={logoEditorDrawer}
        onClose={() => setLogoEditorDrawer(false)}
      />

      <AppEditorDrawer
        darkLogo={darkLogo}
        lightLogo={lightLogo}
        businessColors={appColors}
        businessId={id}
        refetch={refetch}
        loading={loading}
        visible={appEditorDrawer}
        onClose={() => setAppEditorDrawer(false)}
      />

      <LandingEditorDrawer
        businessColors={landingColors}
        businessId={id}
        refetch={refetch}
        loading={loading}
        visible={landingEditorDrawer}
        onClose={() => setLandingEditorDrawer(false)}
      />

    </Layout>
  );
};

export default ConfigScreen;
