import React from 'react';
import {
  Row, Col,
} from 'antd';
import PropTypes from 'prop-types';
import { Images } from 'assets';
import './styles.less';

const {
  GzaoCortado,
} = Images;

const HorizontalSplit = ({ children }) => (
  <Row id="split-component">
    <Col md={24} lg={12} className="left-side">
      <Row>
        <Col xs={0} sm={0} md={24}>
          <img src={GzaoCortado} alt="gzao cortado" className="g-mais-image" />
        </Col>
        <Col xs={24} sm={24} md={0}>
          <img src={GzaoCortado} alt="gzao cortado" className="g-mais-image" />
        </Col>
      </Row>
    </Col>
    <Col md={24} lg={12} className="right-side">
      {children}
      <br />
      <div className="copyright">
        {new Date().getFullYear()}
        {' '}
        © GYRA+ - Todos os direitos reservados.
      </div>
    </Col>
  </Row>
);

HorizontalSplit.propTypes = {
  children: PropTypes.element,
};

export default HorizontalSplit;
