import { gql } from '@apollo/client';

const SAVE_CUSTOMER = gql`
  mutation saveCustomer(
    $id: ID
    $name: String
    $legalName: String
    $cnpj: String
    $email: String
    $phoneNumber: String
    $cpf: String
    $fullName: String
    $platformType: String
    $sector: String
    $segment: String
    $employeesNumber: Int,
    $revenueAmount: Float
    $website: String,
  ) {
    saveCustomer(
      id: $id
      name: $name
      legalName: $legalName
      cnpj: $cnpj
      email: $email
      phoneNumber: $phoneNumber
      cpf: $cpf
      fullName: $fullName
      platformType: $platformType
      sector: $sector
      segment: $segment
      employeesNumber: $employeesNumber
      revenueAmount: $revenueAmount
      website: $website
    ) {
      id
    }
  }
`;

export {
  SAVE_CUSTOMER,
};
export default SAVE_CUSTOMER;
