/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Row,
  Col,
  Statistic,
  Divider,
  Dropdown,
  Menu,
  Tag,
} from 'antd';
import { StatusTag } from 'components';
import { formatDate } from 'utils';
import {
  BarcodeOutlined, DollarOutlined, MoreOutlined, EyeOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const InstallmentCard = ({
  installment: {
    id,
    number,
    totalAmount,
    paidAmount,
    movements,
    dueDate,
    invoices = [],
    values: {
      penalty = {},
      status: {
        name,
        value,
        colors,
      } = {},
    },
    loan,
  } = {},
  style,
  detailed,
  onClickInvoice,
  onClickPayment,
}) => {
  const history = useHistory();
  const title = name;
  const backgroundColor = colors && colors[0];
  const confirmedMovements = movements?.filter(({ canceledAt }) => !canceledAt);
  const lastPayment = confirmedMovements?.[confirmedMovements.length - 1];
  return (
    <Card
      style={style}
      bodyStyle={{ padding: 10 }}
      hoverable={detailed}
      onClick={(e) => {
        // eslint-disable-next-line no-constant-condition
        if (false) {
          if (detailed === true) {
            if (e.ctrlKey || e.metaKey) window.open(`${window.location.origin}/emprestimos/${loan.id}`, '_blank');
            else history.push(`/emprestimos/${loan.id}`);
          }
        }
      }}
    >
      <Row style={{ marginBottom: 6 }} align="middle">
        <Col>
          <div style={{
            border: '1px solid gray',
            width: 30,
            height: 30,
            borderRadius: 15,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            {number}
          </div>
        </Col>
        <Col style={{ marginLeft: 10 }}>
          <div>
            <Row>Vencimento</Row>
            <b>{formatDate(new Date(dueDate))}</b>
          </div>
        </Col>
        <Col style={{ marginLeft: 'auto', display: 'flex' }}>
          {!['paid', 'renegotiated'].includes(value)
        && (
        <>
          <Button
            icon={<BarcodeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              onClickInvoice({
                id,
                totalAmount,
                paidAmount,
                penalty,
                lastPaidInvoice: lastPayment,
                dueDate,
              });
            }}
          />
          <Button
            style={{ marginLeft: 10 }}
            icon={<DollarOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              onClickPayment({
                id,
                totalAmount,
                paidAmount,
                penalty,
                lastPaidInvoice: lastPayment,
                dueDate,
              });
            }}
          />
        </>
        )}
          {invoices.length > 0 && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              trigger={['click']}
              overlayStyle={{ position: 'fixed' }}
              overlay={(
                <Menu>
                  {invoices.map(({
                    id, url, dueDate, amount, paidAt, canceledAt,
                  }) => (
                    <Menu.Item key={id}>
                      <Row gutter={10} style={{ display: 'flex', alignItems: 'center' }}>
                        {url && (
                        <Col>
                          <Button
                            icon={<EyeOutlined />}
                            shape="circle"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`${url}`, '_blank');
                            }}
                          />
                        </Col>
                        )}
                        <Col>
                          <Tag
                            color={paidAt ? '#2ECC40' : (canceledAt ? '#FF4136' : '#FFDC00')}
                            style={{
                              fontWeight: 'bold', display: 'flex', height: 24, alignItems: 'center',
                            }}
                          >
                            {paidAt ? 'Pago' : canceledAt ? 'Cancelado' : 'Pendente'}
                          </Tag>
                        </Col>
                        <Col>
                          Valor
                          <Row>
                            <b>
                              <Statistic
                                decimalSeparator=","
                                groupSeparator="."
                                value={amount}
                                precision={2}
                                prefix="R$"
                              />
                            </b>
                          </Row>
                        </Col>
                        <Col>
                          Vencimento
                          <Row><b>{formatDate(new Date(dueDate))}</b></Row>
                        </Col>
                        <Col>
                          Pago Em
                          <Row>
                            <b>{paidAt && formatDate(new Date(paidAt))}</b>
                          </Row>
                        </Col>
                      </Row>
                    </Menu.Item>
                  ))}
                </Menu>
            )}
            >
              <Button
                style={{ marginLeft: 10 }}
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </div>
          )}
        </Col>
      </Row>
      <StatusTag title={title} backgroundColor={backgroundColor} />
      <Row
        gutter={10}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        <Col xs={24} sm={24} md={4} lg={3}>
          <div className="card-info">
            <Row>Valor</Row>
            <b>
              <Statistic
                decimalSeparator=","
                groupSeparator="."
                value={totalAmount}
                precision={2}
                prefix="R$"
              />
            </b>
          </div>
        </Col>
        {value === 'paid' && (
          <>
            <Col className="desktop-only">
              <Divider type="vertical" style={{ height: 44 }} />
            </Col>
            <Col xs={24} sm={24} md={4} lg={3}>
              <div className="card-info">
                <Row>Valor Pago</Row>
                <b>
                  <Statistic
                    decimalSeparator=","
                    groupSeparator="."
                    value={paidAmount}
                    precision={2}
                    prefix="R$"
                  />
                </b>
              </div>
            </Col>
          </>
        )}
        {value !== 'paid' && penalty.debitDays > 0
        && (
        <>
          <Col className="desktop-only">
            <Divider type="vertical" style={{ height: 44 }} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={3}>
            <div className="card-info">
              <Row>Juros / Multa</Row>
              <b>
                <Statistic
                  decimalSeparator=","
                  groupSeparator="."
                  value={penalty.totalAmount}
                  precision={2}
                  prefix="R$"
                />
              </b>
            </div>
          </Col>
          <Col className="desktop-only">
            <Divider type="vertical" style={{ height: 44 }} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={3}>
            <div className="card-info">
              <Row>Dias em Atraso</Row>
              <b>
                {penalty.debitDays}
              </b>
            </div>
          </Col>
        </>
        )}
        {detailed === true && (
        <>
          <Col className="desktop-only">
            <Divider type="vertical" style={{ height: 44 }} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={3}>
            <div className="card-info">
              Empréstimo
              <Row>
                <b>{loan.number}</b>
              </Row>
            </div>
          </Col>
          <Col className="desktop-only">
            <Divider type="vertical" style={{ height: 44 }} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={9}>
            <div className="card-info">
              Cliente
              <Row><b>{loan.business.number}</b></Row>
              <Row className="desktop-only"><b>{loan.business.name}</b></Row>
            </div>
          </Col>
          <Col className="mobile-only" style={{ marginLeft: 'auto', paddingRight: 10, textAlign: 'right' }}>
            <b>{loan.business.name}</b>
          </Col>
        </>
        )}
      </Row>
    </Card>
  );
};

InstallmentCard.propTypes = {
  installment: PropTypes.shape({}),
  style: PropTypes.shape({}),
  detailed: PropTypes.bool,
  onClickInvoice: PropTypes.func,
  onClickPayment: PropTypes.func,
};

export default memo(InstallmentCard);
