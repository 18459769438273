/* eslint-disable react/prop-types */
import React from 'react';
import {
  Drawer as AntDrawer,
} from 'antd';
import {
  CloseOutlined,
} from '@ant-design/icons';
import Button from '../Button';

import './styles.less';

/**
 * @type {React.FC<import('antd/lib/drawer').DrawerProps & {
 *  primaryButton: Button.propTypes
 *  backButton: Button.propTypes
 *  title: string | React.ReactNode
 *  maxWidth?: any
 *  onClose: Function
 *  hideFooter?: boolean
 *  extraFooterComponents: Object
 * }}
 */
const Drawer = ({
  children,
  primaryButton,
  backButton,
  className,
  onClose,
  title,
  maxWidth,
  drawerStyle,
  closable = true,
  hideFooter = false,
  extraFooterComponents,
  width = '90%',
  ...props
}) => {
  // por padrão é visível
  const isPrimaryButtonVisible = primaryButton !== null
    && (primaryButton === undefined
      || primaryButton?.visible === undefined
      || primaryButton?.visible
    );

  const isBackButtonVisible = backButton && backButton?.visible;

  const shouldRenderFooter = !hideFooter && (
    isPrimaryButtonVisible || isBackButtonVisible || extraFooterComponents
  );

  return (
    <AntDrawer
      {...props}
      width={width}
      closable={false}
      contentWrapperStyle={{
        ...drawerStyle,
        maxWidth,
      }}
      className={`gyra-drawer ${className || ''}`}
    >
      <div className="gyra-drawer-body">
        {closable && (
          <Button
            link
            onClick={onClose}
            className="close-button"
          >
            <CloseOutlined className="close-icon" />
            Fechar
          </Button>
        )}

        <div
          className={`gyra-drawer-content ${!shouldRenderFooter ? 'no-footer' : ''}`}
        >
          {title && (
            <h2 className="gyra-drawer-title no-margin-bottom">
              {title}
            </h2>
          )}

          {children}
        </div>

        {shouldRenderFooter && (
          <div className={`gyra-drawer-footer ${!isBackButtonVisible && 'no-back-btn'}`}>
            {isBackButtonVisible ? (
              <Button
                link
                {...backButton}
                className={`back-button ${backButton?.className}`}
              >
                {backButton?.children || '< Voltar'}
              </Button>
            ) : (
              <>
                {extraFooterComponents}
              </>
            )}

            {isPrimaryButtonVisible && (
              <Button
                color="primary"
                {...primaryButton}
              >
                {primaryButton?.children || 'Confirmar e prosseguir'}
              </Button>
            )}
          </div>
        )}
      </div>
    </AntDrawer>
  );
};

export default Drawer;
