import React, { memo } from 'react';

const NotFound = () => (
  <div>
    <h1>404</h1>
    <h5>OOPS! ESSA PÁGINA NÃO FOI ENCONTRADA</h5>
    <hr />
    Parece que nada foi encontrado nessa localização.
  </div>
);

export default memo(NotFound);
