import { gql } from '@apollo/client';

const SET_COLORS = gql`
  mutation setColors (
    $colors:[String!]!
  ) {
    setColors(
      colors:$colors
    ) 
  }
`;

const SET_LOGO = gql`
  mutation uploadLogo(
    $fileId:ID!
  ) {
    uploadLogo(
      fileId:$fileId
    ) 
  }
`;

const CREATE_LOANS = gql`
  mutation createLoans {
    createLoans
  }
`;

export {
  SET_COLORS,
  SET_LOGO,
  CREATE_LOANS,
};
