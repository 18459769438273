import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
  Form,
  Modal,
  InputNumber,
  Checkbox,
  message,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { INSTALLMENT } from 'services/graphql/queries';
import { SAVE_INVOICE } from 'services/graphql/mutations';
import { formatDate, formatError } from 'utils';
import moment from 'moment';

const { success } = Modal;
const { Item, useForm } = Form;

// Cannot select days before today
const disabledDate = (current) => current && current < moment().subtract(1, 'days').endOf('day');

const InvoiceModal = ({
  installment,
  onCancel,
  onOk,
}) => {
  const [form] = useForm();
  const [date, setDate] = useState(null);
  useQuery(INSTALLMENT, {
    fetchPolicy: 'network-only',
    partialRefetch: true,
    variables: { id: installment && installment.id, date },
    skip: !installment,
    onCompleted: ({
      installment: {
        totalAmount,
        values: {
          penalty,
        },
        movements,
      } = {},
    } = {}) => {
      const penaltyAmount = ((penalty && penalty.totalAmount) || 0);
      const confirmedMovements = movements?.filter(({ canceledAt }) => !canceledAt);
      const lastPayment = confirmedMovements?.[confirmedMovements.length - 1];
      const amount = lastPayment
        ? lastPayment.remainingAmount.totalAmount + penaltyAmount : totalAmount + penaltyAmount;
      form.setFieldsValue({
        amount: amount.toFixed(2),
      });
    },
  });
  const [saveInvoice, { loading: confirmLoading }] = useMutation(SAVE_INVOICE, {
    onCompleted: (data) => {
      success({
        centered: true,
        title: 'O boleto foi gerado com sucesso. Dentro de alguns minutos você receberá o email.',
      });
      onOk(data);
    },
    onError: (error) => message.error(formatError(error)),
  });
  useEffect(() => {
    // if (id) {
    //   if (!date) {
    //     const auxDate1 = new Date();
    //     const dueDate = new Date(
    //       auxDate1.getFullYear(),
    //       auxDate1.getMonth(),
    //       auxDate1.getDate(), 0, 0, 0,
    //     );
    //     setDate(dueDate);
    //   }
    // } else if (date) setDate(null);
  });
  return (
    <Modal
      visible={Boolean(installment)}
      closable={false}
      centered
      title={`Deseja enviar o boleto referente ao vencimento de ${installment && formatDate(new Date(installment.dueDate))} para o e-mail do cliente?`}
      confirmLoading={confirmLoading}
      cancelText="Cancelar"
      onCancel={() => onCancel()}
      onOk={async () => {
        const { amount, installmentPaid } = await form.validateFields();
        saveInvoice({
          variables: {
            installmentId: installment && installment.id,
            amount: Number(amount),
            installmentPaid,
            dueDate: date,
          },
        });
      }}
    >
      <Form
        form={form}
        initialValues={{
          dueDate: moment(),
          installmentPaid: false,
        }}
      >
        <Item
          label="Data de vencimento"
          name="dueDate"
          rules={[{
            required: true, message: 'Por favor escolha a data de vencimento.',
          }]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            onChange={(value) => {
              const auxDate1 = value && value.toDate();
              const dueDate = new Date(
                auxDate1.getFullYear(),
                auxDate1.getMonth(),
                auxDate1.getDate(), 0, 0, 0,
              );
              setDate(dueDate);
            }}
            disabledDate={disabledDate}
          />
        </Item>
        <Item
          label="Valor"
          name="amount"
          rules={[{
            required: true, message: 'Por favor insira o valor.',
          }]}
        >
          <InputNumber
            placeholder="Valor (R$)"
            style={{ width: '100%' }}
            min={0}
            formatter={(value) => `R$ ${value}`}
            parser={(value) => value.replace(/[R$\s?|(*)]/g, '')}
            autoFocus
          />
        </Item>
        <Item name="installmentPaid" valuePropName="checked">
          <Checkbox
            style={{ marginBottom: 20 }}
          >
            Parcela Paga
          </Checkbox>
        </Item>
      </Form>
    </Modal>
  );
};

InvoiceModal.propTypes = {
  installment: PropTypes.shape({
    id: PropTypes.string,
    dueDate: PropTypes.string,
    totalAmount: PropTypes.number,
    paidAmount: PropTypes.number,
  }),
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default InvoiceModal;
