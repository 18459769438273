let timeoutRef;

const useDrawerHelpers = ({
  carouselRef,
}) => {

  const _goToStepSafely = (index, count = 0) => {
    if (count > 50) {
      // provavelmente num loop infinito
      return;
    }

    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    if (carouselRef?.current) {
      carouselRef.current.goTo(index);
      return;
    }

    timeoutRef = setTimeout(() => _goToStepSafely(index, count + 1), 100);
  };

  // se drawer não esta aberto,
  // demora um pouco para o ref estar ativo
  const goToStepSafely = async (index, timeout) => {
    await new Promise((res) => setTimeout(res, timeout));

    _goToStepSafely(index);
  };

  return {
    goToStepSafely,
  };
};

export default useDrawerHelpers;
