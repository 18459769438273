import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import {
  Button,
} from 'components/gyramais';
import { ImportInfoImage } from 'assets/images';
import './styles.less';

const ImportInfosModal = ({
  visible,
  onClose,
}) => (
  <Modal
    width={700}
    visible={visible}
    onCancel={onClose}
    centered
    closable={false}
    title="Pode deixar o resto com a gente! Enviaremos um email quando tudo estiver pronto"
    footer={(
      <Button color="secondary" onClick={onClose}>Entendido</Button>
    )}
    className="import-alert-email-modal"
  >
    <div id="import-infos-modal-content">
      <img src={ImportInfoImage} alt="Import info" className="import-infos-modal-image" />
      <div className="import-infos-modal-text justified-text">
        Pode relaxar! Seu upload será feito em segundo plano e, quando tudo estiver
        pronto, iremos lhe enviar um email. Você pode navegar livremente pela sua
        dashboard enquanto o envio do arquivo estiver sendo processado!
      </div>
    </div>
  </Modal>
);

ImportInfosModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportInfosModal;
