import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Modal,
  Radio,
} from 'antd';

const { Item, useForm } = Form;

const InvoiceModal = ({
  onCancel,
  onOk,
  visible,
}) => {
  const [form] = useForm();
  const [extension, setExtension] = useState('');
  return (
    <Modal
      visible={visible}
      closable={false}
      centered
      title="Qual o formato desejado para o arquivo?"
      // confirmLoading={confirmLoading}
      cancelText="Cancelar"
      okButtonProps={{ id: 'confirm-export-installments' }}
      onCancel={() => onCancel()}
      onOk={async () => {
        const { fileExtension } = await form.validateFields();
        onOk(fileExtension);
      }}
    >
      <Form
        form={form}
        initialValues={{
          fileExtension: '',
        }}
      >
        <Item
          name="fileExtension"
          rules={[{
            required: true, message: 'Por favor selecione uma extensão.',
          }]}
        >
          <Radio.Group
            onChange={(e) => setExtension(e.target.value)}
            value={extension}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Radio value="csv" id="export-csv-file">
              CSV
            </Radio>
            <Radio style={{ marginTop: 10 }} id="export-xlsx-file" value="xlsx">
              XLSX
            </Radio>
          </Radio.Group>
        </Item>
      </Form>
    </Modal>
  );
};

InvoiceModal.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
};

export default InvoiceModal;
