import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  Drawer,
} from 'components/gyramais';
import { OptionCard } from 'components';

const { Item } = Form;
const { useForm } = Form;

const ClientDrawer = ({
  visible,
  onClose,
}) => {
  const history = useHistory();
  const [form] = useForm();

  return (
    <Drawer
      visible={visible}
      width="90%"
      className="loan-drawer"
      onClose={onClose}
      title="Adicionar empréstimos"
      hideFooter
    >
      <Form
        className="form-footer"
        form={form}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Item>
              <OptionCard
                label="Inclusão de empréstimo único"
                caption="Solicite empréstimo para um cliente individual"
                buttonId="create-unique-loan-button"
                buttonLabel="Incluir novo empréstimo"
                button={() => history.push('/adicionar-emprestimo')}
                primaryBtnClassName="new-loan"
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

ClientDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default ClientDrawer;
