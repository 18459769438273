import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
} from 'antd';

import {
  useQuery,
  useMutation,
} from '@apollo/client';
import UploadCard from 'components/cards/UploadCard';

import BUSINESS from '../graphql/business.gql';
import SAVE_BUSINESS from '../graphql/saveBusiness.gql';

const { Item } = Form;

const BusinessDocuments = ({
  businessId,
  loading: parentLoading,
}) => {
  const [raisPersonFile, setRaisPersonFile] = useState({});
  const [cndScEnvironmentalCrimesFile, setCndScEnvironmentalCrimes] = useState({});

  const {
    data: {
      business,
    } = {},
    refetch,
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    fetchPolicy: 'cache-only',
    onCompleted: ({ business }) => {
      const additionalFiles = [...business.additionalFiles ?? []];

      additionalFiles
        ?.reverse()
        ?.forEach((file) => {
          if (
            file.parent?.name === 'raisPerson'
          && file.id !== raisPersonFile.id
          ) {
            setRaisPersonFile(file);
          }

          if (
            file.parent?.name === 'cndScEnvironmentalCrimes'
          && file.id !== cndScEnvironmentalCrimesFile.id
          ) {
            setCndScEnvironmentalCrimes(file);
          }
        });
    },
  });

  const [saveBusiness] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
      refetch();
    },
  });

  const files = [{
    value: 'bylawsFileId',
    file: business?.bylaws,
    name: 'Documento contratual da empresa',
  }, {
    value: 'proofOfAddressFileId',
    file: business?.address?.proofOfAddress,
    name: 'Comprovante de endereço',
  }, {
    value: 'badescRaisPersonFileId',
    file: raisPersonFile,
    name: 'RAIS e-social',
  }, {
    value: 'badescCndScEnvironmentalCrimesFileId',
    file: cndScEnvironmentalCrimesFile,
    name: 'CND - Crimes Ambientais SC',
  }];

  const loading = parentLoading || !business;

  return (
    <Form>
      <Row gutter={12}>
        {files.map(({ value, name, file }) => (
          <Col xs={24} sm={24} key={value}>
            <Item
              name={value}
              rules={[{ required: true, message: `Por favor, insira o ${name}` }]}
            >
              <UploadCard
                id="bylaws-upload"
                accept="image/png,image/jpeg,application/pdf"
                loading={loading}
                label={name}
                actualFile={file}
                onCompleted={async ({ id: fileId }) => {
                  const variables = {
                    id: business.id,
                  };
                  variables[value] = fileId;

                  saveBusiness({ variables });
                }}
              />
            </Item>
          </Col>
        ))}

      </Row>
    </Form>
  );
};

export default BusinessDocuments;
