import { gql } from '@apollo/client';

const SAVE_INTEGRATION = gql`
  mutation saveIntegration(
    $businessId: ID!
    $typeId: ID!
    $credentials: String!
  ) {
    saveIntegration(
      businessId: $businessId
      typeId: $typeId
      credentials: $credentials
    ) {
      id
      credentials
      type {
        id
        icon {
          id
          url
        }
        name
      }
    }
  }
`;

const REMOVE_INTEGRATION = gql`
  mutation removeIntegration($id: ID!) {
    removeIntegration(id: $id) {
      id
    }
  }
`;

export {
  SAVE_INTEGRATION,
  REMOVE_INTEGRATION,
};
