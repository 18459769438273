import { gql } from '@apollo/client';

const INSTALLMENTS = gql`
  query installments(
    $installmentStatusesValues: [String]
    $loanId: ID
    $loanNumber: Int
    $businessNumber: Int
    $startDueDate: Date
    $endDueDate: Date
    $limit: Int
    $offset: Int
    $sort: [SortInput!]
    $fromPartner: Boolean
  ) {
    installments (
      installmentStatusesValues: $installmentStatusesValues
      loanId: $loanId
      loanNumber: $loanNumber
      businessNumber: $businessNumber
      startDueDate: $startDueDate
      endDueDate: $endDueDate
      limit: $limit
      offset: $offset
      sort: $sort
      fromPartner: $fromPartner
    ) {
      id
      dueDate
      amount
      number
      paidAmount
      movements {
        id
        date
        canceledAt
        remainingAmount {
          principal
          interest
          totalAmount
          penalty {
            iofAmount,
            interestAmount,
            amount,
            moraAmount,
            debitDays,
          }
        }
      }
      values {
        status {
          id
          name
          value
          colors
        }
        penalty {
          amount
          totalAmount
          moraAmount
          iofAmount
          interestAmount
          debitDays
        }
      }
      totalAmount
      invoices {
        id
        paidAt
        canceledAt
        url
        amount
        dueDate
      }
      loan {
        id
        number
        partner {
          id
          name
          legalName
          cnpj
        }
        portfolio {
          id
          name
        }
        business {
          id
          number
          name
        }
      }
    }
  }
`;

const INSTALLMENT = gql`
  query installment(
    $id: ID!
    $date: Date
  ) {
    installment (
      id: $id
    ) {
      id
      dueDate
      totalAmount
      paidAmount
      number
      values(date: $date) {
        penalty {
          amount
          totalAmount
          moraAmount
          iofAmount
          interestAmount
          debitDays
        }
      }
    }
  }
`;

export {
  INSTALLMENTS,
  INSTALLMENT,
};
