import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Button,
} from 'antd';
import { useHistory } from 'react-router-dom';

const BusinessCard = ({
  business: {
    id,
    name,
    legalName,
    number,
    cnpj,
    partner,
  } = {},
}) => {
  const history = useHistory();
  return (
    <Card bodyStyle={{ padding: 10 }}>
      <Button
        style={{ marginBottom: 10 }}
        block
        size="small"
        type="primary"
        onClick={(e) => {
          if (e.ctrlKey || e.metaKey) window.open(`${window.location.origin}/empresas/${id}`, '_blank');
          else history.push(`/empresas/${id}`);
        }}
      >
        Detalhes da Empresa
      </Button>
      <Row style={{ justifyContent: 'space-between' }}>
        Nome
        <b>
          {name || legalName }
        </b>
      </Row>
      {partner && (
        <Row style={{ justifyContent: 'space-between' }}>
          Parceiro
          <b>
            {partner.name || partner.legalName || 'SEM NOME' }
          </b>
        </Row>
      )}
      <Row style={{ justifyContent: 'space-between' }}>
        Número
        <b>
          {number}
        </b>
      </Row>
      <Row style={{ justifyContent: 'space-between' }}>
        CNPJ
        <b>
          {cnpj}
        </b>
      </Row>
    </Card>
  );
};

BusinessCard.propTypes = {
  business: PropTypes.shape({}),
};

export default BusinessCard;
