import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Slider,
  Card,
  Statistic,
  Divider,
  Skeleton,
} from 'antd';
import { useQuery } from '@apollo/client';
import { formatCurrency } from 'utils';
import {
  SETTINGS,
} from './graphql';
import './styles.less';

const UserAndLoanStep = ({
  onChangeInstallmentsCount,
  onChangeAmount,
  title,
  className = '',
}) => {
  const [installmentsCount, setInternalInstallmentsCount] = useState();
  const [amount, setInternalAmount] = useState(15000);

  const terms = [6, 9, 12, 18];

  const handleSliderChange = (e) => {
    setInternalAmount(e);
    onChangeAmount(e);
  };

  const {
    data: {
      settings: {
        preApprovedLimit = 100000,
      } = {},
    } = {},
  } = useQuery(SETTINGS);

  useEffect(() => {
    onChangeAmount(10000);
  }, []);

  return (
    <Card id="pre-approved-card" className={className}>
      <h3>{title || 'Configurar empréstimo'}</h3>
      <br />

      <div className="slider-values">
        <Statistic
          className="slider-value"
          decimalSeparator=","
          groupSeparator="."
          value={15000}
          precision={2}
          prefix="R$"
        />

        <Statistic
          className="slider-main-value"
          decimalSeparator=","
          groupSeparator="."
          value={amount}
          precision={2}
          prefix="R$"
        />

        <Statistic
          className="slider-value"
          decimalSeparator=","
          groupSeparator="."
          value={preApprovedLimit}
          precision={2}
          prefix="R$"
        />
      </div>

      <Slider
        className="pre-approved-slider"
        defaultValue={50}
        tooltipVisible={false}
        min={15000}
        max={preApprovedLimit}
        value={amount}
        step={1000}
        onChange={handleSliderChange}
      />

      <p>Em quantas vezes?</p>

      <div className="options-container">
        {terms
          .map((term) => (
            <div
              key={term}
              className={`pre-approved-option ${installmentsCount === term ? 'selected' : ''}`}
              role="button"
              tabIndex="-1"
              onClick={() => {
                setInternalInstallmentsCount(term);
                onChangeInstallmentsCount(term);
              }}
              onKeyPress={() => false}
            >
              {term}
            </div>
          ))}
      </div>
      <Divider />
      <div className="card-footer">
        Seu cliente pagará
        {' '}
        {installmentsCount && amount ? (
          <span className="highlight-text">
            {installmentsCount}
            {' '}
            parcelas
          </span>
        ) : (
          <Skeleton.Input className="loan-value-skeleton" size="small" active />
        )}
        {' '}
        de
        {' '}
        {installmentsCount && amount ? (
          <span className="highlight-text">
            {formatCurrency(amount / installmentsCount)}
          </span>
        ) : (
          <Skeleton.Input className="loan-value-skeleton" size="small" active />
        )}
      </div>
    </Card>
  );
};

UserAndLoanStep.propTypes = {
  onChangeInstallmentsCount: PropTypes.func,
  onChangeAmount: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default UserAndLoanStep;
