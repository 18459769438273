import React from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.less';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

/**
 *
 * @param {Object} params
 * @param {import('react').ReactNode} [params.children]
 * @param {boolean} [params.lg]
 * @param {boolean} [params.block]
 * @param {boolean} [params.loading]
 * @param {boolean} [params.disabled]
 * @param {string} [params.color]
 * @param {string} [params.className]
 * @param {Object} [params.style]
 * @param {boolean} [params.link]
 * @param {() => void} [params.onClick]
 * @param {string} [params.htmlType]
 * @param {string} [params.id]
 * @param {any} [params.form]
 * @param {string} [params.key]
 * @param {import('react').ReactNode} [params.icon]
 * @param {string} [params.text]
 * @param {() => void} [params.onMouseEnter]
 * @param {() => void} [params.onMouseLeave]
 * @returns
 */
const Button = ({
  children,
  lg,
  block,
  loading,
  disabled,
  color,
  className,
  style,
  link,
  onClick,
  htmlType,
  id,
  form,
  key,
  icon,
  text,
  onMouseEnter,
  onMouseLeave,
  ...rest
}) => {
  const iconToShow = () => {
    if (loading) {
      return <Spin indicator={antIcon} />;
    }

    if (icon) {
      return icon;
    }

    return null;
  };

  if (text) {
    return (
      <span
        tabIndex={0}
        role="button"
        onKeyDown={false}
        className="gyramais-btn-text"
        onClick={() => onClick?.()}
      >
        {children}
      </span>
    );
  }

  return (
    <button
      className={
        `gyramais-btn${lg ? ' gyramais-btn-large' : ''
        }${block ? ' gyramais-btn-block' : ''
        }${loading ? ' gyramais-btn-loading' : ''
        }${color ? ` gyramais-btn-${color}` : ''
        }${link ? ' gyramais-btn-link' : ''
        }${icon ? ' gyramais-btn-icon' : ''
        }${className ? ` ${className}` : ''
        }`
      }
      disabled={loading || disabled}
      style={style}
      onClick={() => onClick?.()}
      type={htmlType === 'submit' ? 'submit' : 'button'}
      id={id}
      key={key}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      form={form}
      {...rest}
    >
      {iconToShow()}
      <span style={{ paddingLeft: iconToShow() ? '10px' : '' }}>
        {children}
      </span>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]),
  lg: PropTypes.bool,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.shape({}),
  link: PropTypes.bool,
  text: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  htmlType: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.string,
  key: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
};

export default Button;
