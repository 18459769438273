/* eslint-disable max-len */
const termsOfUseBadesc = `POR FAVOR, LEIA COM ATENÇÃO ANTES DE PROSSEGUIR NA LINHA PRONAMPE EMERGENCIAL.

OS TERMOS E CONDIÇÕES ABAIXO REGEM O USO DOS SERVIÇOS E AS SOLICITAÇÕES DE FINANCIAMENTO NA LINHA DE CRÉDITO PRONAMPE EMERGENCIAL.

AO CLICAR "Li e aceito os Termos do Badesc", VOCÊ ACEITA OS TERMOS E CONDIÇÕES.

PRONAMPE EMERGENCIAL é a linha de crédito destinada a estimular a rápida reconstrução e recuperação dos empreendimentos produtivos diretamente afetados por desastres naturais, catástrofes climáticas e situações correlatas, localizados em Municípios Catarinenses em Situação de Emergência (SE) ou Estado de Calamidade Pública (ECP), devidamente reconhecidos pela Defesa Civil, visando minimizar os efeitos econômicos e sociais negativos deles decorrentes, preservando os níveis de emprego e renda nas regiões afetadas. São beneficiárias desta linha de crédito as pessoas jurídicas de micro e pequeno porte, conforme legislação Federal e nos normativos da fonte, diretamente atingidas, localizadas nos municípios catarinenses em situação de emergência ou estado de calamidade pública, homologado por decreto do Governador do Estado (conforme Resolução).

Não são passíveis de apoio financeiro no âmbito da linha PRONAMPE EMERGENCIAL (SE):

a) Pessoas Físicas;

b) Sociedades Cooperativas;

c) Setor Público;

d) Organizações da Sociedade Civil de Interesse Público (OSCIPs);

e) Empresas constituídas há menos de 12 meses da data de solicitação dos recursos;

f) Empresas sem faturamento em 07 (sete) ou mais meses dos últimos 12 (doze) meses contados da data do envio da Solicitação de Financiamento ou do mês anterior a este;

g) Empresas inadimplentes com o BADESC;

h) Empresas com títulos protestados ou pendências financeiras (inscrição na SERASA);

i) Empresas inscritas no Cadastro de Emitentes de Cheques sem Fundos (CCF);

j) Empresas com restrições junto à Receita Federal do Brasil;

k) Empresas com restrições junto à Secretaria de Estado da Fazenda de Santa Catarina;

l) Empresas com restrições junto ao Fundo de Garantia do Tempo Serviço (FGTS);

m) Empresas com valores vencidos ou em prejuízo em consulta ao Sistema Central de Risco (SCR);

n) Com atividade econômica (principal ou secundária) vedada pela fonte de recursos.

O valor mínimo de operação é de R$ 20.000,00 e máximo de R$ 150.000,00. O limite de exposição de crédito do Badesc junto ao pleiteante e/ou coobrigados é de até R$ 150.000,00 ou o correspondente a 4 (quatro) vezes o faturamento médio mensal fiscal do empreendimento, conforme apurado nos 12 (doze) meses anteriores à solicitação do financiamento - o que for menor.

A taxa de juros será de:

a) A taxa de juros prefixada é composta pelo spread Badesc, taxa de remuneração do BNDES e taxa fixa BNDES (TFB);

b) O custo financeiro utilizado nas simulações apresenta variação diária e, por este motivo, a taxa estimada que figura nas simulações serve, apenas, como base à tomada de decisão sobre o financiamento e não garante a sua manutenção na assinatura do contrato;

c) Se a empresa estiver adimplente, fará jus a equalização de 50% (cinquenta por cento) da taxa de juros, que ficará sob a responsabilidade do Estado de Santa Catarina, por intermédio dos recursos repassados na forma prevista na Lei nº 18.096/2021 e no Decreto nº Estadual n º 1.085/2021 e suas atualizações, bem como na Medida Provisória nº 260/23 e Decreto Estadual vigente.

Os prazos de carência e de amortização poderão ser definidos pelo pleiteante, respeitando os prazos máximos:

a) de carência: 12 meses;

b) total: 48 meses.

Os pagamentos durante a carência serão trimestrais, quando serão devidos os juros do período e as amortizações terão periodicidade mensal, na forma do Sistema de Amortizações Constantes-SAC.

Após a solicitação, o pleiteante ou o correspondente deverá enviar ao Badesc:

Referente à empresa:

a) Documento constitutivo da empresa: Contrato Social (última alteração consolidada) OU Requerimento de Empresário com respectivos registros na Junta Comercial do Estado de Santa Catarina (JUCESC) OU Registro Civil das Pessoas Jurídicas;

b) Relação de faturamento dos últimos 12 (doze) meses, mês a mês, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

c) Extrato do Simples Nacional dos últimos 3 (três) exercícios, com data base janeiro de cada ano, que traga acumulado o faturamento do ano anterior OU ECF (Escrituração Contábil Fiscal) dos últimos 3 exercícios (no caso de tributadas pelo Lucro Real, Lucro Presumido ou Lucro Arbitrado) OU Declaração de Faturamento da empresa dos últimos 3 (três) exercícios, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

d) RAIS (Recibo de entrega) ou Declaração de envio de informações da Folha de Pagamento via eSocial;

e) Comprovante de Inscrição e de Situação Cadastral/CNPJ;

f) Cópia de folha de talão de cheque ou de extrato bancário que comprove a titularidade da conta corrente cadastrada em nome da empresa;

g) Autorização para consulta ao Sistema Central de Risco - SCR assinado pelo representante da empresa (documento gerado pelo sistema, que deverá ser impresso e assinado pelo representante da empresa);

h) Certidão Negativa de Débitos Estaduais ou Certidão Positiva com Efeitos de Negativa de Débitos Estaduais;

i) Certidão Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União ou Certidão Positiva com Efeitos de Negativa de Débitos relativos aos Tributos Federais e à Dívida Ativa da União;

j) Certidão Negativa de Débitos Trabalhistas;

k) Certificado de Regularidade do FGTS;

l) Declaração de Responsabilidade Social Ambiental - RSCA.

Referente aos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes:

a) Cópia da Carteira de Identidade OU documento equivalente e do Cadastro de Pessoa Física (CPF);

b) Documento comprobatório atualizado do estado civil - Certidão de Casamento OU Declaração de União Estável OU Negativa de União Estável;

c) Cópia da Declaração Imposto de Renda das Pessoas Físicas do último exercício, acompanhado do recibo de entrega à Receita Federal OU Declaração de Isento;

d) Comprovante de residência (fatura de água, luz ou telefone fixo, com defasagem de, no máximo, 3 meses);

e) Autorização para consulta ao Sistema Central de Risco - SCR (documento gerado pelo sistema, que deverá ser impresso e assinado pelos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes);

As solicitações de financiamento serão realizadas através de plataforma eletrônica pela internet, pelo próprio pleiteante ou por parceiro operacional.

O envio da documentação não obriga o Badesc a conceder o crédito solicitado.

As propostas não concluídas ou não enviadas ao Badesc serão automaticamente canceladas após 30 dias.

A empresa declara desde já que as informações cadastradas bem como as constantes na documentação entregue ao Badesc são verdadeiras.`;

const termsOfUseBadescWoman = `POR FAVOR, LEIA COM ATENÇÃO ANTES DE PROSSEGUIR NA LINHA DE CRÉDITO PRONAMPE MULHER.

OS TERMOS E CONDIÇÕES ABAIXO REGEM O USO DOS SERVIÇOS E AS SOLICITAÇÕES DE FINANCIAMENTO NA LINHA DE CRÉDITO PRONAMPE MULHER.

AO CLICAR NO BOTÃO "EU CONCORDO", VOCÊ CONCORDA COM OS TERMOS E CONDIÇÕES.

1. PRONAMPE MULHER é a linha destinada a incentivar o empreendedorismo feminino e promover a igualdade de gênero na economia catarinense, por meio da concessão de subsídio financeiro, pelo Estado, às pessoas jurídicas de micro e pequeno porte sediados em Santa Catarina, com participação feminina no capital social, de acordo com as orientações e normas contidos em Lei e Decreto Estadual, no sentido de que para acessar este produto, a empresa declara, desde já, que atende, conjuntamente, aos requisitos obrigatórios listados abaixo:

· Participação de sócias mulheres no capital social maior do que 50%; OU

· Participação de sócias mulheres no capital social maior ou igual a 20% e uma sócia mulher (pelo menos) como administradora.

· Não estar enquadrada ou estar pleiteando recursos em quaisquer outras das modalidades do Pronampe Santa Catarina; OU

· · Não estar pleiteando recursos nas demais modalidades do Pronampe Santa Catarina por intermédio de empresas do Grupo econômico, quando for o caso, acima do limite estipulado.

Observação: O capital social e administração da mulher transgênero atende ao requisito de participação feminina.

2. Não são passíveis de apoio financeiro no âmbito do PRONAMPE MULHER:

a) Pessoas Físicas;

b) Sociedades Cooperativas;

c) Setor Público;

d) Organizações da Sociedade Civil de Interesse Público (OSCIPs);

e) Microempreendedoras Individuais (MEI);

f) Empresas constituídas há menos de 12 meses;

g) Empresas sem os requisitos obrigatórios previstos no item a acima, há pelo menos 3 (três) meses, contados da data da solicitação do crédito e respectivo enquadramento;

h) Empresas sem faturamento em 05 (cinco) ou mais meses dos últimos 12 (doze) meses contados da data do envio da Solicitação de Financiamento ou do mês anterior a este;

i) Empresas inadimplentes com o BADESC;

j) Empresas com títulos protestados ou pendências financeiras (inscrição na SERASA);

k) Empresas inscritas no Cadastro de Emitentes de Cheques sem Fundos (CCF);

l) Empresas com restrições junto à Receita Federal do Brasil;

m) Empresas com restrições junto à Secretaria de Estado da Fazenda de Santa Catarina;

n) Empresas com restrições junto ao Fundo de Garantia do Tempo Serviço (FGTS);

o) Com atividade econômica (principal ou secundária) vedada pela fonte de recursos;

p) Empresas com valores vencidos ou em prejuízo em consulta ao Sistema Central de Risco (SCR).

3. O valor mínimo de operação é de R$ 20.000,00 e máximo de R$ 100.000,00. O limite de exposição de crédito do Badesc junto ao pleiteante e/ou coobrigados é de até R$ 100.000,00 acrescido do IOF devido ou 4 vezes o seu Faturamento Médio Mensal (FMM) apurado nos últimos 12 meses (o que for menor).

4. A taxa de juros será de:

a. A taxa de juros prefixada é composta pelo spread Badesc, taxa de remuneração do BNDES e taxa fixa BNDES (TFB);

b. O custo financeiro utilizado nas simulações apresenta variação diária e, por este motivo, a taxa estimada que figura nas simulações serve, apenas, como base à tomada de decisão sobre o financiamento e não garante a sua manutenção na assinatura do contrato;

c. Se a empresa estiver adimplente, fará jus ao subsídio financeiro integral dos encargos remuneratórios, que ficarão sob a responsabilidade do Estado de Santa Catarina, por intermédio dos recursos repassados na forma prevista em Lei e Decreto Estadual.

5. Os prazos de carência e de amortização poderão ser definidos pelo pleiteante, respeitando os prazos máximos:

a) de carência: não há carência mínima;

b) total: 48 meses.

6. Os pagamentos durante a carência serão trimestrais, quando serão devidos os juros do período e as amortizações terão periodicidade mensal, na forma do Sistema de Amortizações Constantes-SAC.

7. Após a solicitação, o pleiteante ou o correspondente deverá enviar ao Badesc:

a. Referente à empresa:

a. Documento constitutivo da empresa: Contrato Social (última alteração consolidada) OU Requerimento de Empresário com respectivos registros na Junta Comercial do Estado de Santa Catarina (JUCESC) OU Registro Civil das Pessoas Jurídicas;

b. Relação de faturamento dos últimos 12 (doze) meses, mês a mês, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

c. Extrato do Simples Nacional dos últimos 3 (três) exercícios, com data base janeiro de cada ano, que traga acumulado o faturamento do ano anterior OU ECF (Escrituração Contábil Fiscal) dos últimos 3 exercícios (no caso de tributadas pelo Lucro Real, Lucro Presumido ou Lucro Arbitrado) OU Declaração de Faturamento da empresa dos últimos 3 (três) exercícios, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

d. RAIS (Recibo de entrega) ou Declaração de envio de informações da Folha de Pagamento via eSocial;

e. Comprovante de Inscrição e de Situação Cadastral/CNPJ;

f. Cópia de folha de talão de cheque ou de extrato bancário que comprove a titularidade da conta corrente cadastrada em nome da empresa;

g. Autorização para consulta ao Sistema Central de Risco - SCR assinado pelo representante da empresa (documento gerado pelo sistema, que deverá ser impresso e assinado pelo representante da empresa)

h. Certidão Negativa de Débitos Estaduais ou Certidão Positiva com Efeitos de Negativa de Débitos Estaduais;

i. Certidão Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União ou Certidão Positiva com Efeitos de Negativa de Débitos relativos aos Tributos Federais e à Dívida Ativa da União;

j. Certidão Negativa de Débitos Trabalhistas;

k. Certificado de Regularidade do FGTS;

l. Declaração de Responsabilidade Social e Ambiental – RSAC;

m. Comprovação de regularidade perante os órgãos ambientais: Licença de Operação, Certidão de dispensa ou conformidade ambiental ou Declaração de Atividade não constante emitida por órgão competente na esfera estadual ou municipal, conforme o caso.

n. Certidão Negativa de Débitos Ambientais.

b. Referente aos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes:

a. Cópia da Carteira de Identidade OU documento equivalente e do Cadastro de Pessoa Física (CPF);

b. Documento comprobatório atualizado do estado civil - Certidão de Casamento OU Declaração de União Estável OU Negativa de União Estável;

c. Cópia da Declaração Imposto de Renda das Pessoas Físicas do último exercício, acompanhado do recibo de entrega à Receita Federal OU Declaração de Isento;

d. Comprovante de residência (fatura de água, luz ou telefone fixo, com defasagem de, no máximo, 3 meses);

e. Autorização para consulta ao Sistema Central de Risco - SCR (documento gerado pelo sistema, que deverá ser impresso e assinado pelos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes);

8. As solicitações de financiamento serão realizadas através de plataforma eletrônica pela internet, pelo próprio pleiteante ou por parceiro operacional.

9. O envio da documentação não obriga o Badesc a conceder o crédito solicitado.

10. As propostas não concluídas ou não enviadas ao Badesc serão automaticamente canceladas após 30 dias.

11. A empresa declara desde já que as informações cadastradas bem como as constantes na documentação entregue ao Badesc são verdadeiras.`;

const termsOfUseBadescGeneral = `POR FAVOR, LEIA COM ATENÇÃO ANTES DE PROSSEGUIR NA LINHA DE CRÉDITO PRONAMPE SC.

OS TERMOS E CONDIÇÕES ABAIXO REGEM O USO DOS SERVIÇOS E AS SOLICITAÇÕES DE FINANCIAMENTO NA LINHA DE CRÉDITO PRONAMPE SC.

AO CLICAR NO BOTÃO "EU CONCORDO", VOCÊ CONCORDA COM OS TERMOS E CONDIÇÕES.

a. PRONAMPE SC é a linha destinada a à elevação da produtividade e da competitividade dos pequenos negócios catarinenses, caracterizados como microempresa ou empresa de pequeno porte, em conformidade com o art. 3º da Lei Complementar federal n. 123, de 2006, por meio da concessão de subsídio financeiro, pelo Estado, de acordo com as orientações e normas contidos em Lei e Decreto Estadual, no sentido de que para acessar este produto, a empresa declara, desde já, que atende, conjuntamente, aos requisitos obrigatórios listados abaixo:

· Não estar enquadrada ou estar pleiteando recursos em quaisquer outras das modalidades do Pronampe Santa Catarina; OU

· Não estar pleiteando recursos nas demais modalidades do Pronampe Santa Catarina por intermédio de empresas do Grupo econômico, quando for o caso, acima do limite estipulado.

3. Não são passíveis de apoio financeiro no âmbito do PRONAMPE SC:

a) Pessoas Físicas;

b) Sociedades Cooperativas;

c) Setor Público;

d) Organizações da Sociedade Civil de Interesse Público (OSCIPs);

e) Microempreendedoras Individuais (MEI);

f) Empresas constituídas há menos de 12 meses;

g) Empresas sem os requisitos obrigatórios previstos no item a acima, há pelo menos 3 (três) meses, contados da data da solicitação do crédito e respectivo enquadramento;

h) Empresas sem faturamento em 05 (cinco) ou mais meses dos últimos 12 (doze) meses contados da data do envio da Solicitação de Financiamento ou do mês anterior a este;

i) Empresas inadimplentes com o BADESC;

j) Empresas com títulos protestados ou pendências financeiras (inscrição na SERASA);

k) Empresas inscritas no Cadastro de Emitentes de Cheques sem Fundos (CCF);

l) Empresas com restrições junto à Receita Federal do Brasil;

m) Empresas com restrições junto à Secretaria de Estado da Fazenda de Santa Catarina;

n) Empresas com restrições junto ao Fundo de Garantia do Tempo Serviço (FGTS);

o) Com atividade econômica (principal ou secundária) vedada pela fonte de recursos;

p) Empresas com valores vencidos ou em prejuízo em consulta ao Sistema Central de Risco (SCR).

4. O valor mínimo de operação é de R$ 20.000,00 e máximo de R$ 150.000,00. O limite de exposição de crédito do Badesc junto ao pleiteante e/ou coobrigados é de até R$ 150.000,00 acrescido do IOF devido ou 4 vezes o seu Faturamento Médio Mensal (FMM) apurado nos últimos 12 meses (o que for menor).

5. A taxa de juros será de:

a. A taxa de juros prefixada é composta pelo spread Badesc, taxa de remuneração do BNDES e taxa fixa BNDES (TFB); O custo financeiro utilizado nas simulações apresenta variação diária e, por este motivo, a taxa estimada que figura nas simulações serve, apenas, como base à tomada de decisão sobre o financiamento e não garante a sua manutenção na assinatura do contrato;

b. Se a empresa estiver adimplente, fará jus ao subsídio financeiro de 40% (quarenta por cento) dos juros remuneratórios. A pleiteante somente pagará os valores correspondentes à amortização da dívida e 60%

(sessenta por cento) da taxa de juros remuneratórios previstos, ficando os 40% (quarenta por cento) restantes dos juros remuneratórios sob a responsabilidade do Estado de Santa Catarina, por intermédio dos recursos repassados na forma do Decreto Estadual vigente.

6. Os prazos de carência e de amortização poderão ser definidos pelo pleiteante, respeitando os prazos máximos:

a. de carência: 12 meses;

b. de amortização: 36 meses;

c. total: 48 meses.

7. Os pagamentos durante a carência serão trimestrais, quando serão devidos os juros do período e as amortizações terão periodicidade mensal, na forma do Sistema de Amortizações Constantes-SAC.

8. Após a solicitação, o pleiteante ou o correspondente deverá enviar ao Badesc:

1. Referente à empresa:

a. Documento constitutivo da empresa: Contrato Social (última alteração consolidada) OU Requerimento de Empresário com respectivos registros na Junta Comercial do Estado de Santa Catarina (JUCESC) OU Registro Civil das Pessoas Jurídicas;

b. Relação de faturamento dos últimos 12 (doze) meses, mês a mês, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

c. Extrato do Simples Nacional dos últimos 3 (três) exercícios, com data base janeiro de cada ano, que traga acumulado o faturamento do ano anterior OU ECF (Escrituração Contábil Fiscal) dos últimos 3 exercícios (no caso de tributadas pelo Lucro Real, Lucro Presumido ou Lucro Arbitrado) OU Declaração de Faturamento da empresa dos últimos 3 (três) exercícios, assinada por certificado digital pelo contador, devidamente identificado no documento com seu registro no Conselho Regional de Contabilidade (CRC). Cópia ou digitalização do faturamento não será aceita;

d. RAIS (Recibo de entrega) ou Declaração de envio de informações da Folha de Pagamento via eSocial;

e. Comprovante de Inscrição e de Situação Cadastral/CNPJ;

f. Cópia de folha de talão de cheque ou de extrato bancário que comprove a titularidade da conta corrente cadastrada em nome da empresa;

g. Autorização para consulta ao Sistema Central de Risco - SCR assinado pelo representante da empresa (documento gerado pelo sistema, que deverá ser impresso e assinado pelo representante da empresa)

h. Certidão Negativa de Débitos Estaduais ou Certidão Positiva com Efeitos de Negativa de Débitos Estaduais;

i. Certidão Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União ou Certidão Positiva com Efeitos de Negativa de Débitos relativos aos Tributos Federais e à Dívida Ativa da União;

j. Certidão Negativa de Débitos Trabalhistas;

k. Certificado de Regularidade do FGTS;

l. Declaração de Responsabilidade Social e Ambiental – RSAC;

m. Comprovação de regularidade perante os órgãos ambientais: Licença de Operação, Certidão de dispensa ou conformidade ambiental ou Declaração de Atividade não constante emitida por órgão competente na esfera estadual ou municipal, conforme o caso.

2. Referente aos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes:

a. Cópia da Carteira de Identidade OU documento equivalente e do Cadastro de Pessoa Física (CPF);

c. Documento comprobatório atualizado do estado civil - Certidão de Casamento OU Declaração de União Estável OU Negativa de União Estável;

d. Cópia da Declaração Imposto de Renda das Pessoas Físicas do último exercício, acompanhado do recibo de entrega à Receita Federal OU Declaração de Isento;

e. Comprovante de residência (fatura de água, luz ou telefone fixo, com defasagem de, no máximo, 3 meses);

f. Autorização para consulta ao Sistema Central de Risco - SCR (documento gerado pelo sistema, que deverá ser impresso e assinado pelos sócios, avalistas, intervenientes e respectivos cônjuges/conviventes);

9. As solicitações de financiamento serão realizadas através de plataforma eletrônica pela internet, pelo próprio pleiteante ou por parceiro operacional.

10. O envio da documentação não obriga o Badesc a conceder o crédito solicitado.

11. As propostas não concluídas ou não enviadas ao Badesc serão automaticamente canceladas após 30 dias.

12. A empresa declara desde já que as informações cadastradas bem como as constantes na documentação entregue ao Badesc são verdadeiras.`;

export {
  termsOfUseBadesc,
  termsOfUseBadescWoman,
  termsOfUseBadescGeneral,
};
