import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  Divider,
  Modal,
  message,
} from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  REMOVE_BUSINESS,
} from 'services/graphql/mutations';
import { useMutation } from '@apollo/client';
import { formatError } from 'utils';

const BusinessCard = ({
  business: {
    id,
    name,
    legalName,
    number,
    cnpj,
  },
  style,
  refetch,
}) => {
  const history = useHistory();
  const [onRemove, setOnRemove] = useState(false);
  const [removeBusiness, { loading }] = useMutation(REMOVE_BUSINESS, {
    onCompleted: () => refetch(),
    onError: (error) => message.error(formatError(error), 2),
  });
  return (
    <Card
      id={`business-${number}-card`}
      key={id}
      style={style}
      className={loading ? 'card-remove' : ''}
      bodyStyle={{ padding: 10 }}
      hoverable
      onClick={(e) => {
        if (!onRemove) {
          if (e.ctrlKey || e.metaKey) window.open(`${window.location.origin}/empresas/${id}`, '_blank');
          else history.push(`/empresas/${id}`);
        }
      }}
    >
      <Row style={{ margin: '5px 0' }}>
        <Col>
          <div>
            <div style={{ display: 'flex' }}>
              <Row><b>{`${number} - ${name || legalName || 'SEM NOME'}`}</b></Row>
            </div>
          </div>
        </Col>
        <Col style={{ marginLeft: 'auto', display: 'flex' }}>
          <Row align="middle">
            <DeleteOutlined
              id="delete-business-button"
              style={{ fontSize: 20, marginRight: 10, color: 'red' }}
              onMouseEnter={() => setOnRemove(true)}
              onMouseLeave={() => setOnRemove(false)}
              onClick={() => {
                Modal.confirm({
                  title: 'Deseja realmente remover este cliente?',
                  centered: true,
                  okButtonProps: { id: 'confirm-delete-business-button' },
                  onOk: async () => {
                    removeBusiness({ variables: { id } });
                  },
                  okText: 'Sim',
                  cancelText: 'Voltar',
                });
              }}
            />
          </Row>
        </Col>
      </Row>
      {/* <StatusTag title={name} backgroundColor={colors[0]} /> */}
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        <Col xs={24} sm={24} md={8} lg={6}>
          <div className="card-info">
            <Row>Razão Social</Row>
            <b>{legalName}</b>
          </div>
        </Col>
        <Col className="desktop-only">
          <Divider type="vertical" style={{ height: 44 }} />
        </Col>
        <Col xs={24} sm={24} md={4} lg={3}>
          <div className="card-info">
            <Row>CNPJ</Row>
            <b>{cnpj}</b>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

BusinessCard.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    legalName: PropTypes.string,
    cnpj: PropTypes.string,
    number: PropTypes.number,
  }),
  style: PropTypes.shape({}),
  refetch: PropTypes.func,
};

export default memo(BusinessCard);
