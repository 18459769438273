import { gql } from '@apollo/client';

const GET_API_USERS = gql`
  query apiUsers {
    apiUsers {
      id
      apiKey
    }
  }
`;

export {
  GET_API_USERS,
};

export default GET_API_USERS;
