import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Row,
  message,
  Col,
  Spin,
  Card,
} from 'antd';
import { AlertOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import {
  BUSINESS_USER,
} from 'services/graphql/queries';
import { SAVE_BUSINESS_USER } from 'services/graphql/mutations';
import { Images } from 'assets';
import { formatError } from 'utils';

const { Item, useForm } = Form;
const { LogoGyraG } = Images;

const EmailForm = ({
  id,
  businessId,
  cpf,
  onCompleted,
}) => {
  const history = useHistory();
  const [form] = useForm();
  const {
    data: { businessUser: { user: { email } = {} } = {} } = {},
    loading: userLoading,
  } = useQuery(BUSINESS_USER, {
    skip: !id,
    variables: { id },
    partialRefetch: true,
    onCompleted: ({ businessUser: { user: { email } } = {} } = { businessUser: { user: { email: '' } } }) => {
      if (email) {
        form.setFieldsValue({
          email,
        });
      }
    },
  });
  const [saveBusinessUser, { loading: saveLoading }] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: ({
      saveBusinessUser: {
        id,
      },
    }) => {
      history.push('/cadastro/empresa-socios', {
        businessUserId: id,
        businessId,
        cpf,
      });
      onCompleted();
    },
    onError: (error) => message.error(formatError(error)),
  });
  const onFinish = async (values) => {
    await form.validateFields();
    saveBusinessUser({
      variables: {
        id,
        businessId,
        cpf,
        ...values,
      },
    });
  };
  return (
    <Spin
      indicator={<LogoGyraG className="rotating" style={{ width: 64, height: 64 }} />}
      spinning={userLoading}
      style={{ transform: 'translateX(-16px)' }}
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <h3 className="wl-text">
          Insira o email do sócio ou representante legal
        </h3>
        <Card className="upload-card" style={{ marginBottom: 30 }}>
          <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
            <Col span={2}>
              <AlertOutlined style={{ color: 'orange', fontSize: 30 }} />
            </Col>
            <Col span={20}>
              Importante: Cada sócio receberá um e-mail e deverá cadastrar uma senha no site da
              GYRA+ para ter acesso ao painel de assinatura eletrônica do contrato.
            </Col>
          </Row>
        </Card>
        <Item name="email" rules={[{ required: true, message: 'Por favor preencha o email do sócio e/ou representante legal.' }]}>
          <Input placeholder="E-mail" />
        </Item>
        {(!id || (email && !email.includes('@')))
        && (
          <>
            <Item
              name="emailConfirmation"
              dependencies={['email']}
              rules={[
                { required: true, message: 'Por favor confirme o email do sócio e/ou representante legal.' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve();
                    }

                    const emailsDontMatch = new Error('Os dois emails não coincidem');
                    return Promise.reject(emailsDontMatch);
                  },
                })]}
            >
              <Input placeholder="Confirmação de e-mail" />
            </Item>
          </>
        )}
        <Row gutter={12} className="fixed-footer">
          <Col span={10}>
            <Button
              block
              disabled={saveLoading || userLoading}
              ghost
              type="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              Voltar
            </Button>
          </Col>
          <Col span={14}>
            <Button
              id="business-user-email-continue"
              block
              disabled={userLoading}
              loading={saveLoading || userLoading}
              type="primary"
              htmlType="submit"
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

EmailForm.propTypes = {
  id: PropTypes.string,
  cpf: PropTypes.string,
  businessId: PropTypes.string,
  onCompleted: PropTypes.func,
};

export default EmailForm;
