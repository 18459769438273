import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Skeleton,
} from 'antd';

import './styles.less';

const SubHeader = ({
  title,
  subtitle,
  buttons = [],
  buttonsPlacement,
  loading,
  id,
  extra,
  onlyButtons,
}) => {
  const _title = () => {
    if (typeof title === 'function') {
      return title();
    }

    return title;
  };

  const _subtitle = () => {
    if (typeof subtitle === 'function') {
      return subtitle();
    }

    return subtitle;
  };

  const _buttons = () => {
    if (typeof buttons === 'function') {
      return buttons();
    }

    return buttons;
  };

  return (
    <div id={id} className="gyramais-sub-header">
      {onlyButtons
        ? (
          <Row justify="center">
            {buttons}
          </Row>
        ) : (
          <Row align={buttonsPlacement === 'right' ? 'middle' : 'bottom'} className="content">
            <Col xs={24} sm={24} md={14} lg={((buttonsPlacement === 'right' && _buttons()) || extra) ? 12 : 24} className="left-side">
              {loading ? (
                <>
                  <Skeleton active className="sub-header-skeleton" />
                  <Skeleton.Button active className="sub-header-button-skeleton" />
                </>
              ) : (
                <>
                  {_title() && (
                  <div className="title-container">
                    <h1 className="white-label-text">{_title()}</h1>
                  </div>
                  )}

                  {_subtitle() && (
                  <div className="subtitle-container">
                    <p className={`white-label-text text-justified ${(buttonsPlacement && _buttons()) ? '' : 'no-margin-bottom'}`}>{_subtitle()}</p>
                  </div>
                  )}

                  {!loading
                    && buttonsPlacement
                    && buttonsPlacement === 'bottom'
                    && _buttons()}
                </>
              )}
            </Col>
            {((buttonsPlacement === 'right' && _buttons()) || extra) && (
            <>
              <Col xs={24} sm={24} md={10} lg={12} className="right-side">
                {!loading
                    && buttonsPlacement === 'right'
                  ? _buttons()
                  : (
                    <div className="extra-container">
                      {extra}
                    </div>
                  )}
              </Col>
            </>
            )}
          </Row>
        )}
    </div>
  );
};

SubHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonsPlacement: PropTypes.string,
  buttons: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.bool,
  ]),
  loading: PropTypes.bool,
  onlyButtons: PropTypes.bool,
  extra: PropTypes.shape(),
};

export default SubHeader;
