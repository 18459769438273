import React from 'react';
import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  Input,
} from 'components/gyramais';
import PropTypes from 'prop-types';

const { Item } = Form;

const ClientForm = ({ form }) => (
  <Form
    form={form}
    layout="vertical"
  >
    <Row gutter={12}>
      <Col span={24}>
        <Item
          name="fullName"
          rules={[{ required: true, message: 'Por favor preencha o nome completo.' }]}
        >
          <Input placeholder="Nome completo" />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          name="cpf"
          rules={[{ required: true, message: 'Por favor preencha o CPF.' }]}
        >
          <Input
            type="masked"
            className="ant-input"
            guide
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholder="CPF"
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          name="email"
          rules={[{ required: true, message: 'Por favor preencha o email.' }]}
        >
          <Input placeholder="Email" />
        </Item>
      </Col>
      <Col xs={24} sm={24} md={24}>
        <Item
          name="phoneNumber"
          rules={[{ required: true, message: 'Por favor preencha o celular.' }]}
          className="no-margin-bottom"
        >
          <Input
            type="masked"
            className="ant-input"
            guide
            mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholder="Celular com DDD"
          />
        </Item>
      </Col>
    </Row>
  </Form>
);

ClientForm.propTypes = {
  form: PropTypes.shape(),
};

export default ClientForm;
